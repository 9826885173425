export function categoryTypeCanHaveDecimal(categoryName, product, preventPackToOrder = false) {
  if (product && (product.units_per_case || product.units_per_package)) {
    return false;
  }
  if (
    categoryName == "Bulk Extract" ||
    categoryName == "Flower" ||
    (categoryName == "Pack To Order" && !preventPackToOrder) ||
    categoryName == "Plant Material"
  ) {
    return true;
  }
  return false;
}

// Helper function to validate number string
function isValidNumber(value, { allowDecimal, maxPrecision, allowCommas, allowDashes, allowNegative }) {
  // Check for invalid negative numbers
  if (allowNegative) {
    const negativeCount = (value.match(/-/g) || []).length;
    if (negativeCount > 1 || (negativeCount === 1 && value.indexOf("-") !== 0)) {
      return false;
    }
  }

  // Check for invalid dashes
  if (allowDashes && !allowNegative) {
    const consecutiveDashes = value.includes("--");
    if (consecutiveDashes) {
      return false;
    }
  }

  // Check for invalid decimals
  if (allowDecimal) {
    const decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount > 1) {
      return false;
    }

    // Check decimal precision
    const parts = value.split(".");
    if (parts[1] && parts[1].length > maxPrecision) {
      return false;
    }
  } else if (value.includes(".")) {
    return false;
  }

  // Check for invalid commas
  if (allowCommas) {
    const consecutiveCommas = value.includes(",,");
    if (consecutiveCommas) {
      return false;
    }
  } else if (value.includes(",")) {
    return false;
  }

  return true;
}

/**
 * Process and validate number input
 * @param {string} currentValue - Current value of the input
 * @param {string} newContent - New content to be inserted
 * @param {number} selectionStart - Start position of selection/cursor
 * @param {number} selectionEnd - End position of selection/cursor
 * @param {Object} options - Validation options
 * @param {boolean} [options.allowDecimal=true] - Allow decimal points
 * @param {number} [options.maxPrecision=6] - Maximum decimal precision
 * @param {boolean} [options.allowCommas=false] - Allow commas
 * @param {boolean} [options.allowDashes=false] - Allow dashes
 * @param {boolean} [options.allowNegative=false] - Allow negative numbers
 * @returns {Object|null} - Object containing new value and cursor position, or null if invalid
 */
export function processNumberInput(
  currentValue,
  newContent,
  selectionStart,
  selectionEnd,
  { allowDecimal = true, maxPrecision = 6, allowCommas = false, allowDashes = false, allowNegative = false } = {},
) {
  // Clean input content
  const validChars = new RegExp(`[^0-9${allowDecimal ? "\\." : ""}${allowCommas ? "," : ""}${allowDashes || allowNegative ? "\\-" : ""}]`, "g");
  const cleanedContent = newContent.replace(validChars, "");

  // Construct new value
  const newValue = currentValue.substring(0, selectionStart) + cleanedContent + currentValue.substring(selectionEnd);

  // Validate the new value
  if (!isValidNumber(newValue, { allowDecimal, maxPrecision, allowCommas, allowDashes, allowNegative })) {
    return null;
  }

  return {
    newValue,
    newPosition: selectionStart + cleanedContent.length,
  };
}

export function onNumberKeyDown(
  event,
  { allowDecimal = true, maxPrecision = 6, allowCommas = false, allowDashes = false, allowNegative = false } = {},
) {
  const evt = event || window.event;

  // // Original keyboard event handling
  // const { value } = evt.target;
  // const { selectionStart } = evt.target;

  // Handle both numeric keypad and regular number keys
  const isNumber = (evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105);
  const isDot = evt.keyCode === 190 || evt.keyCode === 110; // Both period and numpad decimal
  const isComma = evt.keyCode === 188; // Comma key
  const isDash = evt.keyCode === 189 || evt.keyCode === 109; // Regular dash and numpad minus
  const isControlKey =
    evt.keyCode === 8 ||
    evt.keyCode === 46 || // Backspace and Delete
    evt.keyCode === 37 ||
    evt.keyCode === 39 || // Left and Right arrows
    evt.keyCode === 9 || // Tab
    evt.ctrlKey ||
    evt.metaKey; // Allow copy/paste/cut

  // Prevent shift key combinations (except for allowed special characters)
  if (evt.shiftKey && !isControlKey) {
    evt.preventDefault();
    return;
  }

  // Check if input is valid based on configuration
  const isValidInput =
    isNumber || (allowDecimal && isDot) || isControlKey || (allowCommas && isComma) || (allowDashes && isDash) || (allowNegative && isDash);

  if (!isValidInput) {
    evt.preventDefault();
    return;
  }

  const result = processNumberInput(evt.target.value, evt.key, evt.target.selectionStart, evt.target.selectionEnd, {
    allowDecimal,
    maxPrecision,
    allowCommas,
    allowDashes,
    allowNegative,
  });

  if (!result) {
    evt.preventDefault();
  }
  // // Handle negatives - only allow at start of input
  // if (allowNegative && isDash) {
  //   // Only allow negative at the start, and only one
  //   if (selectionStart !== 0 || value.includes("-")) {
  //     evt.preventDefault();
  //     return;
  //   }
  // }

  // // Handle formatting dashes
  // if (allowDashes && isDash && !allowNegative) {
  //   // Prevent consecutive dashes
  //   if (value.charAt(selectionStart - 1) === "-" || value.charAt(selectionStart) === "-") {
  //     evt.preventDefault();
  //     return;
  //   }
  // }

  // // Prevent multiple dots if decimals are allowed
  // if (allowDecimal && isDot && value.includes(".")) {
  //   evt.preventDefault();
  //   return;
  // }

  // // Prevent multiple consecutive commas if allowed
  // if (allowCommas && isComma && (value.charAt(selectionStart - 1) === "," || value.charAt(selectionStart) === ",")) {
  //   evt.preventDefault();
  //   return;
  // }

  // // Only check decimal precision if decimals are allowed
  // if (allowDecimal) {
  //   let amountOfDecimals = 0;
  //   if (value) {
  //     // Remove formatting dashes and commas, but keep negative sign
  //     const cleanValue = value
  //       .replace(allowDashes ? /-/g : "", "") // Only remove dashes if they're formatting dashes
  //       .replace(/,/g, "");

  //     if (Math.floor(Math.abs(parseFloat(cleanValue))) === Math.abs(parseFloat(cleanValue))) {
  //       amountOfDecimals = 0;
  //     }
  //     const parts = cleanValue.split(".");
  //     if (parts[1]) {
  //       amountOfDecimals = parts[1].length || 0;
  //     }
  //   }

  //   // Only prevent if we're adding a number after the decimal point
  //   if (maxPrecision <= amountOfDecimals && value.indexOf(".") !== -1 && selectionStart > value.indexOf(".")) {
  //     evt.preventDefault();
  //   }
  // }
}

export function blurPrice(product, event) {
  const newValue = this.normalizePriceForDBEntry(this.currencyParser(event.target.value));
  if (product.listing_price != newValue) {
    this.updateInventoryItemListing({ id: product.id, listing_price: newValue });
  } else if (product.listing_price) {
    event.target.value = this.currencyParser(event.target.value);
  }
}

export function currencyParser(value) {
  let newValue = value.replace(/\$/g, "");
  if (newValue.indexOf(".") == -1) {
    newValue = `${newValue}.00`;
  }
  const decimals = this.countDecimals(newValue);
  if (decimals === 0) {
    newValue = `${newValue}00`;
  }
  if (decimals === 1) {
    newValue = `${newValue}0`;
  }
  return newValue;
}

// Remove the decimal value
export function normalizePriceForDBEntry(value) {
  if (typeof value == "number") {
    value = String(value);
  }

  const val = value.replace(/\$|,/g, "");
  if (val == ".00") {
    return null;
  }

  return (Number(val) * 100).toFixed(0);
}

export function blurProductField(product, event, type) {
  const newValue = event.target.value;
  if (product[type] != newValue) {
    this.updateInventoryItemListing({ id: product.id, [type]: newValue });
  }
}

export function countDecimals(value) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
}

export function priceClass(value) {
  let cssClass = "no-value";
  if (value) {
    cssClass = "has-value";
  }
  return cssClass;
}

export function focusPrice() {
  //
}

export function editingBatchFieldNumbers(event) {
  const evt = event || window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  // Allow only numbers
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }
}

export function checkAndRound(number, precision = 2) {
  if (Number.isFinite(number)) {
    if (number % 1 != 0) {
      return number.toFixed(precision);
    }
  }
  return number;
}

export function calculateProductCbdDisplay(batches, category, returnAllValues = false) {
  if (!batches || batches.length == 0 || category == "Merchandise") {
    return "N/A";
  }
  let min = null;
  let max = null;
  let value = null;
  let usingCbdRange = false;
  let cbdUnit = "";
  let startingNumber = null;
  let endingNumber = null;
  let minBuffer = null;
  const singleMinArray = [];
  let lowest = null;
  let highest = null;
  let lowestItem = null;
  let highestItem = null;
  const minsArray = [];
  const maxsArray = [];
  let combined = [];
  let isPercentage = false;
  batches.forEach((element) => {
    if (!element.archived && (element.predominate_canabinoid_min_or_only || element.predominate_canabinoid_max) && !element.deleted_at) {
      if (element.predominate_canabinoid_min_or_only) {
        minBuffer = element.predominate_canabinoid_min_or_only;
        minsArray.push(element.predominate_canabinoid_min_or_only);
      }
      if (element.predominate_canabinoid_max) {
        maxsArray.push(element.predominate_canabinoid_max);
      }
      if (element.predominate_canabinoid_min_or_only < minBuffer || minBuffer != null) {
        min = minBuffer;
      }
      if (element.predominate_canabinoid_max >= max) {
        max = element.predominate_canabinoid_max;
      }
      if (element.predominate_canabinoid_min_or_only > max) {
        max = element.predominate_canabinoid_min_or_only;
      }
      if (element.predominate_canabinoid_max) {
        usingCbdRange = true;
      }
      if (element.predominate_canabinoid_unit) {
        cbdUnit = element.predominate_canabinoid_unit;
      }
      if (minBuffer) {
        singleMinArray.push({ value: minBuffer, measurement: cbdUnit });
      }
    }
  });
  combined = [...minsArray, ...maxsArray];
  if (combined.length > 1) {
    min = Math.min(...combined);
    max = Math.max(...combined);
  }
  startingNumber = min;
  endingNumber = max;
  if (cbdUnit && min) {
    min = checkAndRound(min) + cbdUnit;
  }
  if (usingCbdRange) {
    if (cbdUnit) {
      max = checkAndRound(max) + cbdUnit;
    }
    if (min) {
      value = min;
    }
    if (!min && max) {
      value = max;
    }
    if (min && max) {
      if (min == max) {
        value = `${checkAndRound(min)}`;
      } else {
        value = `${checkAndRound(min)} - ${checkAndRound(max)}`;
      }
    }
  } else {
    if (singleMinArray.length > 1) {
      lowest = Math.min(...singleMinArray.map((item) => item.value));
      highest = Math.max(...singleMinArray.map((item) => item.value));
      lowestItem = singleMinArray.find((v) => v.value === lowest);
      highestItem = singleMinArray.find((v) => v.value === highest);
      startingNumber = lowest;
      if (lowestItem.value + lowestItem.measurement == highestItem.value + highestItem.measurement) {
        value = `${checkAndRound(lowestItem.value) + lowestItem.measurement}`;
      } else {
        value = `${checkAndRound(lowestItem.value) + lowestItem.measurement} - ${checkAndRound(highestItem.value) + highestItem.measurement}`;
      }
    }
    if (singleMinArray.length == 1) {
      value = checkAndRound(min);
    }
  }
  if (!value) {
    return "Not Entered";
  }
  if (returnAllValues) {
    if (cbdUnit == "%") {
      isPercentage = true;
    }
    return {
      rangeString: value,
      isUsingPercentage: isPercentage,
      minValue: startingNumber,
      maxValue: endingNumber,
    };
  }

  return value;
}

export function checkShowTotalQuantity(batches) {
  let showQuantity = false;
  if (batches.length > 0) {
    batches.forEach((batch, index) => {
      if (!batch.back_order && !batch.archived && !batch.hold && !batch.disable_inventory_tracking) {
        showQuantity = true;
      }
    });
  }
  return showQuantity;
}

export function calculatedAvailableBatchQuantity(batch) {
  let quantity = 0;
  // const hasPotencyDoc = false;
  let thcLimit = true;
  // if (potencyDocRequired) {
  thcLimit = batch.thc_limit;
  // }
  if (!batch.archived && !batch.hold && !batch.disable_inventory_tracking && thcLimit) {
    // if (potencyDocRequired) {
    //   if (batch.documents && batch.documents.length > 0) {
    //     batch.documents.forEach((doc, index) => {
    //       if (doc.label == 'Potency') {
    //         hasPotencyDoc = true;
    //       }
    //     });
    //     if (hasPotencyDoc) {
    //       quantity += batch.quantity;
    //     }
    //   }
    // } else {
    quantity += batch.quantity;
    // }
  }
  return quantity;
}

export function calculatedAvailableProductQuantity(batches) {
  let quantity = 0;
  if (batches.length > 0) {
    batches.forEach((batch, index) => {
      quantity += calculatedAvailableBatchQuantity(batch);
    });
  }
  return quantity;
}

export function checkShowInfinityQuanity(batches) {
  let showInifinity = false;
  let thcLimit = true;
  if (batches.length > 0) {
    batches.forEach((batch, index) => {
      thcLimit = true;
      // if (potencyDocRequired) {
      thcLimit = batch.thc_limit;
      // }
      if (!batch.archived && !batch.hold && batch.disable_inventory_tracking && thcLimit) {
        // if (potencyDocRequired) {
        //   if (batch.documents && batch.documents.length > 0) {
        //     batch.documents.forEach((doc, dex) => {
        //       if (doc.label == 'Potency') {
        //         showInifinity = true;
        //       }
        //     });
        //   }
        // } else {
        showInifinity = true;
        // }
      }
    });
  }
  return showInifinity;
}
