<template>
  <div :class="invalidFeedbackClasses">
    <slot />
  </div>
</template>
<script>
export default {
  name: "BFormInvalidFeedback",
  props: {
    tag: {
      type: String,
      default: "div",
    },
    state: {
      type: Boolean,
      default: null,
    },
    forceShow: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: "alert",
    },
  },
  computed: {
    invalidFeedbackClasses() {
      return {
        "invalid-feedback": this.forceShow || !this.state,
        "valid-feedback": this.state,
      };
    },
  },
};
</script>
