<template>
  <small :class="formTextClasses">
    <slot />
  </small>
</template>
<script>
export default {
  name: "BFormText",
  props: {
    tag: {
      type: String,
      default: "small",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      default: "muted",
    },
  },
  computed: {
    formTextClasses() {
      return {
        "form-text": true,
        "text-muted": this.variant === "muted",
        "text-success": this.variant === "success",
        "text-danger": this.variant === "danger",
        "text-warning": this.variant === "warning",
        "text-info": this.variant === "info",
        "text-primary": this.variant === "primary",
        "text-secondary": this.variant === "secondary",
        "text-dark": this.variant === "dark",
        "text-light": this.variant === "light",
        "text-white": this.variant === "white",
        "text-body": this.variant === "body",
        "d-inline": this.inline,
      };
    },
  },
};
</script>
