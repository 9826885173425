import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const createEvent = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/events", payload);
    if (response.data.id) {
      commit("eventAdded", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Event" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Event" }, { root: true });
    return error;
  }
};

export const createEventItem = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/events/item", payload);
    if (response.data.id) {
      commit("eventItemAdded", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Event" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Event" }, { root: true });
    return error;
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/events/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceEvent", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Event" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Event" }, { root: true });
    return error;
  }
};

export const fetchEvents = async ({ commit }) => {
  try {
    const response = await api.get("/events");
    const events = arrayToObject(response.data, "id");
    commit("initEvents", events);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Event List" }, { root: true });
  }
};

export const fetchEvent = async ({ commit, dispatch }, id) => {
  try {
    commit("setLoading", true);

    const response = await api.get(`/events/${id}`);
    if (response.data.id) {
      commit("replaceEvent", response.data);
      commit("setLoading", false);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Event Data" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Event Data" }, { root: true });
  }
};

export const destroyEvent = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/events/${payload}`);
    if (response.data.message == "success") {
      commit("eventRemoved", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Event" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Event" }, { root: true });
  }
};

export const destroyEventItem = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/events/item/${payload}`);
    // if (response.data.message == 'success') {
    if (response.data.id) {
      commit("eventItemRemoved", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Event" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Event" }, { root: true });
  }
};

export const restoreEvent = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/events/restore/${payload}`);
    if (response.data.message == "success") {
      commit("eventRestored", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Event" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Event" }, { root: true });
  }
};
