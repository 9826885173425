import * as api from "@/services/api";

export const fetchBuyerStages = async ({ commit }) => {
  try {
    const response = await api.get("/buyer-stages/company-stages");
    if (response.data.code == 200) {
      commit("init", response.data.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Fetching Buyer Stages",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Fetching Buyer Stages",
      },
      { root: true },
    );
  }
};

export const createNewBuyerStage = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/buyer-stages/create", payload);
    if (response.data.code == 201) {
      commit("addBuyerStage", response.data.data.buyerStage);
      // commit('ui/setSnackbar', { variant: 'success', message: 'Buyer Stage Created Successfully' }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Creating Buyer Stage",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Creating Buyer Stage",
      },
      { root: true },
    );
    return error;
  }
};

export const updateBuyerStage = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/buyer-stages/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("updateBuyerStage", response.data.data);
      // commit('ui/setSnackbar', { variant: 'success', message: 'Buyer Stage Updated Successfully' }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Updating Buyer Stage",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Buyer Stage",
      },
      { root: true },
    );
    return error;
  }
};

export const deleteBuyerStage = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/buyer-stages/delete/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("processDeletedBuyerStage", response.data.data);
      if (response.data.data.buyersWithStagesRemoved.length > 0) {
        dispatch("buyers/nullifyBuyerStages", response.data.data.buyersWithStagesRemoved, { root: true });
      }
      // commit('ui/setSnackbar', { variant: 'success', message: 'Buyer Stage Deleted' }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Buyer Stage",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Buyer Stage",
      },
      { root: true },
    );
    return error;
  }
};

export const updateBuyerStageSortOrder = async ({ commit, dispatch }, payload) => {
  try {
    let sortOrder = 0;
    const { items } = payload;
    const updatedItems = [];
    items.forEach((item, index) => {
      const itemToBeUpdated = item;
      itemToBeUpdated.sort_order = sortOrder;
      commit("updateBuyerStage", itemToBeUpdated);
      sortOrder += 1;
      updatedItems.push(itemToBeUpdated);
    });
    const response = await api.post("/buyer-stages/bulk-update", items);
    if (response.data.code == 200) {
      commit("init", response.data.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Updating Buyer Stage",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Buyer Stage",
      },
      { root: true },
    );
    return error;
  }
};
