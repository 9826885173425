<template>
  <Teleport v-if="visible" to="body">
    <div @click="hideModal">
      <transition name="slide-fade" appear>
        <div
          v-if="visible"
          :id="uniqueId"
          :class="{
            modal: true,
          }"
          role="dialog"
          aria-labelledby="custom-modal"
          aria-hidden="true"
        >
          <div
            :class="{
              'modal-dialog': true,
              'modal-dialog-centered': centered,
              'modal-dialog-scrollable': scrollable,

              'modal-md': size == 'md',
              'modal-lg': size == 'lg',
              'modal-xl': size == 'xl',
              'modal-xxl': size == 'xxl',
              'modal-sm': size == 'sm',
              'modal-full': size == 'full',
            }"
          >
            <div :class="{ 'modal-content': true, 'shadow-lg': true, 'rounded-0': size == 'full' }">
              <!-- Modal Header -->
              <div v-if="!hideHeader" :class="{ 'modal-header': true, [headerClass]: headerClass }">
                <slot name="modal-header">
                  <slot name="modal-title">
                    <h5 v-if="title" id="custom-modal" class="modal-title">
                      {{ title }}
                    </h5>
                  </slot>
                  <button type="button" class="close" aria-label="Close" @click="hide">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </slot>
              </div>

              <!-- Modal Body -->
              <div :class="{ 'modal-body': true, 'modal-scroll': scrollable ? true : false, [bodyClass]: true }">
                <slot />
              </div>

              <!-- Modal Footer -->
              <div v-if="!hideFooter" class="modal-footer">
                <slot name="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="hide">Close</button>
                  <!-- Additional buttons or actions can be added here -->
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div v-if="visible && !hideBackdrop" class="modal-backdrop show" />
    </div>
  </Teleport>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md",
      validator: (value) => ["sm", "md", "lg", "xl", "xxl", "full"].includes(value),
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
      default: "",
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["hide"],
  data() {
    return {
      uniqueId: `modal-${Math.random().toString(36).substring(7)}`,
      preExistingModalOpen: false,
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        if (document.body.classList.contains("modal-open")) {
          this.preExistingModalOpen = true;
        } else {
          document.body.classList.add("modal-open");
          this.preExistingModalOpen = false;
        }
      } else if (!this.preExistingModalOpen) {
        document.body.classList.remove("modal-open");
      }
    },
  },
  mounted() {
    if (this.visible) {
      document.body.classList.add("modal-open");
    }
  },
  methods: {
    hide() {
      this.$emit("hide", true);
    },
    hideModal(event) {
      if (this.noCloseOnBackdrop) {
        return;
      }
      if (event.target.id === this.uniqueId) {
        this.hide();
      }
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-50%);
  opacity: 0;
}
</style>
