import { arrayToObject } from "../../../services/helpers";

export const init = (state, payload) => {
  const list = [];
  payload.forEach((item) => {
    const itemVar = { ...item };
    itemVar.permissions = null;
    list.push(itemVar);
  });
  state.list = arrayToObject(list, "id");
  state.loaded = true;
};

export const reset = (state, payload) => {
  state.list = {};
  state.loaded = false;
};
