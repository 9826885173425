import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const createBuyer = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/buyers", payload);
    if (response.data.id) {
      commit("buyerAdded", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Buyer" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Buyer" }, { root: true });
    return error;
  }
};

export const createBuyerFromBuyerNeedsRequest = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/buyer-needs/buyer-needs-linking", payload);
    if (response.data.id) {
      commit("buyerAdded", response.data);
      return response.data;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "There was an error. This buyer may have already been created. Try refreshing your screen.",
      },
      { root: true },
    );
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "There was an error. This buyer may have already been created. Try refreshing your screen.",
      },
      { root: true },
    );
    return error;
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/buyers/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceBuyer", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Buyer" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Buyer" }, { root: true });
    return error;
  }
};

export const reset = async ({ commit, dispatch }) => {
  try {
    commit("reset");
  } catch (error) {
    //
  }
};

export const disable = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/buyers/disable/${payload}`, payload);
    if (response.data.message == "success") {
      commit("buyerDisabled", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Buyer" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Buyer" }, { root: true });
  }
};

export const enable = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/buyers/enable/${payload}`, payload);
    if (response.data.message == "success") {
      commit("buyerEnabled", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Buyer" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Buyer" }, { root: true });
  }
};

export const fetchBuyers = async ({ commit }) => {
  try {
    const response = await api.get("/buyers");
    const buyers = arrayToObject(response.data, "id");
    commit("initBuyers", buyers);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Buyer List" }, { root: true });
  }
};

export const fetchBuyer = async ({ commit, dispatch }, uuid) => {
  try {
    const response = await api.get(`/buyers/${uuid}`);
    if (response.data.id) {
      commit("replaceBuyer", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Buyer Data" }, { root: true });
    }
    return response;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Buyer Data" }, { root: true });
    return error;
  }
};

export const destroyBuyer = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/buyers/${payload}`);
    if (response.data.message == "success") {
      commit("buyerRemoved", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Disabling Buyer" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Disabling Buyer" }, { root: true });
  }
};

export const restoreBuyer = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/buyers/restore/${payload}`);
    if (response.data.message == "success") {
      commit("buyerRestored", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Buyer" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Buyer" }, { root: true });
  }
};

export const deleteBuyerDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/docs/${payload.uuid}`);
    if (response.data == 1) {
      commit("buyerDocDeleted", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
  }
};

export const nullifyBuyerStages = async ({ commit, dispatch }, payload) => {
  commit("processDeletedBuyersStage", payload);
};

export const nullifyBuyerTerms = async ({ commit, dispatch }, payload) => {
  commit("processDeletedBuyersTerms", payload);
};

/** ***********************************
 ** Buyer Location Actions
 ************************************ */
export const createBuyerLocation = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/buyer-locations/create", payload);
    if (response.data.code == 201) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Creating Location",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Creating Location",
      },
      { root: true },
    );
    return error;
  }
};

export const updateBuyerLocation = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/buyer-locations/update/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Updating Location",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Location",
      },
      { root: true },
    );
    return error;
  }
};

export const deleteBuyerLocation = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/buyer-locations/delete/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Location",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Location",
      },
      { root: true },
    );
    return error;
  }
};

/** ***********************************
 ** Buyer Contact Actions
 ************************************ */
export const fetchBuyerShareableUsers = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get(`/buyer-contacts/get-buyers-shareable-users/${payload}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const convertBuyerUserToContact = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/buyer-contacts/convert-user", payload);
    if (response.data.code == 201) {
      commit("buyers/replaceBuyer", response.data.data, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Creating Contact",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Contact" }, { root: true });
    return error;
  }
};

export const createBuyerContact = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/buyer-contacts/create", payload);
    if (response.data.code == 201) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Creating Contact",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Contact" }, { root: true });
    return error;
  }
};

export const updateBuyerContact = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/buyer-contacts/update/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Updating Contact",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Contact" }, { root: true });
    return error;
  }
};

export const deleteBuyerContact = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/buyer-contacts/delete/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Contact",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Contact" }, { root: true });
    return error;
  }
};

export const confirmBuyerOptBackInEmail = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/buyer-contacts/send-opt-in-email/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
      commit("ui/setSnackbar", { variant: "success", message: "Sent Opt-In Email Successfully" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Sending Buyer Opt-In Email" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Sending Buyer Opt-In Email" }, { root: true });
    return error;
  }
};

export const dismissBuyerUserFromContacts = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/buyer-contacts/dismiss-user", payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

/** ***********************************
 ** Buyer Notes Actions
 ************************************ */
export const createBuyerNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/buyer-notes/create", payload);
    if (response.data.code == 201) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Buyer Note" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Buyer Note" }, { root: true });
    return error;
  }
};

export const updateBuyerNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/buyer-notes/update/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Buyer Note" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Buyer Note" }, { root: true });
    return error;
  }
};

export const deleteBuyerNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/buyer-notes/delete/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("buyers/replaceBuyer", response.data.data.buyer, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Buyer Note" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Buyer Note" }, { root: true });
    return error;
  }
};
