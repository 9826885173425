import * as api from "@/services/api";

export const fetchTaskCounts = async ({ commit, dispatch }) => {
  try {
    const response = await api.get("/tasks/counts");
    if (response.data) {
      commit("initTaskCount", response.data);
    }

    return response.data;
  } catch (error) {
    // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
    return error;
  }
};

export const fetchCompanyUnreadAlertsCount = async ({ commit, dispatch }) => {
  try {
    const response = await api.get("/company-alerts/unread-count");
    if (response.data.code == 200) {
      commit("initCount", response.data.data);
      commit("initUnreadChatsCount", response.data.data.chatCounts.unread);
      commit("initUnreadLeadsCount", response.data.data.serviceLeads);
    } else {
      // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
    return error;
  }
};

export const updateCompanyProfileMessagesCount = async ({ commit, dispatch }, payload) => {
  commit("initProfileMessagesCount", payload);
};

export const fetchCompanyProfileMessagesCount = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("public-profile-messages/count");
    if (response.data) {
      commit("initProfileMessagesCount", response.data);
    } else {
      // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
    return error;
  }
};

export const fetchCompanyAlerts = async ({ commit, dispatch }) => {
  try {
    const response = await api.get("/company-alerts");
    if (response.data.code == 200) {
      commit("init", response.data.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Company Alerts" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Company Alerts" }, { root: true });
    return error;
  }
};

export const toggleCompanyAlertStatus = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/company-alerts/update-status/${payload.id}`);
    if (response.data.code == 200) {
      commit("updateCompanyAlert", response.data.data.companyAlert);
      commit("initCount", response.data.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Alert Status" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Alert Status" }, { root: true });
    return error;
  }
};

export const markAllCompanyAlertsRead = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/company-alerts/mark-all-read/${payload.id}`);
    if (response.data.code == 200) {
      commit("init", response.data.data);
      commit("initCount", response.data.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Marking All Alerts Read" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Marking All Alerts Read" }, { root: true });
    return error;
  }
};

export const deleteCompanyAlert = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/company-alerts/delete/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("processDeletedCompanyAlert", response.data.data);
      commit("initCount", response.data.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Alert",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Alert",
      },
      { root: true },
    );
    return error;
  }
};

export const deleteAllCompanyAlerts = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/company-alerts/delete-all/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("init", response.data.data);
      commit("initCount", response.data.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting All Alerts",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting All Alerts",
      },
      { root: true },
    );
    return error;
  }
};

/**
 * Chats
 */
export const fetchUnreadChatsCount = async ({ commit, dispatch }) => {
  try {
    const response = await api.get("/company-chat/unread-chats-count");
    if (response.data && response.data.code == 200) {
      commit("initUnreadChatsCount", response.data.data.chatCounts.unread);
    } else {
      // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error getting chat messages count' }, { root: true });
    }
    return response.data;
  } catch (error) {
    // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error getting chat messages count' }, { root: true });
    return error;
  }
};

// Not really used in the app, no pagitation
export const fetchAllUnreadChats = async ({ commit, dispatch }) => {
  try {
    const response = await api.get("/company-chat/all-unread-company-chats");
    if (response.data && response.data.code == 200 && response.data.data && response.data.data.chats) {
      // Saving count to store and returning messges (not saving messages to store)
      commit("initUnreadChatsCount", response.data.data.chats.length);
    } else {
      // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error getting chat messages count' }, { root: true });
    }
    return response.data;
  } catch (error) {
    // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error getting chat messages count' }, { root: true });
    return error;
  }
};
