<template>
  <BModal
    v-if="show"
    :id="id"
    :visible="show"
    :scrollable="scrollable"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :hide-backdrop="hideBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    :no-enforce-focus="noEnforceFocus"
    :static="isStatic"
    :no-fade="noFade"
    :lazy="lazy"
    :no-stacking="noStacking"
    :return-focus="returnFocus"
    :modal-class="modalClass"
    :header-class="modalHeaderClass"
    :hide-footer="hideFooter"
    :body-class="bodyClass"
    :footer-class="footerClass"
    :size="modalSize()"
    @hide="$emit('hide')"
  >
    <template #modal-header>
      <slot name="header">
        <div class="d-flex w-100 ml-auto mr-auto max-w-100" :style="headerStyle">
          <div class="my-auto text-center pl-0">
            <slot name="title">
              <h4>
                {{ title }}
              </h4>
            </slot>
          </div>
          <div class="ml-auto text-center pr-0">
            <slot name="headerButtons" />
            <BushelBtn
              v-if="allowFullScreenToggle"
              class="mr-1"
              :variant="fullScreenToggleVariant"
              :flat="fullScreenToggleFlat"
              :outline="fullScreenToggleOutline"
              :btn-container-class="'modal-close ' + fullScreenToggleClass"
              button-id="expandModal"
              tooltip-placement="left"
              :tooltip-message="allowFullScreenToggleToolip ? (showingFullScreen ? 'Compress to Regular Screen' : 'Expand to Full Screen') : null"
              :disabled="disableFullScreenToggle"
              :style="fullScreenToggleStyle"
              @click="showingFullScreen = !showingFullScreen"
            >
              <i :class="showingFullScreen ? 'fas fa-compress' : 'fas fa-expand'" />
            </BushelBtn>
            <BushelBtn
              button-id="close_modal"
              :variant="closeModalVariant"
              :ripple-color="closeModalVariant"
              :flat="closeModalFlat"
              :outline="closeModalOutline"
              :btn-container-class="'modal-close ' + closeModalClass"
              aria-label="Close"
              :tooltip-message="allowCloseToolip ? 'Close' : null"
              :disabled="disableHeaderClose"
              :style="closeModalStyle"
              @click="closeModal()"
            >
              <i class="fa fa-times-thin text-muted" style="font-size: 1.5em; margin-top: -3px" />
            </BushelBtn>
          </div>
        </div>
      </slot>
    </template>

    <div v-if="loading" style="display: flex; align-items: center; justify-content: center; height: 100%">
      <slot v-if="hasLoadingSlot" name="loading" />
      <Loading v-else />
    </div>

    <div v-if="!loading">
      <slot name="body">
        <div class="mx-3">
          <span v-if="message" v-html-safe="message" />
          <span v-else> Are you completely sure you want to do this? </span>
        </div>
      </slot>
    </div>

    <template v-if="!hideFooter" #modal-footer>
      <slot v-if="hasFooterSlot" name="footer" />
      <template v-else>
        <BushelBtn
          v-if="!hideCancelBtn"
          :variant="cancelBtnVariant"
          :ripple-color="cancelBtnRipple"
          :flat="cancelBtnFlat"
          :outline="cancelBtnOutline"
          :disabled="disableCancelButton"
          @click="closeModal()"
        >
          <span v-if="authorized">
            {{ cancelText || "Cancel" }}
          </span>
          <span v-if="!authorized"> Close </span>
        </BushelBtn>

        <BushelBtn
          v-if="primaryActionBtnText"
          :variant="primaryActionButtonVariant"
          :ripple-color="primaryActionButtonRipple"
          :flat="primaryActionButtonFlat"
          :outline="primaryActionButtonOutline"
          :disabled="primaryActionButtonDisabled"
          @click="$emit('primaryActionBtnClicked')"
        >
          {{ primaryActionBtnText }}
        </BushelBtn>

        <BushelBtn
          v-if="infoActionBtnText"
          :variant="infoActionButtonVariant"
          :ripple-color="infoActionButtonRipple"
          :flat="infoActionButtonFlat"
          :outline="infoActionButtonOutline"
          :disabled="infoActionButtonDisabled"
          @click="$emit('infoActionBtnClicked')"
        >
          {{ infoActionBtnText }}
        </BushelBtn>

        <BushelBtn
          v-if="secondaryActionBtnText"
          :variant="secondaryActionButtonVariant"
          :ripple-color="secondaryActionButtonRipple"
          :flat="secondaryActionButtonFlat"
          :outline="secondaryActionButtonOutline"
          :disabled="secondaryActionButtonDisabled"
          @click="$emit('secondaryActionBtnClicked')"
        >
          {{ secondaryActionBtnText }}
        </BushelBtn>

        <BushelBtn
          v-if="warningActionBtnText"
          :variant="warningActionButtonVariant"
          :ripple-color="warningActionButtonRipple"
          :flat="warningActionButtonFlat"
          :outline="warningActionButtonOutline"
          :disabled="warningActionButtonDisabled"
          @click="$emit('warningActionBtnClicked')"
        >
          {{ warningActionBtnText }}
        </BushelBtn>

        <BushelBtn
          v-if="dangerActionBtnText"
          :variant="dangerActionButtonVariant"
          :ripple-color="dangerActionButtonRipple"
          :flat="dangerActionButtonFlat"
          :outline="dangerActionButtonOutline"
          :disabled="dangerActionButtonDisabled"
          @click="$emit('dangerActionBtnClicked')"
        >
          {{ dangerActionBtnText }}
        </BushelBtn>

        <BushelBtn
          v-if="successActionBtnText"
          :variant="successActionButtonVariant"
          :ripple-color="successActionButtonRipple"
          :flat="successActionButtonFlat"
          :outline="successActionButtonOutline"
          :disabled="successActionButtonDisabled"
          @click="$emit('successActionBtnClicked')"
        >
          {{ successActionBtnText }}
        </BushelBtn>

        <BushelBtn
          v-if="darkActionBtnText"
          :variant="darkActionButtonVariant"
          :ripple-color="darkActionButtonRipple"
          :flat="darkActionButtonFlat"
          :outline="darkActionButtonOutline"
          :disabled="darkActionButtonDisabled"
          @click="$emit('darkActionBtnClicked')"
        >
          {{ darkActionBtnText }}
        </BushelBtn>

        <BushelBtn
          v-if="lightActionBtnText"
          :variant="lightActionButtonVariant"
          :ripple-color="lightActionButtonRipple"
          :flat="lightActionButtonFlat"
          :outline="lightActionButtonOutline"
          :disabled="lightActionButtonDisabled"
          @click="$emit('lightActionBtnClicked')"
        >
          {{ lightActionBtnText }}
        </BushelBtn>

        <BushelBtn
          v-if="!hideConfirmBtn && authorized"
          :variant="confirmButtonVariant"
          :disabled="disableConfirmButton"
          :btn-container-class="confirmButtonClass"
          :ripple-color="confirmButtonRipple"
          :flat="confirmButtonFlat"
          :outline="confirmButtonOutline"
          :loading="submitting"
          @click="$emit('confirm')"
        >
          {{ confirmText || "I'm Sure " }}
        </BushelBtn>
      </template>
    </template>
  </BModal>
</template>

<script>
export default {
  name: "BushelModal",
  components: {},
  props: {
    id: { type: String, default: "BushelModal" },
    show: { type: Boolean, required: true },
    authorized: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    noCloseOnBackdrop: { type: Boolean, default: false },
    hideBackdrop: { type: Boolean, default: false },
    noCloseOnEsc: { type: Boolean, default: false },
    noEnforceFocus: { type: Boolean, default: false },
    noFade: { type: Boolean, default: false },
    noStacking: { type: Boolean, default: false },
    returnFocus: { type: [String, Object] },
    loading: { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },

    isStatic: { type: Boolean, default: false },
    lazy: { type: Boolean, default: false },

    allowFullScreenToggle: { type: Boolean, default: false },
    allowFullScreenToggleToolip: { type: Boolean, default: false },
    allowCloseToolip: { type: Boolean, default: false },
    size: { type: String, default: "lg mh60 mh-sm-50 mh-sm-70" },
    fullSize: { type: String, default: "full mh-sm-50 mh-md-70" },
    startFullscreen: { type: Boolean, default: false },

    title: { type: String },
    message: { type: String },

    hideHeader: { type: Boolean, default: false },
    headerClass: { type: String },
    headerStyle: { type: String },
    modalClass: { type: String },
    bodyClass: { type: String },
    bodyStyle: { type: String },
    hideFooter: { type: Boolean, default: false },
    footerClass: { type: String },

    cancelText: { type: String },
    hideCancelBtn: { type: Boolean, default: false },
    cancelBtnVariant: { type: String, default: "secondary" },
    cancelBtnRipple: { type: String, default: "secondary" },
    cancelBtnFlat: { type: Boolean, default: true },
    cancelBtnOutline: { type: Boolean, default: false },
    disableCancelButton: { type: Boolean, default: false },

    confirmText: { type: String },
    confirmButtonClass: { type: String },
    hideConfirmBtn: { type: Boolean, default: false },
    confirmButtonVariant: { type: String, default: "success" },
    disableConfirmButton: { type: Boolean, default: false },
    confirmButtonRipple: { type: String, default: "success" },
    confirmButtonFlat: { type: Boolean, default: true },
    confirmButtonOutline: { type: Boolean, default: false },

    primaryActionBtnText: { type: String, default: null },
    primaryActionButtonVariant: { type: String, default: "primary" },
    primaryActionButtonRipple: { type: String, default: "primary" },
    primaryActionButtonFlat: { type: Boolean, default: true },
    primaryActionButtonOutline: { type: Boolean, default: false },
    primaryActionButtonDisabled: { type: Boolean, default: false },

    infoActionBtnText: { type: String, default: null },
    infoActionButtonVariant: { type: String, default: "info" },
    infoActionButtonRipple: { type: String, default: "info" },
    infoActionButtonFlat: { type: Boolean, default: true },
    infoActionButtonOutline: { type: Boolean, default: false },
    infoActionButtonDisabled: { type: Boolean, default: false },

    secondaryActionBtnText: { type: String, default: null },
    secondaryActionButtonVariant: { type: String, default: "secondary" },
    secondaryActionButtonRipple: { type: String, default: "secondary" },
    secondaryActionButtonFlat: { type: Boolean, default: true },
    secondaryActionButtonOutline: { type: Boolean, default: false },
    secondaryActionButtonDisabled: { type: Boolean, default: false },

    warningActionBtnText: { type: String, default: null },
    warningActionButtonVariant: { type: String, default: "warning" },
    warningActionButtonRipple: { type: String, default: "warning" },
    warningActionButtonFlat: { type: Boolean, default: true },
    warningActionButtonOutline: { type: Boolean, default: false },
    warningActionButtonDisabled: { type: Boolean, default: false },

    dangerActionBtnText: { type: String, default: null },
    dangerActionButtonVariant: { type: String, default: "danger" },
    dangerActionButtonRipple: { type: String, default: "danger" },
    dangerActionButtonFlat: { type: Boolean, default: true },
    dangerActionButtonOutline: { type: Boolean, default: false },
    dangerActionButtonDisabled: { type: Boolean, default: false },

    successActionBtnText: { type: String, default: null },
    successActionButtonVariant: { type: String, default: "success" },
    successActionButtonRipple: { type: String, default: "success" },
    successActionButtonFlat: { type: Boolean, default: true },
    successActionButtonOutline: { type: Boolean, default: false },
    successActionButtonDisabled: { type: Boolean, default: false },

    darkActionBtnText: { type: String, default: null },
    darkActionButtonVariant: { type: String, default: "dark" },
    darkActionButtonRipple: { type: String, default: "dark" },
    darkActionButtonFlat: { type: Boolean, default: true },
    darkActionButtonOutline: { type: Boolean, default: false },
    darkActionButtonDisabled: { type: Boolean, default: false },

    lightActionBtnText: { type: String, default: null },
    lightActionButtonVariant: { type: String, default: "light" },
    lightActionButtonRipple: { type: String, default: "light" },
    lightActionButtonFlat: { type: Boolean, default: true },
    lightActionButtonOutline: { type: Boolean, default: false },
    lightActionButtonDisabled: { type: Boolean, default: false },
    disableFullScreenToggle: { type: Boolean, default: false },
    disableHeaderClose: { type: Boolean, default: false },

    fullScreenToggleVariant: { type: String, default: "secondary" },
    fullScreenToggleFlat: { type: Boolean, default: true },
    fullScreenToggleOutline: { type: Boolean, default: false },
    fullScreenToggleStyle: { type: String, default: "margin-top: -4px" },
    fullScreenToggleClass: { type: String },

    closeModalVariant: { type: String, default: "dark" },
    closeModalFlat: { type: Boolean, default: true },
    closeModalOutline: { type: Boolean, default: false },
    closeModalStyle: { type: String, default: "margin-top: -4px" },
    closeModalClass: { type: String },
  },
  emits: [
    "hide",
    "confirm",
    "primaryActionBtnClicked",
    "infoActionBtnClicked",
    "secondaryActionBtnClicked",
    "warningActionBtnClicked",
    "dangerActionBtnClicked",
    "successActionBtnClicked",
    "darkActionBtnClicked",
    "lightActionBtnClicked",
  ],
  data() {
    return {
      showingFullScreen: false,
    };
  },
  computed: {
    hasTitleSlot() {
      return this.$slots.title;
    },
    hasHeaderSlot() {
      return this.$slots.header;
    },
    hasBodySlot() {
      return this.$slots.body;
    },
    hasFooterSlot() {
      return this.$slots.footer;
    },
    hasLoadingSlot() {
      return this.$slots.loading;
    },
    modalHeaderClass() {
      if (this.hideHeader) {
        return "d-none";
      }
      return this.headerClass;
    },
  },
  created() {},
  mounted() {
    if (this.startFullscreen) {
      this.showingFullScreen = true;
    }
  },
  beforeUnmount() {},
  methods: {
    modalSize() {
      if (this.showingFullScreen) {
        return this.fullSize;
      }
      return this.size;
    },
    closeModal() {
      this.$emit("hide");
    },
  },
};
</script>

<style scoped>
:deep(.p-0.modal-header) {
  padding: 0 !important;
}
:deep(.m-0.modal-header) {
  margin: 0 !important;
}
</style>

<style lang="scss" scoped></style>
