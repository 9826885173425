export default {
  buyerLeadsExistForState: false,
  licenseTypes: [],
  states: [],
  countries: [],
  certificationTypes: [],
  operationTypes: [],
  categories: {},
  types: {},
  productTypeGroups: [],
  prerollTypes: {},
  extractTypes: {},
  crudeExtractTypes: {},
  cartrideTypes: {},
  cultivars: {},
  growEnvironments: {},
  growMediums: {},
  stateOfMaterials: {},
  trimmingMethods: {},
  dryingMethods: {},
  storageTypes: {},
  containerTypes: {},
  unitsOfMeasurement: {},
  batchTerpenes: {},
  additionalCannabinoids: {},
  harvestCycles: {},
  infusionMethods: {},
  extractionMethods: {},
  packagedUnitSizes: {},
  governmentAgencies: {},
  feminizedTypes: {},
  floweringPeriods: {},
  environmentalIssues: {},
  distillateExtractTypes: {},
  productFlavors: {},
  productAdditives: {},
  testingDocumentTypes: {},
  companyServiceTypes: {},
  companyServiceSubTypes: {},
  cultivarTypes: {},
  buyerTableQuery: {},
  inventoryTableQuery: {},
  shippingOrdersTableQuery: {},
  receivingOrdersTableQuery: {},
  metrcUnitsOfMeasure: [
    {
      QuantityType: 'CountBased',
      Name: 'Each',
      Abbreviation: 'ea',
    },
    {
      QuantityType: 'WeightBased',
      Name: 'Ounces',
      Abbreviation: 'oz',
    },
    {
      QuantityType: 'WeightBased',
      Name: 'Pounds',
      Abbreviation: 'lb',
    },
    {
      QuantityType: 'WeightBased',
      Name: 'Grams',
      Abbreviation: 'g',
    },
    {
      QuantityType: 'WeightBased',
      Name: 'Milligrams',
      Abbreviation: 'mg',
    },
    {
      QuantityType: 'WeightBased',
      Name: 'Kilograms',
      Abbreviation: 'kg',
    },
  ],
};
