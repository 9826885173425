import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const createPricingTier = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/pricing-tiers", payload);
    if (response.data.id) {
      commit("pricingTierAdded", response.data);

      if (response.data.marketplace_tier) {
        dispatch("fetchPricingTiers");
      }

      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Pricing Tier" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Pricing Tier" }, { root: true });
    return error;
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/pricing-tiers/${payload.id}`, payload);
    if (response.data.id) {
      commit("replacePricingTier", response.data);
      if (response.data.marketplace_tier) {
        dispatch("fetchPricingTiers");
      }
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Pricing Tier" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Pricing Tier" }, { root: true });
    return error;
  }
};

export const fetchPricingTiers = async ({ commit }) => {
  try {
    const response = await api.get("/pricing-tiers");
    const pricingTiers = arrayToObject(response.data, "id");
    commit("initPricingTiers", pricingTiers);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Pricing Tier List" }, { root: true });
  }
};

export const fetchPricingTier = async ({ commit, dispatch }, id) => {
  try {
    const response = await api.get(`/pricing-tiers/${id}`);
    if (response.data.id) {
      commit("replacePricingTier", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Pricing Tier Data" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Pricing Tier Data" }, { root: true });
  }
};

export const destroyPricingTier = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/pricing-tiers/${payload}`);
    if (response.data.message == "success") {
      commit("pricingTierRemoved", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Pricing Tier" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Pricing Tier" }, { root: true });
  }
};

export const restorePricingTier = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/pricing-tiers/restore/${payload}`);
    if (response.data.message == "success") {
      commit("pricingTierRestored", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Pricing Tier" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Pricing Tier" }, { root: true });
  }
};

export const createPricingTierPrice = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/pricing-tiers/pricing-tier-price/${payload.pricing_tier_id}`, payload);
    if (response.data.id) {
      commit("replacePricingTier", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Pricing Tier Rule" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Pricing Tier Rule" }, { root: true });
    return "failure";
  }
};

export const destroyPricingTierPrice = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/pricing-tiers/pricing-tier-price/${payload.id}`);
    if (response.data.id) {
      commit("replacePricingTier", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Pricing Tier Rule" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Pricing Tier Rule" }, { root: true });
  }
};
