<template>
  <div
    :class="spinnerClasses"
    :role="role"
  >
    <span class="sr-only">{{ label }}</span>
  </div>
</template>
<script>
export default {
  name: "BSpinner",
  props: {
    label: {
      type: String,
      default: "Loading...",
    },
    small: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    type: {
      type: String,
      default: "border",
      validator(value, props) {
        return ["border", "grow"].includes(value);
      },
    },
    role: {
      type: String,
      default: "status",
    },
    tag: {
      type: String,
      default: "div",
    },
  },
  computed: {
    spinnerClasses() {
      return {
        spinner: true,
        "spinner-border": this.type === "border",
        "spinner-grow": this.type === "grow",
        "spinner-border-sm": this.small,
        "spinner-grow-sm": this.small,
        [`text-${this.variant}`]: true,
      };
    },
  },
};
</script>
