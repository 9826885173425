export const buyerAdded = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const replaceBuyer = (state, payload) => {
  state.list[payload.id] = payload;
};

// export const licenseAdded = (state, payload) => {
// };
//
// export const licenseUpdated = (state, payload) => {
// };
//
// export const licenseDeleted = (state, payload) => {
// };
//
//
// export const licenseDocDeleted = (state, payload) => {
// };

export const buyerDisabled = (state, payload) => {
  const updatedBuyer = { ...state.list[payload], disabled: true };
  state.list = { ...state.list, [payload]: updatedBuyer };
};

export const buyerEnabled = (state, payload) => {
  const updatedBuyer = { ...state.list[payload], disabled: false };
  state.list = { ...state.list, [payload]: updatedBuyer };
};

export const buyerRemoved = (state, payload) => {
  const updatedBuyer = { ...state.list[payload], deleted_at: true };
  state.list = { ...state.list, [payload]: updatedBuyer };
};

export const buyerRestored = (state, payload) => {
  const updatedBuyer = { ...state.list[payload], deleted_at: false };
  state.list = { ...state.list, [payload]: updatedBuyer };
};

export const initBuyers = (state, payload) => {
  state.list = payload;
  state.loaded = true;
};

export const reset = (state, payload) => {
  state.list = {};
  state.loaded = false;
};

export const buyerDocDeleted = (state, payload) => {
  const foundBuyer = state.list[payload.docable_id];
  const updatedDocuments = foundBuyer.documents.filter((doc) => doc.id != payload.id);
  state.list[payload.docable_id] = { ...foundBuyer, documents: updatedDocuments };
};

export const processDeletedBuyersStage = (state, payload) => {
  payload.forEach((buyer, index) => {
    state.list[buyer.id].buyer_stage_id = null;
    state.list[buyer.id].stage = null;
  });
};

export const processDeletedBuyersTerms = (state, payload) => {
  payload.forEach((buyer, index) => {
    state.list[buyer.id].net_terms_id = null;
    state.list[buyer.id].term = null;
  });
};
