export const created = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const replaceOrder = (state, payload) => {
  state.list[payload.id] = payload;
};

export const orderLoaded = (state, payload) => {
  if (!state.list[payload.id]) {
    created(state, payload);
  } else {
    replaceOrder(state, payload);
  }
};

export const archivedOrdersLoaded = (state, payload) => {
  state.list = payload;
  state.archivedOrdersFetched = true;
  state.archivedOrdersLoading = false;
};

export const ordersLoaded = (state, payload) => {
  state.list = payload;
  state.ordersFetched = true;
  state.ordersLoading = false;
};

export const ordersLoading = (state) => {
  state.ordersFetched = false;
  state.ordersLoading = true;
};

export const archivedOrdersLoading = (state) => {
  state.archivedOrdersFetched = false;
  state.archivedOrdersLoading = true;
};

export const replaceAfterArchiveToggle = (state, payload) => {
  const updatedOrder = { ...state.list[payload.id], buyer_archived: payload.buyer_archived };
  state.list = { ...state.list, [payload.id]: updatedOrder };
};

export const updateAfterBulkArchive = (state, payload) => {
  const archiving = !!(payload && payload.archiving);
  if (payload && payload.orderIds && payload.orderIds.length > 0) {
    payload.orderIds.forEach((orderId, orderIdsIndex) => {
      const updatedOrder = { ...state.list[orderId], buyer_archived: archiving };
      state.list = { ...state.list, [orderId]: updatedOrder };
    });
  }
};

export const reset = (state, payload) => {
  state.list = {};
  state.ordersFetched = false;
  state.archivedOrdersFetched = false;
  state.ordersLoading = false;
  state.archivedOrdersLoading = false;
};

export const orderDocCreated = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.docable_id].documents.push(payload) };
};

export const orderDocDeleted = (state, payload) => {
  const foundOrder = state.list[payload.docable_id];
  const updatedDocuments = foundOrder.documents.filter((doc) => (doc.id != payload.id));
  state.list[payload.docable_id] = { ...foundOrder, documents: updatedDocuments };
};

export const createdNote = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.order_id].notes.push(payload) };
};

export const deletedNote = (state, payload) => {
  const updatedNotes = state.list[payload.order_id].notes.filter((note) => (note.id != payload.id));
  state.list[payload.order_id].notes = updatedNotes;
};

export const updatedNote = (state, payload) => {
  const updatedNotes = state.list[payload.order_id].notes.filter((note) => (note.id != payload.id));
  updatedNotes.push(payload);
  const updatedOrder = { ...state.list[payload.order_id], notes: updatedNotes };
  state.list = { ...state.list, [payload.order_id]: updatedOrder };
};

export const updateInjectedOrderItem = (state, payload) => {
  const order = state.list[payload.orderId];
  const item = order.items.find((x) => x.id == payload.orderItemId);
  item.injected_into_inventory = true;
};
