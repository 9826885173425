<template>
  <img :style="styles" :class="classes" :src="'img/icons/' + icon + '.svg'" @clicked="onClicked" />
</template>
<script>
export default {
  props: {
    classes: String,
    icon: String,
    styles: String,
  },
  emits: ["clicked"],
  methods: {
    onClicked() {
      this.$emit("clicked");
    },
  },
};
</script>
