<template>
  <div ref="dropdown" class="dropdown">
    <slot name="trigger" :toggle="toggleDropdown">
      <BushelBtn
        ref="triggerBtn"
        :class="[size === 'sm' ? 'btn-sm' : '', size === 'lg' ? 'btn-lg' : '', btnClass]"
        :outline="outline"
        :variant="variant"
        :style="btnStyle"
        @click="toggleDropdown"
      >
        <span class="mr-1">{{ label }}</span>
        <i class="fa fa-caret-down" />
      </BushelBtn>
    </slot>

    <Teleport v-if="isOpen" to="body">
      <transition name="slide-menu" appear>
        <div v-if="isOpen" ref="dropdownMenu" :class="{ 'dropdown-menu': true, [dropdownMenuClass]: dropdownMenuClass }" role="menu">
          <slot />
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script>
import { computePosition, flip, shift, offset, autoUpdate } from "@floating-ui/dom";
import BushelBtn from "@/components/common/BushelBtn.vue";

export default {
  name: "BDropdown",
  components: { BushelBtn },

  props: {
    variant: { type: String, default: "secondary" },
    size: { type: String, default: "md" },
    outline: { type: Boolean, default: false },
    label: { type: String, default: "Dropdown" },
    placement: { type: String, default: "bottom" },
    alignment: { type: String, default: "center" },
    dropdownMenuClass: { type: String, default: "" },
    btnStyle: { type: [Object, String] },
    btnClass: { type: [String, Object, Array], default: "" },
  },

  data() {
    return {
      isOpen: false,
      cleanup: null,
    };
  },

  beforeUnmount() {
    this.cleanup?.();
    document.removeEventListener("click", this.handleOutsideClick);
    window.removeEventListener("resize", this.updateDropdownPosition);
    window.removeEventListener("scroll", this.updateDropdownPosition, true);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        this.$nextTick(this.setupPosition);
        document.addEventListener("click", this.handleOutsideClick);
      } else {
        document.removeEventListener("click", this.handleOutsideClick);
        this.cleanup?.();
      }
    },

    setupPosition() {
      const reference = this.$refs.dropdown;
      const floating = this.$refs.dropdownMenu;

      if (!reference || !floating) return;

      this.cleanup = autoUpdate(reference, floating, () => {
        computePosition(reference, floating, {
          strategy: "fixed",
          placement: `${this.placement}-${this.alignment}`,
          middleware: [offset(4), flip({ padding: 8 }), shift({ padding: 8 })],
        }).then(({ x, y }) => {
          Object.assign(floating.style, {
            position: "fixed",
            left: `${x}px`,
            top: `${y}px`,
            zIndex: 2000, // Base z-index for main dropdown
          });
        });
      });
    },

    handleOutsideClick(event) {
      // Don't close if clicking inside the dropdown or its submenus
      if (this.$refs.dropdown?.contains(event.target) || event.target.closest(".dropdown-submenu")) {
        return;
      }

      const isOutside = !this.$refs.dropdownMenu?.contains(event.target);
      if (isOutside) {
        this.isOpen = false;
      }

      if (event.target.classList.contains("dropdown-item") || event.target.parentElement.classList.contains("dropdown-item")) {
        this.isOpen = false;
      }
    },
    updateDropdownPosition() {
      if (!this.isOpen) return;
      const trigger = this.$refs.dropdown;
      if (!trigger) return;

      const rect = trigger.getBoundingClientRect();
      const menuElement = document.querySelector(".dropdown-menu");
      if (!menuElement) return;

      if (this.direction === "up" || this.forceDropUp) {
        this.dropdownStyle = {
          ...this.dropdownStyle,
          position: "fixed",
          left: `${rect.left}px`,
          bottom: `${window.innerHeight - rect.top}px`,
        };
      } else {
        this.dropdownStyle = {
          ...this.dropdownStyle,
          position: "fixed",
          left: `${rect.left}px`,
          top: `${rect.bottom}px`,
        };
      }
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  position: fixed;
  margin: 0;
  padding: 0.5rem 0;
  min-width: 150px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  transform-origin: top;
  transition:
    transform 0.2s ease,
    opacity 0.2s ease;
  z-index: 2080;
}

.slide-menu-enter-active,
.slide-menu-leave-active {
  transition:
    transform 0.2s ease,
    opacity 0.2s ease;
}

.slide-menu-enter-from,
.slide-menu-leave-to {
  opacity: 0;
  transform: translateY(-10px) scale(0.95);
}

.slide-menu-enter-to,
.slide-menu-leave-from {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.dropdown-menu.has-submenu {
  overflow: visible !important;
  position: fixed;
  transform-origin: top center;
}
</style>
