import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const createOperation = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/operations", payload);
    if (response.data.id) {
      commit("operationAdded", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Operation" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Operation" }, { root: true });
    return error;
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/operations/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceOperation", response.data);
      return response.data;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Problem Updating Operation",
      },
      { root: true },
    );
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Problem Updating Operation",
      },
      { root: true },
    );
    return error;
  }
};

export const createOperationMinimum = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/minimums", payload);
    if (response.data.id) {
      commit("addMinimum", response.data);
      return response.data;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Problem Updating Operation",
      },
      { root: true },
    );
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Problem Updating Operation",
      },
      { root: true },
    );
    return error;
  }
};

export const deleteOperationDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/docs/${payload.uuid}`);
    if (response.data == 1) {
      commit("operationDocDeleted", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
  }
};

export const deleteOperationMinimum = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/minimums/${payload.minimum_id}`, payload);
    if (response.data.message == "success") {
      commit("removeMinimum", payload);
      return response.data;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Problem Updating Operation",
      },
      { root: true },
    );
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Problem Updating Operation",
      },
      { root: true },
    );
    return error;
  }
};

export const fetchOperations = async ({ commit }) => {
  try {
    const response = await api.get("/operations");
    const operations = arrayToObject(response.data, "id");
    commit("initOperations", operations);
    commit("operationsLoaded");
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Operation List" }, { root: true });
  }
};

export const fetchOperation = async ({ commit, dispatch }, uuid) => {
  try {
    const response = await api.get(`/operations/${uuid}`);
    if (response.data.id) {
      commit("replaceOperation", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Operation Data" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Operation Data" }, { root: true });
  }
};

export const fetchGroups = async ({ commit, dispatch }, uuid) => {
  try {
    const response = await api.get("/operations/groups");
    const groups = arrayToObject(response.data, "id");
    commit("initGroups", groups);
    commit("groupsLoaded");
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Operation List" }, { root: true });
  }
};

export const disableOperation = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/operations/disable/${payload}`);
    if (response.data.message == "success") {
      commit("operationRemoved", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Operation" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Operation" }, { root: true });
  }
};

export const enableOperation = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/operations/enable/${payload}`);
    if (response.data.message == "success") {
      commit("operationRestored", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Operation" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Operation" }, { root: true });
  }
};

export const createLicense = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/licenses", payload);
    if (response.data.id) {
      commit("licenseAdded", response.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Creating Operation License",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Creating Operation License",
      },
      { root: true },
    );
  }
};

export const updateLicense = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/licenses/${payload.id}`, payload);
    if (response.data.id) {
      commit("licenseUpdated", response.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Updating Operation License",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Operation License",
      },
      { root: true },
    );
  }
};

export const deleteLicense = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/licenses/${payload.id}`);
    if (response.data == 1) {
      commit("licenseDeleted", payload);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Operation License",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Operation License",
      },
      { root: true },
    );
  }
};

export const deleteLicenseDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/docs/${payload.uuid}`);
    if (response.data == 1) {
      commit("licenseDocDeleted", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
  }
};

export const createCertification = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/certifications", payload);
    if (response.data.id) {
      commit("certificationAdded", response.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Creating Operation Certification",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Creating Operation Certification",
      },
      { root: true },
    );
  }
};

export const updateCertification = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/certifications/${payload.id}`, payload);
    if (response.data.id) {
      commit("certificationUpdated", response.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Updating Operation Certification",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Operation Certification",
      },
      { root: true },
    );
  }
};

export const deleteCertification = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/certifications/${payload.id}`);
    if (response.data == 1) {
      commit("certificationDeleted", payload);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Operation Certification",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Operation Certification",
      },
      { root: true },
    );
  }
};

export const deleteCertificationDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/docs/${payload.uuid}`);
    if (response.data == 1) {
      commit("certificationDocDeleted", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
  }
};
