<template>
  <div :class="groupClasses">
    <slot />
  </div>
</template>
<script>
export default {
    name: 'BButtonGroup',
    props: {
        size: {
            type: String,
            default: null,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        groupClasses() {
            return {
                'btn-group': !this.vertical,
                'btn-group-lg': this.size === 'lg',
                'btn-group-sm': this.size === 'sm',
                'btn-group-vertical': this.vertical,
            };
        },
    },
}
</script>
