import { deleteProperty } from "@/services/helpers";

export const added = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const reset = (state, payload) => {
  state.list = {};
};

export const replace = (state, payload) => {
  state.list[payload.id] = { ...state.list[payload.id], ...payload };
};

export const removed = (state, payload) => {
  const data = payload;
  state.list = deleteProperty(state.list, data.id.toString());
};

export const init = (state, payload) => {
  state.list = payload;
};
