<template>
  <div
    :class="validFeedbackClass"
    :role="role"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: "BFormValidFeedback",
  props: {
    tag: {
      type: String,
      default: "div",
    },
    state: {
      type: Boolean,
      default: null,
    },
    forceShow: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: "alert",
    },
  },
  computed: {
    validFeedbackClass() {
      return {
        "invalid-feedback": !this.state && !this.forceShow,
        "valid-feedback": this.state || this.forceShow,
      };
    },
  },
};
</script>
