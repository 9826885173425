import { deleteProperty } from "@/services/helpers";

export const setUser = (state, payload) => {
  // this is watched and updates local storage
  state.user = payload;
  state.loggedIn = true;
  state.userDataReady = true;
  state.permissions = payload.permissions;
  // state.kioskUser = {};
  // state.kioskPermissions = [];
};

export const logout = (state, payload) => {
  // this is watched and updates local storage
  state.user = {};
  state.loggedIn = false;
  state.userDataReady = false;
  state.permissions = [];
  state.kioskUser = {};
  state.kioskPermissions = [];
};

export const setUserNotReady = (state, payload) => {
  state.userDataReady = false;
};

export const impersonate = (state, payload) => {
  // this is watched and updates local storage
  if (!state.kioskUser.id) {
    state.userDataReady = false;
    state.kioskUser = state.user;
    state.kioskPermissions = state.permissions;

    state.user = payload;
    state.permissions = payload.permissions;
  } else {
    state.user = payload;
    state.permissions = payload.permissions;
  }
};

export const initInvitations = (state, payload) => {
  state.invitations = payload;
};

export const removeInvitation = (state, payload) => {
  state.invitations = deleteProperty(state.invitations, payload.toString());
};

export const updateUserCurrentCompanyId = (state, payload) => {
  state.user.current_company_id = payload;
};

export const updateUser = (state, payload) => {
  state.user = payload;
};

export const updateUserProfile = (state, payload) => {
  // this is watched and updates local storage
  state.user = payload;
  state.permissions = payload.permissions;
};

export const updateUserPermissions = (state, payload) => {
  // this is watched and updates local storage
  state.permissions = payload;
};

export const stopImpersonating = (state, payload) => {
  // this is watched and updates local storage
  state.user = state.kioskUser;
  state.permissions = state.kioskPermissions;

  state.kioskUser = {};
  state.kioskPermissions = [];
};
