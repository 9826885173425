export const currentCompany = (state, getters, rootState) => {
  const currentCompanyId = rootState.auth?.user?.current_company_id;
  if (currentCompanyId && state.list[currentCompanyId]) {
    return state.list[currentCompanyId];
  }
  return {};
};

export const byCompanyId = (state) => (id) => state.list[id];
export const inArray = (state) => Object.values(state.list);
