export const reloadPage = async ({ commit }, payload) => {
  try {
    //
  } catch (error) {
    //
  }
};

export const setSnackbar = async ({ commit }, payload) => {
  try {
    commit("setSnackbar", payload);
  } catch (error) {
    //
  }
};

export const clearSnackbar = async ({ commit }) => {
  commit("clearSnackbar");
};

export const IncrementNavUIKey = ({ commit }) => {
  commit("IncrementNavUIKey");
};

export const setOnboarding = ({ commit }, payload) => {
  commit("setOnboarding", payload);
};

export const toggleSupportLandingDisplay = ({ commit }) => {
  commit("toggleSupportLandingDisplay");
};

// sidebar
export const toggleSidebarSticky = ({ commit }, payload) => {
  commit("toggleSidebarSticky", payload);
};

export const switchSidebar = ({ commit }, payload) => {
  commit("switchSidebar", payload);
};

// export const changeSidebarActive = ({ commit }, payload) => {
//   commit("changeSidebarActive", payload);
// };

export const handleSupportArticleDrawer = ({ commit }, payload) => {
  commit("handleSupportArticleDrawer", payload);
};

export const setDashboardPopupShown = ({ commit }, payload) => {
  commit("handleSettingDashboardPopupShown", payload);
};
