import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";
import store from "@/store";

// this function is called after the user has logged in, or if the user never logged out.
// it's first job is to verify the user is still logged into the system and not locked.
// If successful, it will fetch the user specific data coresponding to the current company
export const fetchSelf = async ({ commit, dispatch }) => {
  try {
    commit("setUserNotReady");
    const response = await api.get("/auth/self");
    if (response.data.id) {
      const user = response.data;
      commit("setUser", user);
      dispatch("fetchInvitations");
      dispatch("fetchSharedData");
      if (user.current_company_id) {
        dispatch("handleInitCompany");
        /// DONT PUT ANYTHING ELSE HERE. YOU ARE PROBABLY IN THE WRONG SPOT
        /// THIS IS NOT CALLED EVERYTIME. you probaby want fetchSpecificCompanyData
      }
    } else {
      dispatch("logout");
    }
    return response.data;
  } catch (error) {
    dispatch("logout");
    return "error";
  }
};

// this function is called after the user has logged in, or if the user never logged out.
// it's first job is to verify the user is still logged into the system and not locked.
// If successful, it will NOT fetch the user specific data coresponding to the current company
export const fetchUser = async ({ commit, dispatch }) => {
  try {
    commit("setUserNotReady");
    const response = await api.get("/auth/user");
    if (response.data.id) {
      const user = response.data;
      commit("setUser", user);
      dispatch("fetchInvitations");
      // dispatch('fetchSharedData');
      if (user.current_company_id) {
        dispatch("handleInitCompany");
        /// DONT PUT ANYTHING ELSE HERE. YOU ARE PROBABLY IN THE WRONG SPOT
        /// THIS IS NOT CALLED EVERYTIME. you probaby want fetchSpecificCompanyData
      }
    }
    return response.data;
  } catch (error) {
    dispatch("logout");
    return "error";
  }
};

// this function is called after the user has logged in, or if the user never logged out.
// it's first job is to verify the user is still logged into the system and not locked.
// If successful, it will NOT fetch the user specific data coresponding to the current company
export const fetchUserNoDispatching = async ({ commit, dispatch }) => {
  try {
    commit("setUserNotReady");
    const response = await api.get("/auth/user");
    if (response.data.id) {
      const user = response.data;
      commit("setUser", user);
      // dispatch('fetchInvitations');
      // dispatch('fetchSharedData');
      if (user.current_company_id) {
        // dispatch('handleInitCompany');
        /// DONT PUT ANYTHING ELSE HERE. YOU ARE PROBABLY IN THE WRONG SPOT
        /// THIS IS NOT CALLED EVERYTIME. you probaby want fetchSpecificCompanyData
      }
    }
    return response.data;
  } catch (error) {
    dispatch("logout");
    return "error";
  }
};

// A periodic check to make sure the users session is still good
export const verifyUserSession = async ({ commit, dispatch }) => {
  try {
    const response = await api.get("/auth/self");
    if (response.data.id) {
      // this is a check if perhaps they are logged on in 2 devices, and change companies in 1
      if (store.state.auth.user.current_company_id != response.data.current_company_id) {
        this.fetchSelf();
      }
    } else {
      dispatch("logout");
    }
    return response.data;
  } catch (error) {
    dispatch("logout");
    return "error";
  }
};

export const handleInitCompany = ({ commit, dispatch }) => {
  // dispatch('specificCompanyDataReset');
  dispatch("fetchSpecificCompanyData"); // this needs to be in first position
};

//
// Any data that needs to be loaded at login that is shared across all users and companies
// It is called in fetch Self. Leave it there. It seems like it might be reasonable to call it in
// main.vue within layout, but if user is not logged in will spam the screen with error messages.
//
export const fetchSharedData = ({ commit, dispatch }) => {
  dispatch("base/fetchCertificationTypes", "", { root: true });
  dispatch("base/fetchLicenseTypes", "", { root: true });
  dispatch("base/fetchOperationTypes", "", { root: true });
  dispatch("base/fetchCompanyServicesData", "", { root: true });
  dispatch("tags/fetchTagTypes", "", { root: true });
};

//
// Any data that needs to be loaded at login that belongs to a specific company or user
// Note that what you fetch here should not be gated by a permission. For example, the ability to
// view users is a gated permission, but we still need access to the user list in other places of the app
// so server side we allow this particular request through without permission for minimum data required.
//
export const fetchSpecificCompanyData = ({ commit, dispatch }) => {
  dispatch("operations/fetchOperations", "", { root: true });
  dispatch("users/fetchUsers", "", { root: true });
  // dispatch('company/fetchInvitations', '', { root: true });
  dispatch("bulkDiscounts/fetchBulkDiscounts", "", { root: true });
  dispatch("baseCompany/fetchDepartments", "", { root: true });
  dispatch("base/fetchStates", "", { root: true });
  dispatch("base/fetchCountries", "", { root: true });
  dispatch("base/fetchProductData", "", { root: true });
  dispatch("tags/fetchTags", "", { root: true });
  dispatch("companyAlerts/fetchCompanyUnreadAlertsCount", "", { root: true });
};

//
// Data that needs to be reset at logout or when company changes
//
export const specificCompanyDataReset = ({ commit, dispatch }) => {
  if (store.hasModule("base")) {
    commit("base/reset", "", { root: true });
  }
  if (store.hasModule("baseCompany")) {
    commit("baseCompany/reset", "", { root: true });
  }
  if (store.hasModule("brands")) {
    commit("brands/reset", "", { root: true });
  }
  if (store.hasModule("bulkDiscounts")) {
    commit("bulkDiscounts/reset", "", { root: true });
  }
  if (store.hasModule("buyers")) {
    commit("buyers/reset", "", { root: true });
  }
  if (store.hasModule("vendors")) {
    commit("vendors/reset", "", { root: true });
  }
  if (store.hasModule("companyServices")) {
    commit("companyServices/reset", "", { root: true });
  }
  if (store.hasModule("events")) {
    commit("events/reset", "", { root: true });
  }
  if (store.hasModule("tasks")) {
    commit("tasks/reset", "", { root: true });
  }
  if (store.hasModule("inventory")) {
    commit("inventory/reset", "", { root: true });
  }
  if (store.hasModule("shippingOrders")) {
    commit("shippingOrders/reset", "", { root: true });
  }
  if (store.hasModule("receivingOrders")) {
    commit("receivingOrders/reset", "", { root: true });
  }
  if (store.hasModule("dealFlows")) {
    commit("dealFlows/reset", "", { root: true });
  }
  if (store.hasModule("invitations")) {
    commit("invitations/reset", "", { root: true });
  }
  if (store.hasModule("netTerms")) {
    commit("netTerms/reset", "", { root: true });
  }
  if (store.hasModule("operations")) {
    commit("operations/reset", "", { root: true });
  }
  if (store.hasModule("pricingTiers")) {
    commit("pricingTiers/reset", "", { root: true });
  }
  if (store.hasModule("roles")) {
    commit("roles/reset", "", { root: true });
  }
  if (store.hasModule("users")) {
    commit("users/reset", "", { root: true });
  }
  if (store.hasModule("usersSecure")) {
    commit("usersSecure/reset", "", { root: true });
  }
  if (store.hasModule("metrc")) {
    commit("metrc/reset", "", { root: true });
  }
  if (store.hasModule("buyerStages")) {
    commit("buyerStages/reset", "", { root: true });
  }
  if (store.hasModule("companyAlerts")) {
    commit("companyAlerts/reset", "", { root: true });
  }
  if (store.hasModule("tags")) {
    commit("tags/clear", "", { root: true }); // just the tags, not the types
  }
  if (store.hasModule("reporting")) {
    commit("reporting/reset", "", { root: true });
  }
  if (store.hasModule("quickbooks")) {
    commit("quickbooks/reset", "", { root: true });
  }
};

export const setAuthy = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/users/authy", payload);
    if (response.status == 200) {
      dispatch("fetchSelf");
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const login = async ({ commit }, payload) => {
  try {
    const response = await api.post("/auth/login", payload);
    if (response.data.message == "two factor enabled") {
      return response.data.message;
    }
    if (response.data && response.data.id) {
      const user = response.data;
      commit("setUser", user);
      return "success";
    }
    return "error";
  } catch (error) {
    return error;
  }
};

export const loginProfileWidget = async ({ commit }, payload) => {
  try {
    const response = await api.post("/auth/login/storefront", payload);
    if (response.data.message == "two factor enabled") {
      return response.data.message;
    }
    if (response.data && response.data.message == "success") {
      const user = response.data;
      commit("setUser", user);
      return "success";
    }
    return "error";
  } catch (error) {
    return error;
  }
};

export const register = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/auth/register", payload);
    if (response && response.data && response.data.id) {
      commit("setUser", response.data);
      return "success";
    }
    if (response && response.data && response.data == "email taken") {
      return "email taken";
    }
    return "error";
  } catch (error) {
    const errorBag = error.response?.data?.errors || { failure: "unknown" };
    let errorString = "";

    Object.keys(errorBag).forEach((key) => {
      errorString += `${errorBag[key]} `;
    });

    errorString = errorString.replace(/,/g, " ");
    return errorString;
  }
};

export const logout = async ({ commit, dispatch }) => {
  try {
    const response = await api.post("/auth/logout");
    if (response && response.data && response.data.message === "success") {
      commit("logout");
      dispatch("specificCompanyDataReset");
      commit("companies/reset", "", { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const impersonate = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/kiosk/impersonate/${payload}`);
    if (response && response.data.id) {
      // dispatch('companies/fetchCompanies', {}, { root: true });
      // force a reload. we had an issue where an item went into the impersonators inventory
      // instead of the impersonatees. Perhaps because of a session key not set correctly.
      // doesn't really make sense, but trying for now.
      commit("impersonate", response.data);
      window.location.reload(true);
      // dispatch('fetchSelf');
      commit(
        "ui/setSnackbar",
        { variant: "warning", message: "You are the Faceless Man... or woman... the point is, you're not you anymore" },
        { root: true },
      );
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const stopImpersonating = async ({ commit, dispatch }) => {
  try {
    const response = await api.post("/kiosk/leaveImpersonation");
    if (response.status == 200) {
      commit("stopImpersonating");
      dispatch("companies/fetchCompanies", {}, { root: true });
      // force a reload. we had an issue where an item went into the impersonators inventory
      // instead of the impersonatees. Perhaps because of a session key not set correctly.
      // doesn't really make sense, but trying for now.
      window.location.reload(true);

      // dispatch('fetchSelf');

      commit(
        "ui/setSnackbar",
        { variant: "warning", message: "You return to yourself, but now, after so long as someone else, who are you really?" },
        { root: true },
      );
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const updateUserSettings = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/users/${payload.id}`, payload);
    if (response.data && response.data.id) {
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
      commit("updateUserProfile", response.data);
      dispatch("i18n/setLocale", { locale: response.data.language }, { root: true });
    } else if (response.data == "email taken") {
      // duplicate user
      commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "This email is already in use in our system" }, { root: true });
    } else {
      // unknown error
      commit(
        "ui/setSnackbar",
        { variant: "warning", title: "Error", message: "problem updating profile, data has not been saved on server" },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      { variant: "warning", title: "Error", message: "problem updating profile, data has not been saved on server" },
      { root: true },
    );
  }
};

export const updateUserProfileNoSnackbarOrLanguage = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/users/${payload.id}`, payload);
    if (response.data && response.data.id) {
      commit("updateUserProfile", response.data);
    } else {
      commit(
        "ui/setSnackbar",
        { variant: "warning", title: "Error", message: "problem updating profile, data has not been saved on server" },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      { variant: "warning", title: "Error", message: "problem updating profile, data has not been saved on server" },
      { root: true },
    );
  }
};

export const setActiveCompany = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/users/${payload.id}`, payload);
    commit("updateUserCurrentCompanyId", response.data.current_company_id); // do this first or middleware on php side will fail that checks header that contains current_company_id
    dispatch("specificCompanyDataReset");
    dispatch("companies/fetchCompany", payload.current_company_id, { root: true });
    dispatch("fetchSelf");
  } catch (error) {
    commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "problem changing company" }, { root: true });
  }
};

export const setActiveCompanyNoDispatching = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/users/${payload.id}`, payload);
    commit("updateUser", response.data); // do this first or middleware on php side will fail that checks header that contains current_company_id
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "problem changing company" }, { root: true });
  }

  return null;
};

export const acceptInvitation = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/invitations/accept/${payload}`);
    if (response.data == 1) {
      commit("removeInvitation", payload);
      dispatch("companies/fetchCompanies", "", { root: true });
      dispatch("fetchSelf");
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Accepting Invitation" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Accepting Invitation" }, { root: true });
  }
};

export const rejectInvitation = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/invitations/${payload}`);
    if (response.data == 1) {
      commit("removeInvitation", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Rejecting Invitation" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Rejecting Invitation" }, { root: true });
  }
};

export const fetchInvitations = async ({ commit }) => {
  try {
    const response = await api.get("/invitations/own");
    const invitations = arrayToObject(response.data, "id");
    commit("initInvitations", invitations);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Invitations List" }, { root: true });
  }
};
