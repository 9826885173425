export function navTo(route) {
  this.$router.push(route);
}

/** *************************************************************
 * See if user can do something
 ************************************************************** */

// export function userCanOperationCheck(operation, user) {
//   if(!user) {
//     return false;
//   }
//   let operations=user.operations;
//   let role = user.current_role ? user.current_role.toLowerCase() : null;
//   if(role === 'owner' || role === 'admin') {
//     return true;
//   }
//   if(!operations || operations == "undefined") {
//     return false;
//   }
//   // if passed in an array of operations
//   if(Array.isArray(operation)) {
//     let found = false;
//     operation.forEach(function(p) {
//       if(operations.indexOf(p) > -1) {
//         found = true;
//       }
//     });
//     return found;
//   } else {
//     // if given a single permission
//     return (operations.indexOf(operation) > -1);
//   }
// };

export function userIsAdmin(user) {
  if (!user) {
    return false;
  }
  const role = user.current_role ? user.current_role.toLowerCase() : null;
  if (role === "owner" || role === "admin") {
    return true;
  }
  return false;
}

export function userCan(permission, user) {
  if (!user) {
    return false;
  }
  const { permissions } = user;
  const role = user.current_role ? user.current_role.toLowerCase() : null;
  if (role === "owner" || role === "admin") {
    return true;
  }
  if (!permissions || permissions == "undefined") {
    return false;
  }
  // if passed in an array of permisisons
  if (Array.isArray(permission)) {
    let found = false;
    permission.forEach((p) => {
      if (permissions.indexOf(p) > -1) {
        found = true;
      }
    });
    return found;
  }
  // if given a single permission
  return permissions.indexOf(permission) > -1;
}

/** *************************************************************
 * throttle
 * Use to only allow function to be called so many times within a period of time,
 * delay is in miliseconds
 * For instance, if 10 calls are made within a time frame, the first will be sent to Server
 * and then the next will not be sent until the delay period has passed
 *
 *  also can pull in from lodash
 * import throttle from 'lodash.throttle';
 *  initToggle: throttle(function(data){
 *    do thing here
 * },200),
 ************************************************************** */

export function throttle(fn, delay) {
  let lastCall = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    // return fn(...args);
  };
}

/** *************************************************************
 * Debounce
 * Use to bundle calls together, so function will not make a call unless
 * the calling function hasn't been accessed within a period of time.
 * For instance, if 10 calls are made within a time frame, only the tenth will
 * be sent to server.

 *  also can pull in from lodash
 * import debounce from 'lodash.debounce';
 *  initToggle: debounce(function(data){
 *    do thing here
 * },200),
 ************************************************************** */

export function debounce(fn, delay) {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

/** *************************************************************
 * Cookie Create, Read, Delete
 ************************************************************** */
export function getCookie(name) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
}

export function setCookie(name, value, days) {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
}

export function deleteCookie(name) {
  setCookie(name, "", -1);
}

/** *************************************************************
 * darken or lighten color
 *
 * parameters:
 * hex — a hex color value such as “#abc” or “#123456” (the hash is optional)
 * lum — the luminosity factor, i.e. -0.1 is 10% darker, 0.2 is 20% lighter, etc.
 ************************************************************** */

export function ColorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = "#";
  let c;
  let i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
}

/** *************************************************************
 * Convert Array into object
 ************************************************************** */
export const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

/** *************************************************************
 * Add Commas to Numbers
 ************************************************************** */
export function numberWithCommas(x) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

/** **************************
 * remove array item value
 **************************** */
export function removeArrayItemValue(arr, item) {
  const index = arr.indexOf(item);
  if (index > 0) {
    return arr.splice(index, 1);
  }
  return [];
}

/** *************************************************************
 * Delete Item from object by returning new object without that property
 * Helper function for:
 *   const { [key]: value, ...payloadRemoved } = obj;
 * https://stackoverflow.com/questions/34401098/remove-a-property-in-an-object-immutably
 * if key above is a number, needs to be converted to a string
 *
 * Function should be called like let remaining = deleteProperty(state.list, key);
 * Function should be called like let remaining = deleteProperty(state.list, key.toString());
 ************************************************************** */
export const deleteProperty = (obj, key) => {
   
  const { [key]: _, ...newObj } = obj;
  return newObj;
};

/** *************************************************************
 * Deep Clone. intensive CPU
 * an alternate is JSON.parse(JSON.stringify(obj)), but requies valid json
 ************************************************************** */
export function deepCloneObj(obj) {
  const clone = {};
  Object.entries(obj).forEach(([key, value]) => {
    clone[key] = value != null && typeof value === "object" ? deepCloneObj(value) : value;
  });
  return clone;
}

/** *************************************************************
 * Deep Clone Array of Objects. intensive CPU
 ************************************************************** */
export function deepCloneObjInArray(arr) {
  return arr.map((obj) => {
    const clone = {};
    Object.entries(obj).forEach(([key, value]) => {
      clone[key] = value != null && typeof value === "object" ? deepCloneObj(value) : value;
    });
    return clone;
  });
}

/** *************************************************************
 * Search through an array of objects by key, [{\id in obj},{\id in obj}]
 * this can also be done with es6, like let obj = objArray.find(obj => obj.id == 3);
 ************************************************************** */

export function findObjectByKey(array, key, value) {
  return array.find((obj) => obj[key] === value) || null;
}

/** *************************************************************
 * Test if object is empty
 ************************************************************** */
export function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

/** *************************************************************
 * Update string prototype to add a Capitalize text function to each word in a string
 ************************************************************** */
String.prototype.capitalizeSentence = function () {
  return this.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

export function capitalize(value) {
  if (!value && value !== 0) {
    return "";
  }

  return value.toString().charAt(0).toUpperCase() + value.slice(1);
}

/** *************************************************************
 * Force reload of assets. We should have cache busting in place, but sometimes the user may
 * not navigate away from site for the entire night, then won't ever ask server for update.
 * this function is a way to force the reload
 ************************************************************** */
export function reloadScreen() {
  window.location.reload(true);
}

/** *************************************************************
 * Download to CSV
 ************************************************************** */
export function exportToCsv(filename, objArray) {
  const downloadedName = `${filename}.csv`;
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  const header = `${Object.keys(array[0])
    .map((value) => `"${value}"`)
    .join(",")} \r\n`;

  const csvFile = array.reduce((csvStr, next) => {
    csvStr += `${Object.values(next)
      .map((value) => (value !== null ? `"${value}"` : ""))
      .join(",")} \r\n`;
    return csvStr;
  }, header);

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, downloadedName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", downloadedName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
/** *************************************************************
 * ScreenSize Helper
 ************************************************************** */
export const screens = {
  "xs-max": 575,
  "sm-min": 576,
  "sm-max": 767,
  "md-min": 768,
  "md-max": 991,
  "lg-min": 992,
  "lg-max": 1199,
  "xl-min": 1200,
};
//
// export function isScreen(size) {
//   const screenPx = window.innerWidth;
//   return (screenPx >= screens[`${size}-min`] || size === 'xs')
//     && (screenPx <= screens[`${size}-max`] || size === 'xl');
// }

export function screenSize() {
  return window.innerWidth;
}

/** *************************************************************
 * File Helpers
 ************************************************************** */
export function getFileName(fileUrl) {
  if (fileUrl && fileUrl.length) {
    const n = fileUrl.lastIndexOf("/"); // strip path
    const x = fileUrl.indexOf("-", n); // strip timestamp
    return fileUrl.substring(x + 1);
  }
  return "";
}

/** *************************************************************
 * Find data in array by id and return object or found item key value
 ************************************************************** */
export function findById(data, itemId, returnItem = null) {
  const results = data.find((i) => i.id == itemId);
  if (typeof results !== "undefined" && returnItem != null) {
    return results[returnItem];
  }
  return results;
}

/** *************************************************************
 * Check if Objects are equal. There are limitations to this function.
 * if you need robust checking please consider another option.
 * see: https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
 ************************************************************** */
export function basicObjectEqualityCheck(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/** *************************************************************
 * Array Helpers
 ************************************************************** */
export function arrayObjFindAndReplace(array, objProperty, compareValue, replaceWith) {
  let found = false;

  array.forEach((item, index) => {
    if (item[objProperty] === compareValue) {
      found = true;
      array[index] = replaceWith;
    }
  });

  return found;
}

/** *************************************************************
 * Validation Helpers
 ************************************************************** */
export function validateEmail(email) {
  const pattern1 =
    /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
  const pattern2 = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const pattern3 =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const pattern4 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // const pattern5 = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; // This one doesnt allot @word.two.com
  if (!pattern1.test(String(email).toLowerCase())) {
    return false;
  }
  if (!pattern2.test(email)) {
    return false;
  }
  if (!pattern3.test(email)) {
    return false;
  }
  if (!pattern4.test(email)) {
    return false;
  }
  // if (!pattern5.test(email)) {
  //   console.log('pattern5')
  //   return false;
  // }
  return true;
}

export function validatePhone(input) {
  try {
    const ISD_CODES = [
      93, 355, 213, 1684, 376, 244, 1264, 672, 1268, 54, 374, 297, 61, 43, 994, 1242, 973, 880, 1246, 375, 32, 501, 229, 1441, 975, 591, 387, 267, 55,
      246, 1284, 673, 359, 226, 257, 855, 237, 1, 238, 1345, 236, 235, 56, 86, 61, 61, 57, 269, 682, 506, 385, 53, 599, 357, 420, 243, 45, 253, 1767,
      1809, 1829, 1849, 670, 593, 20, 503, 240, 291, 372, 251, 500, 298, 679, 358, 33, 689, 241, 220, 995, 49, 233, 350, 30, 299, 1473, 1671, 502,
      441481, 224, 245, 592, 509, 504, 852, 36, 354, 91, 62, 98, 964, 353, 441624, 972, 39, 225, 1876, 81, 441534, 962, 7, 254, 686, 383, 965, 996,
      856, 371, 961, 266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692, 222, 230, 262, 52, 691, 373, 377, 976, 382, 1664, 212,
      258, 95, 264, 674, 977, 31, 599, 687, 64, 505, 227, 234, 683, 850, 1670, 47, 968, 92, 680, 970, 507, 675, 595, 51, 63, 64, 48, 351, 1787, 1939,
      974, 242, 262, 40, 7, 250, 590, 290, 1869, 1758, 590, 508, 1784, 685, 378, 239, 966, 221, 381, 248, 232, 65, 1721, 421, 386, 677, 252, 27, 82,
      211, 34, 94, 249, 597, 47, 268, 46, 41, 963, 886, 992, 255, 66, 228, 690, 676, 1868, 216, 90, 993, 1649, 688, 1340, 256, 380, 971, 44, 1, 598,
      998, 678, 379, 58, 84, 681, 212, 967, 260, 263,
    ];
    // extract numbers from string
    const thenum = input.match(/[0-9]+/g).join("");
    const totalnums = thenum.length;
    const ISDcode = thenum.substring(0, totalnums - 10);

    // phone numbers are generally of 8 to 16 digits
    if (totalnums >= 8 && totalnums <= 16) {
      if (ISDcode) {
        if (ISD_CODES.includes(parseInt(ISDcode, 10))) {
          return true;
        }
        return false;
      }
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}

/** *************************************************************
 * Form Input Helpers
 ************************************************************** */
export function numbersOnly(event) {
  const evt = event || window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }
}

/** *************************************************************
 * Display Helpers
 ************************************************************** */
export function formatPhoneNumber(phoneNumber) {
  const cleaned = `${phoneNumber}`.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return cleaned;
}

export function setupArrayAsString(data, key, sep = "/") {
  let returnData = "";
  if (data && data.length > 0) {
    data.forEach((item, itemIndex) => {
      if (itemIndex == 0) {
        returnData = item[key];
      } else {
        returnData = `${returnData} ${sep} ${item[key]}`;
      }
    });
  }
  return returnData;
}

/** *************************************************************
 * Device Detection Helpers
 ************************************************************** */
export function isMobile() {
  let isMobileBool = false; // initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      navigator.userAgent.substr(0, 4),
    )
  ) {
    isMobileBool = true;
  }
  return isMobileBool;
}
