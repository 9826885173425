import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const inviteUser = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/invitations", payload);
    if (response.data.id) {
      commit("invitationAdded", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Inviting User" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "Problem Inviting User" }, { root: true });
  }
};

export const destroyInvitation = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/invitations/${payload}`);
    if (response.data == 1) {
      commit("invitationRemoved", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Invitation" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Invitation" }, { root: true });
  }
};

export const fetchInvitations = async ({ commit }) => {
  try {
    const response = await api.get("/invitations");
    const invitations = arrayToObject(response.data, "id");
    commit("initInvitations", invitations);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Invitation List" }, { root: true });
  }
};
