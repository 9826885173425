import * as api from "@/services/api";

/** ***************************************
 * Inventory Reporing Actions
 *************************************** */
export const fetchCustomInventoryReports = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/reporting/fetch-custom-inventory-report");
    if (response.data.code == 200) {
      commit("initCustomReportslist", response.data.data.reports);
      commit("initDefaultCustomReportslist", response.data.data.defaultReport);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Fetching Saved Reports",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Fetching Saved Reports",
      },
      { root: true },
    );
    return error;
  }
};

export const fetchInventoryReport = async ({ commit }, payload) => {
  try {
    const response = await api.get(`/reporting/inventory-report/${payload.id}?includeEmptyBatches=${payload.includeNoQuantityBatches}`, payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Report" }, { root: true });
    return error;
  }
};

export const setDefaultCustomInventoryReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/update-default-custom-inventory-report", payload);
    if (response.data.code == 200) {
      commit("initDefaultCustomReportslist", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Updating Default Report" }, { root: true });
      return response.data;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return error;
  }
};

export const saveCustomInventoryReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/store-custom-inventory-report", payload);
    if (response.data.code == 201) {
      commit("addCustomInventoryReport", response.data.data.report);
      commit("initDefaultCustomReportslist", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Saving Report" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Saving Report",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Saving Report",
      },
      { root: true },
    );
    return error;
  }
};

export const updateCustomInventoryReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/reporting/update-custom-inventory-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceCustomInventoryReport", response.data.data.report);
      commit("initDefaultCustomReportslist", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Updated" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    return error;
  }
};

export const deleteCustomInventoryReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/reporting/delete-custom-inventory-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("removedCustomInventoryReport", response.data.data.report);
      commit("initDefaultCustomReportslist", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Deleted Successfully" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Report",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Report",
      },
      { root: true },
    );
    return error;
  }
};

/** ***************************************
 * All Product Sales Reporing Actions
 *************************************** */
export const fetchAllProductSalesReport = async ({ commit }, payload) => {
  try {
    const response = await api.get(`/reporting/all-product-sales-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Getting All Product Sales Report",
      },
      { root: true },
    );
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Getting All Product Sales Report",
      },
      { root: true },
    );
    return error;
  }
};

export const fetchCustomAllSalesReports = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/reporting/fetch-custom-all-product-sales-report");
    if (response.data.code == 200) {
      commit("initCustomAllProductSalesReportslist", response.data.data.reports);
      commit("initDefaultCustomAllProductSalesReportslist", response.data.data.defaultReport);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Fetching Saved Reports",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Fetching Saved Reports",
      },
      { root: true },
    );
    return error;
  }
};

export const runAllProductSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-all-product-sales-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    // if (response && response.status == 200 && response.data) {
    //   return response.data.reportData;
    // }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Getting All Product Sales Report",
      },
      { root: true },
    );
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Getting All Product Sales Report",
      },
      { root: true },
    );
    return error;
  }
};

export const setDefaultAllProductSalesInventoryReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/update-default-custom-all-product-sales-report", payload);
    if (response.data.code == 200) {
      commit("initDefaultCustomAllProductSalesReportslist", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Updating Default Report" }, { root: true });
      return response.data;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return error;
  }
};

export const saveCustomAllProductSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/store-custom-all-product-sales-report", payload);
    if (response.data.code == 201) {
      commit("addCustomAllProductSalesReport", response.data.data.report);
      commit("initDefaultCustomAllProductSalesReportslist", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Saving Report" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Saving Report",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Saving Report",
      },
      { root: true },
    );
    return error;
  }
};

export const updateCustomAllProductSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/reporting/update-custom-all-product-sales-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceCustomAllProductSalesReport", response.data.data.report);
      commit("initDefaultCustomAllProductSalesReportslist", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Updated" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    return error;
  }
};

export const deleteCustomAllproductSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/reporting/delete-custom-all-product-sales-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("removedCustomAllProductSalesReport", response.data.data.report);
      commit("initDefaultCustomAllProductSalesReportslist", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Deleted Successfully" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Report",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Report",
      },
      { root: true },
    );
    return error;
  }
};

/** ***************************************
 * Seller Order Reporing Actions
 *************************************** */
export const fetchSellerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/seller-order-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const fetchCustomSellerOrderReports = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/reporting/fetch-custom-seller-order-reports");
    if (response.data.code == 200) {
      if (response.data.data.reports) {
        commit("initCustomSellerOrderReportslist", response.data.data.reports);
      }
      if (response.data.data.defaultReport) {
        commit("initDefaultCustomSellerOrderReport", response.data.data.defaultReport);
      }
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Fetching Custom Reports",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Fetching Custom Reports",
      },
      { root: true },
    );
    return error;
  }
};

export const runSellerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-seller-order-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const setDefaultSellerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/update-default-custom-seller-order-report", payload);
    if (response.data.code == 200) {
      commit("initDefaultCustomSellerOrderReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Updating Default Report" }, { root: true });
      return response.data;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return error;
  }
};

export const saveCustomSellerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/store-custom-seller-order-report", payload);
    if (response.data.code == 201) {
      commit("addCustomSellerOrderReport", response.data.data.report);
      commit("initDefaultCustomSellerOrderReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Saving Report" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Saving Report" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Saving Report" }, { root: true });
    return error;
  }
};

export const updateCustomSellerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/reporting/update-custom-seller-order-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceCustomSellerOrderReport", response.data.data.report);
      commit("initDefaultCustomSellerOrderReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Updated" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    return error;
  }
};

export const deleteCustomSellerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/reporting/delete-custom-seller-order-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("removeCustomSellerOrderReport", response.data.data.report);
      commit("initDefaultCustomSellerOrderReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Deleted Successfully" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Report",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Report",
      },
      { root: true },
    );
    return error;
  }
};

/** ***************************************
 * Buyer Order Reporing Actions
 *************************************** */
export const fetchBuyerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/buyer-order-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const fetchCustomBuyerOrderReports = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/reporting/fetch-custom-buyer-order-reports");
    if (response.data.code == 200) {
      if (response.data.data.reports) {
        commit("initCustomBuyerOrderReportslist", response.data.data.reports);
      }
      if (response.data.data.defaultReport) {
        commit("initDefaultCustomBuyerOrderReport", response.data.data.defaultReport);
      }
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Fetching Custom Reports",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Fetching Custom Reports",
      },
      { root: true },
    );
    return error;
  }
};

export const runBuyerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-buyer-order-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const setDefaultBuyerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/update-default-custom-buyer-order-report", payload);
    if (response.data.code == 200) {
      commit("initDefaultCustomBuyerOrderReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Updating Default Report" }, { root: true });
      return response.data;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return error;
  }
};

export const saveCustomBuyerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/store-custom-buyer-order-report", payload);
    if (response.data.code == 201) {
      commit("addCustomBuyerOrderReport", response.data.data.report);
      commit("initDefaultCustomBuyerOrderReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Saving Report" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Saving Report" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Saving Report" }, { root: true });
    return error;
  }
};

export const updateCustomBuyerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/reporting/update-custom-buyer-order-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceCustomBuyerOrderReport", response.data.data.report);
      commit("initDefaultCustomBuyerOrderReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Updated" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    return error;
  }
};

export const deleteCustomBuyerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/reporting/delete-custom-buyer-order-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("removeCustomBuyerOrderReport", response.data.data.report);
      commit("initDefaultCustomBuyerOrderReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Deleted Successfully" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Report",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Report",
      },
      { root: true },
    );
    return error;
  }
};

/** ***************************************
 * Basic Reporting Actions
 *************************************** */
export const runBasicSellerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-basic-seller-order-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const runBasicBuyerOrderReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-basic-buyer-order-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const runBasicProductSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-basic-product-sales-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const runInventoryOverviewReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-inventory-overview-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const runBasicSellerSamplesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-basic-seller-samples-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const runBasicSellerBrandsReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-basic-seller-brands-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

/** ***************************************
 * Performance Reporting Actions
 *************************************** */
export const runProductPerformanceReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-product-performance-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const runBuyerPerformanceReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-buyer-performance-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const runBuyerPerformanceDrillDownReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-buyer-performance-report-drilldown", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

/** ***************************************
 * Team Sales Reporting Actions
 *************************************** */
export const fetchTeamSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/team-sales-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const runSalesRepPerformanceReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/run-sales-rep-report", payload);
    if (response.data.code == 200) {
      return response.data.data.reportData;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Report" }, { root: true });
    return error;
  }
};

export const fetchCustomTeamSalesReports = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/reporting/fetch-custom-team-sales-reports");
    if (response.data.code == 200) {
      if (response.data.data.reports) {
        commit("initCustomTeamSalesReportslist", response.data.data.reports);
      }
      if (response.data.data.defaultReport) {
        commit("initDefaultCustomTeamSalesReport", response.data.data.defaultReport);
      }
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Fetching Custom Reports",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Fetching Custom Reports",
      },
      { root: true },
    );
    return error;
  }
};

export const setDefaultTeamSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/update-default-custom-team-sales-report", payload);
    if (response.data.code == 200) {
      commit("initDefaultCustomTeamSalesReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Updating Default Report" }, { root: true });
      return response.data;
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Default Report",
      },
      { root: true },
    );
    return error;
  }
};

export const saveCustomTeamSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/reporting/store-custom-team-sales-report", payload);
    if (response.data.code == 201) {
      commit("addCustomTeamSalesReport", response.data.data.report);
      commit("initDefaultCustomTeamSalesReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Success Saving Report" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Saving Report" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Saving Report" }, { root: true });
    return error;
  }
};

export const updateCustomTeamSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/reporting/update-custom-team-sales-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceCustomTeamSalesReport", response.data.data.report);
      commit("initDefaultCustomTeamSalesReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Updated" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Report" }, { root: true });
    return error;
  }
};

export const deleteCustomTeamSalesReport = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/reporting/delete-custom-team-sales-report/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("removeCustomTeamSalesReport", response.data.data.report);
      commit("initDefaultCustomTeamSalesReport", response.data.data.defaultReport);
      commit("ui/setSnackbar", { variant: "success", message: "Report Deleted Successfully" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Report",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Report",
      },
      { root: true },
    );
    return error;
  }
};
