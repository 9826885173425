<template>
  <b-form-group style="position: relative" class="bushel-form mb-0">
    <div class="row">
      <div v-show="!hideLabel" class="col-12">
        <div class="d-flex" :class="labelWrapperClass">
          <div>
            <label :class="[{ focussed: inputFocused }, labelClass]">{{ labelActive }}</label>
            <i
              v-if="tooltipMessage"
              :id="tooltipId"
              class="fas fa-question text-info ml-2"
              :class="tooltipClass"
              style="cursor: default"
              :style="tooltipStyle"
            />
            <BTooltip v-if="tooltipMessage && tooltipId" :target="tooltipId" :placement="tooltipPlacement" :content="tooltipMessage" />
          </div>
          <div>
            <slot name="label-button"></slot>
          </div>
        </div>
      </div>
      <div class="col-12" :class="inputColClass">
        <b-form-textarea
          :value="modelValue ? modelValue : value"
          :type="type"
          :disabled="disabled"
          :name="name"
          :autofocus="autofocus"
          :rows="rows"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :state="error ? false : null"
          :class="{ 'text-center': centerInput, 'pl-3': prefix && prefix.length >= 1 }"
          :style="textareaStyle"
          @focus="setInputFocused"
          @blur="setInputBlurred"
          @keydown="checkInputLimit"
          @change="setInputChanged"
          @input="onInput"
        />
        <div v-if="prefix && prefix.length >= 1" class="input-prefix" :class="{ focussed: inputFocused }">
          {{ prefix }}
        </div>
        <div v-if="suffix && suffix.length >= 1" class="input-suffix" :class="{ focussed: inputFocused }">
          {{ suffix }}
        </div>
        <div v-if="appendIcon" class="append-icon" :class="appendIcon" @click="$emit('iconClicked')" />
        <div v-if="thin" class="thin-padding" />
        <div v-if="!thin" v-show="!error" class="feedback-spacer" :class="{ 'd-none': inputFocused }" />
        <b-form-invalid-feedback v-if="!thin" v-show="error" id="inputLiveFeedback" class="message hidden-overflow-vis">
          {{ errorText }}
        </b-form-invalid-feedback>
        <b-form-text v-if="!thin" v-show="!error" class="message hidden-overflow-vis" :class="{ 'd-none': !inputFocused }">
          {{ message }}
        </b-form-text>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import BFormGroup from "@/components/base/BFormGroup.vue";
import BFormInvalidFeedback from "@/components/base/BFormInvalidFeedback.vue";
import BFormTextarea from "@/components/base/BFormTextarea.vue";
import BFormText from "@/components/base/BFormText.vue";

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormText,
  },
  props: {
    tooltipMessage: { type: String },
    tooltipPlacement: { type: String, default: "bottom" },
    tooltipId: { type: String },
    tooltipClass: { type: String },
    tooltipStyle: { type: Object },
    autofocus: { type: Boolean },
    labelColClass: { type: String },
    labelClass: { type: String, default: "" },
    inputColClass: { type: String },
    colWrapSize: { type: String },
    type: { type: String, default: "text" },
    labelWrapperClass: { type: String, default: "justify-content-between" },
    name: { type: String },
    value: { type: null },
    modelValue: { type: null },
    message: { type: String },
    placeholder: { type: String },
    error: { type: Boolean },
    errorText: { type: String },
    labelActive: { type: String },
    suffix: { type: String },
    prefix: { type: String },
    disabled: { type: Boolean, default: false },
    appendIcon: { type: String },
    textareaStyle: { type: String },
    hideLabel: { type: Boolean, default: false },
    centerInput: { type: Boolean, default: false },
    labelFixed: { type: Boolean, default: false },
    thin: { type: Boolean, default: false },
    autocomplete: { type: String },
    rows: { type: Number, default: 3 },
    textLimit: { type: Number, default: null },
  },
  emits: ["update:modelValue", "input", "inputFocussed", "inputBlurred", "change", "iconClicked"],
  data: () => ({
    inputFocused: false,
  }),

  methods: {
    onInput(event) {
      this.$emit("update:modelValue", event.target.value);
      this.$emit("input", event.target.value);
    },
    setInputFocused() {
      this.inputFocused = true;
      this.$emit("inputFocussed");
    },
    setInputBlurred() {
      this.inputFocused = false;
      this.$emit("inputBlurred");
    },
    setInputChanged() {
      if (this.textLimit && this.value && this.value.length > this.textLimit) {
        this.$emit("change", this.value.substring(0, this.textLimit));
      } else {
        this.$emit("change", this.value);
      }
    },
    checkInputLimit(evt) {
      if (this.value && this.textLimit && this.value.length >= this.textLimit) {
        if (evt.keyCode >= 48 && evt.keyCode <= 90) {
          // Allow for other keys not to be pressed.
          evt.preventDefault();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@sass/_variables.scss";

textarea {
  color: #6e7d87;
}

textarea:focus {
  box-shadow: none;
}

textarea.is-invalid {
  border: 1px solid $danger;
  background-image: none;
}

textarea.is-valid {
  border: 1px solid #ced4da;
  background-image: none;
}

textarea:focus.is-valid {
  border-color: $primary;
  box-shadow: none;
}

textarea:focus.is-invalid {
  box-shadow: none;
}
</style>
