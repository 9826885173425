import clonedeep from "lodash.clonedeep";
import { arrayToObject, deleteProperty } from "../../../services/helpers";

export const addPermission = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.id].permissions.push(payload.type.toLowerCase()) };
};

export const removePermission = (state, payload) => {
  const updatedPermissions = state.list[payload.id].permissions.filter((permission) => permission !== payload.type.toLowerCase());
  state.list[payload.id].permissions = updatedPermissions;
};

export const toggleAllPermissions = (state, payload) => {
  state.list[payload.id].permissions = payload.permissions;
};

export const addOperation = (state, payload) => {
  const newVal = clonedeep(state.list[payload.companyUserId]);
  newVal.operationsArray.push(payload.operationId);
  state.list[payload.companyUserId] = newVal;
};

export const removeOperation = (state, payload) => {
  const updatedOperations = state.list[payload.companyUserId].operationsArray.filter((operation) => operation != payload.operationId);
  state.list[payload.companyUserId].operationsArray = updatedOperations;
};

export const toggleAllOperations = (state, payload) => {
  const newVal = clonedeep(state.list[payload.companyUserId]);
  newVal.operationsArray = payload.operationsArray;
  state.list[payload.companyUserId] = newVal;
};

export const updateUser = (state, payload) => {
  state.list[payload.id] = { ...state.list[payload.id], ...payload };
};

export const replaceUser = (state, payload) => {
  state.list[payload.id] = payload;
};

export const userRemoved = (state, payload) => {
  state.list = deleteProperty(state.list, payload.toString());
};

export const init = (state, payload) => {
  const list = [];
  payload.forEach((item) => {
    const itemVar = { ...item };
    itemVar.permissions = null;
    list.push(itemVar);
  });
  state.list = arrayToObject(list, "id");
  state.loaded = true;
};

export const attachPermissions = (state, payload) => {
  state.list[payload.id].permissions = payload.permissions;
};

export const attachOperations = (state, payload) => {
  state.list[payload.id].operationsArray = payload.operations;
};

export const reset = (state, payload) => {
  state.list = {};
  state.loaded = false;
};
