import { deleteProperty } from "@/services/helpers";

export const companyAdded = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const licenseAdded = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.licensable_id].licenses.push(payload) };
};

export const licenseUpdated = (state, payload) => {
  const updatedLicenses = state.list[payload.licensable_id].licenses.filter((license) => license.id != payload.id);
  updatedLicenses.push(payload);
  const updatedCompany = { ...state.list[payload.licensable_id], licenses: updatedLicenses };
  state.list = { ...state.list, [payload.licensable_id]: updatedCompany };
};

export const licenseDeleted = (state, payload) => {
  const updatedLicenses = state.list[payload.licensable_id].licenses.filter((license) => license.id != payload.id);
  state.list[payload.licensable_id].licenses = updatedLicenses;
};

export const licenseDocDeleted = (state, payload) => {
  // find correct company
  const foundCompany = state.list[payload.company_id];
  // find the correct license
  const licenseIndex = foundCompany.licenses.findIndex((license) => license.id == payload.docable_id);
  const foundLicense = foundCompany.licenses[licenseIndex];
  // filter out the deleted document
  const updatedDocuments = foundLicense.documents.filter((doc) => doc.id != payload.id);
  // update the license in state
  foundLicense.documents = updatedDocuments;
};

export const replaceCompanyServiceBanner = (state, payload) => {
  state.list[payload.companyId].servicesBannerLink = payload.servicesBannerLink;
};

export const replaceCompany = (state, payload) => {
  state.list[payload.id] = payload;
};

export const setCurrentCompaniesFetched = (state, payload) => {
  state.currentCompanyFetched = true;
};

export const companyRemoved = (state, payload) => {
  state.list = deleteProperty(state.list, payload.toString());
};

export const companyDisabled = (state, payload) => {
  const updated = { ...state.list[payload], disabled: true };
  state.list = { ...state.list, [payload]: updated };
};

export const companyRestored = (state, payload) => {
  const updated = { ...state.list[payload], disabled: false };
  state.list = { ...state.list, [payload]: updated };
};

export const initCompanies = (state, payload) => {
  state.list = payload;
};

export const reset = (state, payload) => {
  state.list = {};
  state.currentCompanyFetched = false;
};

export const initCompanyEmailTemplates = (state, payload) => {
  if (!state.list[payload.company_id].custom_email_templates) {
    state.list[payload.company_id].custom_email_templates = [];
  }
  if (payload.templates && payload.templates.length > 0) {
    state.list[payload.company_id].custom_email_templates = payload.templates;
  }
};

export const companyEmailTemplateAdded = (state, payload) => {
  state.list[payload.company_id].custom_email_templates.push(payload);
};

export const companyEmailTemplateRemoved = (state, payload) => {
  state.list[payload.company_id].custom_email_templates = state.list[payload.company_id].custom_email_templates.filter(
    (arrayItem) => arrayItem.id !== payload.id,
  );
};

export const companyQuickBooksStatusEnabled = (state, payload) => {
  let company = null;
  if (payload.companyId) {
    company = state.list[payload.companyId];
    company.quickbooks_enabled = true;
  }
};
