export const operationAdded = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const replaceOperation = (state, payload) => {
  state.list[payload.id] = payload;
};

export const operationRemoved = (state, payload) => {
  const updatedOperation = { ...state.list[payload], disabled: true };
  state.list = { ...state.list, [payload]: updatedOperation };
};

export const operationsLoaded = (state, payload) => {
  state.loaded = true;
};

export const groupsLoaded = (state, payload) => {
  state.groupsLoaded = true;
};

export const operationRestored = (state, payload) => {
  const updatedOperation = { ...state.list[payload], disabled: false };
  state.list = { ...state.list, [payload]: updatedOperation };
};

export const initOperations = (state, payload) => {
  state.list = payload;
};

export const initGroups = (state, payload) => {
  state.groups = payload;
};

export const reset = (state, payload) => {
  state.list = {};
  state.loaded = false;
};

export const addMinimum = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.operation_id].minimums.push(payload) };
};

export const removeMinimum = (state, payload) => {
  const updatedMinimums = state.list[payload.operation_id].minimums.filter((min) => (min.id != payload.minimum_id));
  state.list[payload.operation_id].minimums = updatedMinimums;
};

export const licenseAdded = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.licensable_id].licenses.push(payload) };
};

export const licenseUpdated = (state, payload) => {
  const updatedLicenses = state.list[payload.licensable_id].licenses.filter((license) => (license.id != payload.id));
  updatedLicenses.push(payload);
  const updatedOperation = { ...state.list[payload.licensable_id], licenses: updatedLicenses };
  state.list = { ...state.list, [payload.licensable_id]: updatedOperation };
};

export const licenseDeleted = (state, payload) => {
  const updatedLicenses = state.list[payload.licensable_id].licenses.filter((license) => (license.id != payload.id));
  state.list[payload.licensable_id].licenses = updatedLicenses;
};

export const operationDocDeleted = (state, payload) => {
  const foundOperation = state.list[payload.docable_id];
  const updatedDocuments = foundOperation.documents.filter((doc) => (doc.id != payload.id));
  state.list[payload.docable_id] = { ...foundOperation, documents: updatedDocuments };
};

export const licenseDocDeleted = (state, payload) => {
  // find correct operation
  const foundOperation = state.list[payload.operation_id];
  // find the correct license
  const licenseIndex = foundOperation.licenses.findIndex((license) => license.id == payload.docable_id);
  const foundLicense = foundOperation.licenses[licenseIndex];
  // filter out the deleted document
  const updatedDocuments = foundLicense.documents.filter((doc) => (doc.id != payload.id));
  // update the license in state
  foundLicense.documents = updatedDocuments;
};

export const certificationAdded = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.certifiable_id].certifications.push(payload) };
};

export const certificationUpdated = (state, payload) => {
  const updatedCertifications = state.list[payload.certifiable_id].certifications.filter((certification) => (certification.id != payload.id));
  updatedCertifications.push(payload);
  const updatedOperation = { ...state.list[payload.certifiable_id], certifications: updatedCertifications };
  state.list = { ...state.list, [payload.certifiable_id]: updatedOperation };
};

export const certificationDeleted = (state, payload) => {
  const updatedCertifications = state.list[payload.certifiable_id].certifications.filter((certification) => (certification.id != payload.id));
  state.list[payload.certifiable_id].certifications = updatedCertifications;
};

export const certificationDocDeleted = (state, payload) => {
  // find correct operation
  const foundOperation = state.list[payload.operation_id];
  // find the correct certification
  const certificationIndex = foundOperation.certifications.findIndex((certification) => certification.id == payload.docable_id);
  const foundCertification = foundOperation.certifications[certificationIndex];
  // filter out the deleted document
  const updatedDocuments = foundCertification.documents.filter((doc) => (doc.id != payload.id));
  // update the certification in state
  foundCertification.documents = updatedDocuments;
};
