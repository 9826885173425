import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const createCompany = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/companies", payload);
    if (response.data.id) {
      commit("companyAdded", response.data);
      // dispatch("auth/updateUserProfileNoSnackbarOrLanguage", { id: response.data.owner_id, current_company_id: response.data.id }, { root: true });
      dispatch("auth/fetchSelf", "", { root: true });

      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Company" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Company" }, { root: true });
    return error;
  }
};

export const createLicense = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/licenses", payload);
    if (response.data.id) {
      commit("licenseAdded", response.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Creating Company License",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Creating Company License",
      },
      { root: true },
    );
  }
};

export const updateLicense = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/licenses/${payload.id}`, payload);
    if (response.data.id) {
      commit("licenseUpdated", response.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Updating Company License",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Company License",
      },
      { root: true },
    );
  }
};

export const deleteLicense = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/licenses/${payload.id}`);
    if (response.data == 1) {
      commit("licenseDeleted", payload);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleting Company License",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleting Company License",
      },
      { root: true },
    );
  }
};

export const deleteLicenseDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/docs/${payload.uuid}`);
    if (response.data == 1) {
      commit("licenseDocDeleted", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/companies/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceCompany", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Company" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Company" }, { root: true });
    return error;
  }
};

export const updateServicesBanner = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/companies/service-banner/${payload.id}`, payload.form);
    if (response.data.code == 200 && response.data.data.id) {
      commit("replaceCompany", response.data.data);
      return response.data.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Company Services Banner" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Company Services Banner" }, { root: true });
    return error;
  }
};

export const updateServicesBannerStoreOnly = async ({ commit, dispatch }, payload) => {
  commit("replaceCompanyServiceBanner", payload);
};

export const updateCompanyDynamicRoute = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/companies/${payload.route}/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceCompany", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Company" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Company" }, { root: true });
    return error;
  }
};

export const leaveCompany = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/companies/${payload}/leave`);
    if (response.status == 200) {
      commit("companyRemoved", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Leaving Company" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Leaving Company" }, { root: true });
  }
};

export const fetchCompanies = async ({ commit }) => {
  try {
    const response = await api.get("/companies");
    const companies = arrayToObject(response.data, "id");
    commit("initCompanies", companies);
    commit("setCurrentCompaniesFetched");
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Company List" }, { root: true });
  }
};

export const fetchCompany = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get(`/companies/${payload}`);
    if (response.data.id) {
      commit("replaceCompany", response.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Fetching Company Data",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Company Data" }, { root: true });
  }
};

// export const fetchSomeCompany = async ({ commit, dispatch }, payload) => {
//   try {
//     const response = await api.get(`/company/${payload.id}`, payload);
//     if (response.data.code == 200) {
//       return response.data.data;
//     }
//     commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
//     return response.data.data;
//   } catch (error) {
//     commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
//     return error;
//   }
// };

export const fetchCurrentCompany = async ({ commit, dispatch }) => {
  try {
    const response = await api.get("/companies/currentCompany");
    if (response.data.id) {
      commit("replaceCompany", response.data);
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Fetching Company Data",
        },
        { root: true },
      );
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Company Data" }, { root: true });
  }
};

export const disableCompany = async ({ commit, dispatch }, payload, loadpay) => {
  try {
    const response = await api.post(`/companies/disable/${payload.id}`);
    if (response.data.message == "success") {
      commit("companyDisabled", payload.id);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Company" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Company" }, { root: true });
  }
};

export const restoreCompany = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/companies/restore/${payload}`);
    if (response.data.message == "success") {
      commit("companyRestored", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Company" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Company" }, { root: true });
  }
};

/** ***********************************
 ** Company Custom Email Templates
 ************************************ */
export const getCustomCompanyEmailTemplates = async ({ commit }, type = null) => {
  try {
    const response = await api.get(`/emails/custom-company-templates/${type}`);
    if (response.data.code == 200) {
      commit("companies/initCompanyEmailTemplates", response.data.data, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Fetching Email Templates",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Fetching Email Templates",
      },
      { root: true },
    );
    return error;
  }
};

export const saveNewCustomCompanyEmailTemplate = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/emails/custom-company-templates", payload);
    if (response.data.code == 201) {
      commit("companies/companyEmailTemplateAdded", response.data.data.template, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Saving Email Template",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Saving Email Template" }, { root: true });
    return error;
  }
};

export const deleteCustomCompanyEmailTemplate = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/emails/custom-company-templates/${payload.id}`);
    if (response.data.code == 200) {
      commit("companies/companyEmailTemplateRemoved", response.data.data.template, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Deleteing Email Template",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Deleteing Email Template",
      },
      { root: true },
    );
    return error;
  }
};

export const createReferral = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/companies/referral", payload);
    if (response.data.id) {
      commit("referralAdded", response.data);
      commit("ui/setSnackbar", { variant: "success", title: "Referral Email Sent", message: "Thank You!" }, { root: true });
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Sending Referral Email" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Sending Referral Email" }, { root: true });
    return error;
  }
};
