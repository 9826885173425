import { arrayToObject, deleteProperty } from "@/services/helpers";

export const reset = (state, payload) => {
  state.list = {};
  state.types = {};
};

export const clear = (state, payload) => {
  state.list = {};
};

export const initTags = (state, payload) => {
  state.list = arrayToObject(payload, "id");
};

export const initTagTypes = (state, payload) => {
  state.types = arrayToObject(payload, "id");
};

export const addTag = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const updateTag = (state, payload) => {
  state.list[payload.id] = { ...state.list[payload.id], ...payload };
};

export const deleteTag = (state, payload) => {
  state.list = deleteProperty(state.list, payload.id.toString());
};
