import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const fetchContainerTypes = async ({ commit }) => {
  try {
    const response = await api.get("/base/product-container-types");
    commit("initContainerTypes", arrayToObject(response.data, "id"));
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product Fields" }, { root: true });
  }
};

export const checkIfBuyerLeadsExistForState = async ({ commit }, payload) => {
  try {
    if (payload.state_id) {
      const response = await api.get(`leads/exist/${payload.state_id}`);
      if (response.data && response.data > 0) {
        commit("setBuyerLeadsExistForState", true);
      } else {
        commit("setBuyerLeadsExistForState", false);
      }
    }
  } catch (error) {
    commit("setBuyerLeadsExistForState", false);
  }
};

export const setBuyerTableQuery = async ({ commit }, payload) => {
  try {
    commit("setBuyerTableQuery", payload);
  } catch (error) {
    // dont do anything
  }
};

export const setInventoryTableQuery = async ({ commit }, payload) => {
  try {
    commit("setInventoryTableQuery", payload);
  } catch (error) {
    // dont do anything
  }
};

export const setReceivingOrdersTableQuery = async ({ commit }, payload) => {
  try {
    commit("setReceivingOrdersTableQuery", payload);
  } catch (error) {
    // dont do anything
  }
};

export const setShippingOrdersTableQuery = async ({ commit }, payload) => {
  try {
    commit("setShippingOrdersTableQuery", payload);
  } catch (error) {
    // dont do anything
  }
};

export const fetchFloweringPeriods = async ({ commit }) => {
  try {
    const response = await api.get("/base/product-flowering-periods");
    commit("initFloweringPeriods", arrayToObject(response.data, "id"));
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product Fields" }, { root: true });
  }
};

export const fetchCultivars = async ({ commit }) => {
  try {
    const response = await api.get("/base/product-cultivars");
    commit("initCultivars", arrayToObject(response.data, "id"));
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product Fields" }, { root: true });
  }
};

export const fetchEnvironmentalIssues = async ({ commit }) => {
  try {
    const response = await api.get("/base/product-environmental-issues");
    commit("initEnvironmentalIssues", arrayToObject(response.data, "id"));
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product Fields" }, { root: true });
  }
};

export const fetchProductFlavors = async ({ commit }) => {
  try {
    const response = await api.get("/base/product-flavors");
    commit("initProductFlavors", arrayToObject(response.data, "id"));
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product Fields" }, { root: true });
  }
};

export const fetchStorageTypes = async ({ commit }) => {
  try {
    const response = await api.get("/base/product-storage-types");
    commit("initStorageTypes", arrayToObject(response.data, "id"));
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product Fields" }, { root: true });
  }
};

export const fetchProductData = async ({ commit }) => {
  try {
    const response = await api.get("/base/product-field-data");
    commit("initProductData", response.data.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product Fields" }, { root: true });
  }
};

export const fetchProductTypeGroups = async ({ commit }) => {
  try {
    const response = await api.get("product-type-groups");
    commit("setProductTypeGroups", response.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product Type Groups" }, { root: true });
  }
};

export const fetchCompanyServicesData = async ({ commit }) => {
  try {
    const response = await api.get("/base/company-service-field-data");
    commit("initCompanyServiceData", response.data.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Company Service Fields" }, { root: true });
  }
};

export const fetchLicenseTypes = async ({ commit }) => {
  try {
    const response = await api.get("/base/licenseTypes");
    commit("setLicenseTypes", response.data);
  } catch (error) {
    //
  }
};

export const fetchCertificationTypes = async ({ commit }) => {
  try {
    const response = await api.get("/base/certificationTypes");
    commit("setCertificationTypes", response.data);
  } catch (error) {
    //
  }
};

export const fetchTypeGroups = async ({ commit }) => {
  try {
    const response = await api.get("product-type-groups");
    commit("setTypeGroups", response.data);
  } catch (error) {
    //
  }
};

export const fetchStates = async ({ commit }) => {
  try {
    const response = await api.get("/base/states");
    commit("setStates", response.data);
  } catch (error) {
    //
  }
};

export const fetchCountries = async ({ commit }) => {
  try {
    const response = await api.get("/base/countries");
    commit("setCountries", response.data);
  } catch (error) {
    //
  }
};

export const fetchOperationTypes = async ({ commit }) => {
  try {
    const response = await api.get("/base/operationTypes");
    commit("setOperationTypes", response.data);
  } catch (error) {
    //
  }
};
