export const reset = (state, payload) => {
  state.departments = [];
  state.paymentsAccepted = {};
  state.departmentsLoaded = false;
};

export const initDepartments = (state, payload) => {
  state.departments = payload;
  state.departmentsLoaded = true;
};

export const initPaymentsAccepted = (state, payload) => {
  state.paymentsAccepted = payload;
};

export const createdDepartment = (state, payload) => {
  state.departments = [...state.departments, payload];
};

export const destroyedDepartment = (state, payload) => {
  state.departments = state.departments.filter((dep) => dep.id !== payload);
};
