<template>
  <div :class="formGroupClasses">
    <slot name="label">
      <div v-if="label">
        <label
          :for="id"
          :class="labelClass"
        >{{ label }}</label>
      </div>
    </slot>
    <slot />
    <slot name="description">
      <small
        v-if="description"
        class="form-text text-muted"
      >{{ description }}</small>
    </slot>
    <slot name="valid-feedback">
      <BFormValidFeedback
        v-if="state"
        :state="state"
      />
    </slot>
    <slot name="invalid-feedback">
      <BFormInvalidFeedback
        v-if="state"
        :state="state"
      />
    </slot>
  </div>
</template>
<script>
import BFormValidFeedback from "./BFormValidFeedback.vue";
import BFormInvalidFeedback from "./BFormInvalidFeedback.vue";

export default {
  name: "BFormGroup",
  components: {
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  props: {
    label: {
      type: String,
    },
    labelFor: {
      type: String,
      default: null,
    },
    labelClasses: {
      type: [String, Object, Array],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: "div",
    },
    state: {
      type: Boolean,
      default: null,
    },
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
    formGroupClasses() {
      return {
        "form-group": true,
        "has-success": this.state === true,
        "has-danger": this.state === false,
      };
    },
    labelClass() {
      return {
        "form-control-label": true,
        ...this.labelClasses,
      };
    },
  },
};
</script>
