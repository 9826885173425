import * as api from "@/services/api";

export const fetchInventory = async ({ commit }) => {
  try {
    commit("reset");
    const response = await api.get("/inventory");
    if (response.data && response.data.data) {
      commit("initProducts", response.data.data.products);
    }
  } catch (error) {
    // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
  }
};

export const fetchArchivedInventory = async ({ commit }, payload) => {
  try {
    commit("reset");
    const response = await api.post("/inventory/archived", payload);
    if (response.data && response.data.data) {
      commit("initProducts", response.data.data.products);
    }
  } catch (error) {
    // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
  }
};

export const fetchAllSingleProductData = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get(`/inventory/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceItem", response.data.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Product" }, { root: true });
    return error;
  }
};

// export const fetchPublicProduct = async ({ commit, dispatch }, payload) => {
//   try {
//     const response = await api.get(`/inventory/public/${payload.id}`, payload);
//     if (response.data.code == 200) {
//       return response.data.data;
//     }
//     commit(
//       "ui/setSnackbar",
//       { variant: "danger", title: "Error", message: 'Error Fetching Product' },
//       { root: true },
//     );
//     return response.data;
//   } catch (error) {
//     commit(
//       "ui/setSnackbar",
//       { variant: "danger", title: "Error", message: 'Error Fetching Product' },
//       { root: true },
//     );
//     return error;
//   }
// };

export const updateInventoryItemListing = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/inventory/${payload.id}`, payload);
    commit("replaceItem", response.data.data);
    commit("ui/setSnackbar", { variant: "success", message: "Product Updated Successfully!" }, { root: true });
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Product" }, { root: true });
  }
};

export const updateBatchSortOrder = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/batches/update-sort-order`, payload);
    if (response.data.code == 200) {
      commit("replaceItem", response.data.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Batch" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Batch" }, { root: true });
    return error;
  }
};

export const bulkUpdateBatchPricing = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`inventory/batch/bulkUpdatePrice/${payload.product_id}`, payload);
    if (response.data && response.data.data) {
      commit("replaceItem", response.data.data);
      // commit("replaceBatches", { product: response.data.data.id, batches: response.data.data.batches });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Batch" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Batch" }, { root: true });
    return error;
  }
};

export const duplicateProduct = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/inventory/product/duplicate", payload);
    if (response.data.code == 200) {
      commit("productAdded", response.data.data);
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Product" }, { root: true });
    return error;
  }
};

export const handleBatchSplit = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/inventory/batch/split", payload);
    if (response.data.code == 200) {
      commit("replaceItem", response.data.data);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message == "Original batch quantity not sufficient for this split") {
      if (error.response.data.product && error.response.data.product.original && error.response.data.product.original.data)
        commit("replaceItem", error.response.data.product.original.data);
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Batch quantity was not sufficient. We refetched it, please retry.",
        },
        { root: true },
      );
    }
  }
};

export const handleBatchTransfer = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/inventory/batch/transfer", payload);
    if (response.data.code == 200) {
      commit("replaceItem", response.data.data);
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Transferring Batch" }, { root: true });
  }
};

export const createProduct = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/inventory/product/create", payload);
    if (response.data.code == 200) {
      commit("productAdded", response.data.data);
    }

    if (payload.productDetails && payload.productDetails.newContainerType && payload.productDetails.newContainerType.name) {
      dispatch("base/fetchContainerTypes", "", { root: true });
    }

    if (payload.productDetails && payload.productDetails.newFloweringPeriod && payload.productDetails.newFloweringPeriod.name) {
      dispatch("base/fetchFloweringPeriods", "", { root: true });
    }

    if (
      payload.productDetails &&
      payload.productDetails.environmentalIssues &&
      payload.productDetails.environmentalIssues.length &&
      payload.productDetails.environmentalIssues.filter((issue) => !issue.id).length
    ) {
      dispatch("base/fetchEnvironmentalIssues", "", { root: true });
    }

    if (payload.productDetails && payload.productDetails.newCultivar && payload.productDetails.newCultivar.name) {
      dispatch("base/fetchCultivars", "", { root: true });
    }

    if (payload.productDetails && payload.productDetails.newProductFlavor && payload.productDetails.newProductFlavor.name) {
      dispatch("base/fetchProductFlavors", "", { root: true });
    }

    if (payload.productDetails && payload.productDetails.newStorageType && payload.productDetails.newStorageType.name) {
      dispatch("base/fetchStorageTypes", "", { root: true });
    }

    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "There was an error" }, { root: true });
    return error;
  }
};

export const updateProduct = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/inventory/product/update/${payload.product.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceItem", response.data.data);
    }
    if (payload.productDetails && payload.productDetails.newContainerType && payload.productDetails.newContainerType.name) {
      dispatch("base/fetchContainerTypes", "", { root: true });
    }

    if (payload.productDetails && payload.productDetails.newFloweringPeriod && payload.productDetails.newFloweringPeriod.name) {
      dispatch("base/fetchFloweringPeriods", "", { root: true });
    }
    if (
      payload.productDetails &&
      payload.productDetails.environmentalIssues &&
      payload.productDetails.environmentalIssues.length &&
      payload.productDetails.environmentalIssues.filter((issue) => !issue.id).length
    ) {
      dispatch("base/fetchEnvironmentalIssues", "", { root: true });
    }

    if (payload.productDetails && payload.productDetails.newCultivar && payload.productDetails.newCultivar.name) {
      dispatch("base/fetchCultivars", "", { root: true });
    }

    if (payload.productDetails && payload.productDetails.newProductFlavor && payload.productDetails.newProductFlavor.name) {
      dispatch("base/fetchProductFlavors", "", { root: true });
    }

    if (payload.productDetails && payload.productDetails.newStorageType && payload.productDetails.newStorageType.name) {
      dispatch("base/fetchStorageTypes", "", { root: true });
    }
    commit("ui/setSnackbar", { variant: "success", message: "Product Saved" }, { root: true });
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "There was an error updating the product" }, { root: true });
    return error;
  }
};

// note this doesn't handle the relationships, its used for line item stuff at batch level
export const updateInventoryItemBatchListing = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/batches/single/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceItem", response.data.data);
      const message = response.data.message || "Batch Updated Successfully";
      commit("ui/setSnackbar", { variant: "success", message }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Batch" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Batch" }, { root: true });
    return error;
  }
};

export const fullUpdateExistingBatch = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/batches/single/full/${payload.batch.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceItem", response.data.data);
      const message = response.data.message || "Batch Updated Successfully";
      commit("ui/setSnackbar", { variant: "success", message }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Batch" }, { root: true });
    return error;
  }
};

export const setQuickOpenBatch = ({ commit }, payload) => {
  commit("setQuickOpenBatch", payload);
};

export const addNewBatchForExistingProduct = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/batches/add", payload);
    if (response.data.code == 200) {
      commit("replaceItem", response.data.data);
      commit("ui/setSnackbar", { variant: "success", message: "Batch Added" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Adding Batch" }, { root: true });
    return error;
  }
};

export const deleteProductItem = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/inventory/product/delete/${payload.id}`);
    if (response.data.code == 200) {
      commit("itemDeleted", response.data.deletedProduct);
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Product" }, { root: true });
    return error;
  }
};

export const archiveProductItemToggle = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/inventory/product/archive/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("toggleArchivedProduct", response.data.data);
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Product Archive Toggle Error" }, { root: true });
    return error;
  }
};

export const safeUpdateBatchQuantity = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/batches/safe-update-quantity/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("replaceItem", response.data.data);
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Quantity" }, { root: true });
    return error;
  }
};

export const clearInventory = async ({ commit, dispatch }) => {
  try {
    commit("clear");
  } catch (error) {
    //
  }
};

export const resetInventory = async ({ commit, dispatch }) => {
  try {
    commit("reset");
  } catch (error) {
    //
  }
};

export const processAllProductData = async ({ commit, dispatch }, payload) => {
  try {
    commit("base/initProductData", payload.productFieldData, { root: true });
    commit("initProducts", payload.products);
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Processing Product and Fields Data",
      },
      { root: true },
    );
  }
};

export const injectItemIntoProduct = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/inventory/product/inject", payload);
    if (response.data && response && response.data.code == 200) {
      if (response.data.data.newProduct) {
        commit("productAdded", response.data.data.newProduct);
      }
      if (response.data.data.updatedProduct) {
        commit("replaceItem", response.data.data.updatedProduct);
      }
      commit("receivingOrders/updateInjectedOrderItem", response.data.data, { root: true });
      commit("ui/setSnackbar", { variant: "success", message: "Successfully Added to Inventory" }, { root: true });
      return response.data.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Adding Product" }, { root: true });

    return [];
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Adding Product" }, { root: true });
    return error;
  }
};
