import { arrayToObject } from "@/services/helpers";

export const setLicenseTypes = (state, payload) => {
  state.licenseTypes = payload;
};

export const setBuyerLeadsExistForState = (state, payload) => {
  state.buyerLeadsExistForState = payload;
};

export const setBuyerTableQuery = (state, payload) => {
  state.buyerTableQuery = payload;
};

export const setShippingOrdersTableQuery = (state, payload) => {
  state.shippingOrdersTableQuery = payload;
};

export const setReceivingOrdersTableQuery = (state, payload) => {
  state.receivingOrdersTableQuery = payload;
};

export const setInventoryTableQuery = (state, payload) => {
  state.inventoryTableQuery = payload;
};

export const reset = (state, payload) => {
  // state.licenseTypes = []; no need to reset
  state.states = [];
  state.countries = [];
  state.buyerLeadsExistForState = false;
  // state.certificationTypes = []; no need to reset
  // state.operationTypes = []; no need to reset
  // state.categories = {};  no need to reset
  state.types = {};
  state.productTypeGroups = [];
  state.prerollTypes = {};
  state.extractTypes = {};
  state.crudeExtractTypes = {};
  state.cartrideTypes = {};
  state.cultivars = {};
  state.growEnvironments = {};
  state.growMediums = {};
  state.stateOfMaterials = {};
  state.trimmingMethods = {};
  state.dryingMethods = {};
  state.storageTypes = {};
  state.containerTypes = {};
  state.unitsOfMeasurement = {};
  state.batchTerpenes = {};
  state.additionalCannabinoids = {};
  state.harvestCycles = {};
  state.infusionMethods = {};
  state.extractionMethods = {};
  state.packagedUnitSizes = {};
  state.governmentAgencies = {};
  state.feminizedTypes = {};
  state.floweringPeriods = {};
  state.environmentalIssues = {};
  state.distillateExtractTypes = {};
  state.productFlavors = {};
  state.productAdditives = {};
  state.testingDocumentTypes = {};
  state.companyServiceTypes = {};
  state.companyServiceSubTypes = {};
  state.cultivarTypes = {};
  state.buyerTableQuery = {};
  state.inventoryTableQuery = {};
  state.shippingOrdersTableQuery = {};
  state.receivingOrdersTableQuery = {};
};

export const setOperationTypes = (state, payload) => {
  state.operationTypes = payload;
};

export const setStates = (state, payload) => {
  state.states = payload;
};

export const setProductTypeGroups = (state, payload) => {
  state.productTypeGroups = payload;
};

export const setCountries = (state, payload) => {
  state.countries = payload;
};

export const setCertificationTypes = (state, payload) => {
  state.certificationTypes = payload;
};

export const initCultivars = (state, payload) => {
  state.cultivars = payload;
};

export const initStorageTypes = (state, payload) => {
  state.storageTypes = payload;
};

export const initTypeGroups = (state, payload) => {
  state.typeGroups = payload;
};

export const initContainerTypes = (state, payload) => {
  state.containerTypes = payload;
};

export const initEnvironmentalIssues = (state, payload) => {
  state.environmentalIssues = payload;
};

export const initProductFlavors = (state, payload) => {
  state.productFlavors = payload;
};

export const initFloweringPeriods = (state, payload) => {
  state.floweringPeriods = payload;
};

export const initProductData = (state, payload) => {
  const catList = [];
  payload.categories.forEach((item) => {
    const itemVar = { ...item };
    catList.push(itemVar);
  });
  state.categories = arrayToObject(catList, "id");

  const cycleList = [];
  payload.harvestCycles.forEach((item) => {
    const itemVar = { ...item };
    cycleList.push(itemVar);
  });
  state.harvestCycles = arrayToObject(cycleList, "id");

  const typeList = [];
  payload.types.forEach((item) => {
    const itemVar = { ...item };
    typeList.push(itemVar);
  });
  state.types = arrayToObject(typeList, "id");

  // const typeGroupList = [];
  // payload.productTypeGroups.forEach((item) => {
  //   const itemVar = { ...item };
  //   typeGroupList.push(itemVar);
  // });
  // state.productTypeGroups = arrayToObject(typeGroupList, 'id');
  state.productTypeGroups = payload.productTypeGroups;

  const crudeExtractTypesList = [];
  payload.crudeExtractTypes.forEach((item) => {
    const itemVar = { ...item };
    crudeExtractTypesList.push(itemVar);
  });
  state.crudeExtractTypes = arrayToObject(crudeExtractTypesList, "id");

  const cultivarsList = [];
  payload.cultivars.forEach((item) => {
    const itemVar = { ...item };
    cultivarsList.push(itemVar);
  });
  state.cultivars = arrayToObject(cultivarsList, "id");

  const growEnvironmentsList = [];
  payload.growEnvironments.forEach((item) => {
    const itemVar = { ...item };
    growEnvironmentsList.push(itemVar);
  });
  state.growEnvironments = arrayToObject(growEnvironmentsList, "id");

  const growMediumsList = [];
  payload.growMediums.forEach((item) => {
    const itemVar = { ...item };
    growMediumsList.push(itemVar);
  });
  state.growMediums = arrayToObject(growMediumsList, "id");

  const stateOfMaterialsList = [];
  payload.stateOfMaterials.forEach((item) => {
    const itemVar = { ...item };
    stateOfMaterialsList.push(itemVar);
  });
  state.stateOfMaterials = arrayToObject(stateOfMaterialsList, "id");

  const trimmingMethodsList = [];
  payload.trimmingMethods.forEach((item) => {
    const itemVar = { ...item };
    trimmingMethodsList.push(itemVar);
  });
  state.trimmingMethods = arrayToObject(trimmingMethodsList, "id");

  const dryingMethodsList = [];
  payload.dryingMethods.forEach((item) => {
    const itemVar = { ...item };
    dryingMethodsList.push(itemVar);
  });
  state.dryingMethods = arrayToObject(dryingMethodsList, "id");

  const storageTypesList = [];
  payload.storageTypes.forEach((item) => {
    const itemVar = { ...item };
    storageTypesList.push(itemVar);
  });
  state.storageTypes = arrayToObject(storageTypesList, "id");

  const containerTypesList = [];
  payload.containerTypes.forEach((item) => {
    const itemVar = { ...item };
    containerTypesList.push(itemVar);
  });
  state.containerTypes = arrayToObject(containerTypesList, "id");

  const unitsOfMeasurementList = [];
  payload.unitsOfMeasurement.forEach((item) => {
    const itemVar = { ...item };
    unitsOfMeasurementList.push(itemVar);
  });
  state.unitsOfMeasurement = arrayToObject(unitsOfMeasurementList, "id");

  const batchTerpenesList = [];
  payload.batchTerpenes.forEach((item) => {
    const itemVar = { ...item };
    batchTerpenesList.push(itemVar);
  });
  state.batchTerpenes = arrayToObject(batchTerpenesList, "id");

  const additionalCannabinoidsList = [];
  payload.additionalCannabinoids.forEach((item) => {
    const itemVar = { ...item };
    additionalCannabinoidsList.push(itemVar);
  });
  state.additionalCannabinoids = arrayToObject(additionalCannabinoidsList, "id");

  const infusionMethodsList = [];
  payload.infusionMethods.forEach((item) => {
    const itemVar = { ...item };
    infusionMethodsList.push(itemVar);
  });
  state.infusionMethods = arrayToObject(infusionMethodsList, "id");

  const extractionMethodsList = [];
  payload.extractionMethods.forEach((item) => {
    const itemVar = { ...item };
    extractionMethodsList.push(itemVar);
  });
  state.extractionMethods = arrayToObject(extractionMethodsList, "id");

  const packagedUnitSizesList = [];
  payload.packagedUnitSizes.forEach((item) => {
    const itemVar = { ...item };
    packagedUnitSizesList.push(itemVar);
  });
  state.packagedUnitSizes = arrayToObject(packagedUnitSizesList, "id");

  const governmentAgenciesList = [];
  payload.governmentAgencies.forEach((item) => {
    const itemVar = { ...item };
    governmentAgenciesList.push(itemVar);
  });
  state.governmentAgencies = arrayToObject(governmentAgenciesList, "id");

  const feminizedTypesList = [];
  payload.feminizedTypes.forEach((item) => {
    const itemVar = { ...item };
    feminizedTypesList.push(itemVar);
  });
  state.feminizedTypes = arrayToObject(feminizedTypesList, "id");

  const floweringPeriodsList = [];
  payload.floweringPeriods.forEach((item) => {
    const itemVar = { ...item };
    floweringPeriodsList.push(itemVar);
  });
  state.floweringPeriods = arrayToObject(floweringPeriodsList, "id");

  const environmentalIssuesList = [];
  payload.environmentalIssues.forEach((item) => {
    const itemVar = { ...item };
    environmentalIssuesList.push(itemVar);
  });
  state.environmentalIssues = arrayToObject(environmentalIssuesList, "id");

  const distillateExtractTypesList = [];
  payload.distillateExtractTypes.forEach((item) => {
    const itemVar = { ...item };
    distillateExtractTypesList.push(itemVar);
  });
  state.distillateExtractTypes = arrayToObject(distillateExtractTypesList, "id");

  const productFlavorsList = [];
  payload.productFlavors.forEach((item) => {
    const itemVar = { ...item };
    productFlavorsList.push(itemVar);
  });
  state.productFlavors = arrayToObject(productFlavorsList, "id");

  const productAdditivesList = [];
  payload.productAdditives.forEach((item) => {
    const itemVar = { ...item };
    productAdditivesList.push(itemVar);
  });
  state.productAdditives = arrayToObject(productAdditivesList, "id");

  const testingDocumentTypesList = [];
  payload.testingDocumentTypes.forEach((item) => {
    const itemVar = { ...item };
    testingDocumentTypesList.push(itemVar);
  });
  state.testingDocumentTypes = arrayToObject(testingDocumentTypesList, "id");

  const cultivarTypesList = [];
  payload.cultivarTypes.forEach((item) => {
    const itemVar = { ...item };
    cultivarTypesList.push(itemVar);
  });
  state.cultivarTypes = arrayToObject(cultivarTypesList, "id");
};

export const initCompanyServiceData = (state, payload) => {
  const companyServiceTypesList = [];
  payload.companyServiceTypes.forEach((item) => {
    const itemVar = { ...item };
    companyServiceTypesList.push(itemVar);
  });
  state.companyServiceTypes = arrayToObject(companyServiceTypesList, "id");
  const companyServiceSubTypesList = [];
  payload.companyServiceSubTypes.forEach((item) => {
    const itemVar1 = { ...item };
    companyServiceSubTypesList.push(itemVar1);
  });
  state.companyServiceSubTypes = arrayToObject(companyServiceSubTypesList, "id");
};
