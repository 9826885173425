export default {
  customInventoryReportslist: {},
  defaultInventoryReport: null,
  customAllProductSalesReportslist: {},
  defaultAllProductSalesReport: null,
  customSellerOrderReportslist: {},
  defaultSellerOrderReport: null,
  customBuyerOrderReportslist: {},
  defaultBuyerOrderReport: null,
  customTeamSalesReportslist: {},
  defaultTeamSalesReport: null,
};
