export const inCustomInventoryReportArray = (state) => Object.values(state.customInventoryReportslist);
export const byCustomInventoryReportId = (state) => (id) => state.customInventoryReportslist[id];
export const defaultInventoryReportId = (state) => state.defaultInventoryReport;

export const inCustomAllProductSalesReportArray = (state) => Object.values(state.customAllProductSalesReportslist);
export const byCustomAllProductSalesReportId = (state) => (id) => state.customAllProductSalesReportslist[id];
export const defaultAllProductSalesReportId = (state) => state.defaultAllProductSalesReport;

export const inCustomSellerOrderReportArray = (state) => Object.values(state.customSellerOrderReportslist);
export const byCustomSellerOrderReportId = (state) => (id) => state.customSellerOrderReportslist[id];
export const defaultSellerOrderReportId = (state) => state.defaultSellerOrderReport;

export const inCustomBuyerOrderReportArray = (state) => Object.values(state.customBuyerOrderReportslist);
export const byCustomBuyerOrderReportId = (state) => (id) => state.customBuyerOrderReportslist[id];
export const defaultBuyerOrderReportId = (state) => state.defaultBuyerOrderReport;

export const inCustomTeamSalesReportArray = (state) => Object.values(state.customTeamSalesReportslist);
export const byCustomTeamSalesReportId = (state) => (id) => state.customTeamSalesReportslist[id];
export const defaultTeamSalesReportId = (state) => state.defaultTeamSalesReport;
