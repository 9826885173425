export const inCatArray = (state) => Object.values(state.categories).sort((a, b) => a.position - b.position);
export const inTypesArray = (state) => Object.values(state.types);
export const inTypeGroups = (state) => Object.values(state.productTypeGroups);
export const inCrudeExtractTypesArray = (state) => Object.values(state.crudeExtractTypes);
export const inCultivarsArray = (state) => Object.values(state.cultivars);
export const inCultivarTypesArray = (state) => Object.values(state.cultivarTypes);
export const inGrowEnvironmentsArray = (state) => Object.values(state.growEnvironments);
export const inGrowMediumsArray = (state) => Object.values(state.growMediums);
export const inStateOfMaterialsArray = (state) => Object.values(state.stateOfMaterials);
export const inTrimmingMethodsArray = (state) => Object.values(state.trimmingMethods);
export const inDryingMethodsArray = (state) => Object.values(state.dryingMethods);
export const inStorageTypesArray = (state) => Object.values(state.storageTypes);
export const inContainerTypesArray = (state) => Object.values(state.containerTypes);
export const inUnitsOfMeasurementArray = (state) => Object.values(state.unitsOfMeasurement);
export const inBatchTerpenesArray = (state) => Object.values(state.batchTerpenes);
export const inBatchAdditionalCannabinoidsArray = (state) => Object.values(state.additionalCannabinoids);
export const harvestCyclesArray = (state) => Object.values(state.harvestCycles);
export const infusionMethodsArray = (state) => Object.values(state.infusionMethods);
export const extractionMethodsArray = (state) => Object.values(state.extractionMethods);
export const inPackagedUnitSizesArray = (state) => Object.values(state.packagedUnitSizes);
export const inGovernmentAgenciesArray = (state) => Object.values(state.governmentAgencies);
export const inFeminizedTypesArray = (state) => Object.values(state.feminizedTypes);
export const inFloweringPeriodsArray = (state) => Object.values(state.floweringPeriods);
export const inEnvironmentalIssuesArray = (state) => Object.values(state.environmentalIssues);
export const inDistillateExtractTypesArray = (state) => Object.values(state.distillateExtractTypes);
export const inProductFlavorsArray = (state) => Object.values(state.productFlavors);
export const inProductAdditivesArray = (state) => Object.values(state.productAdditives);
export const inTestingDocumentTypesArray = (state) => Object.values(state.testingDocumentTypes);
export const inCompanyServiceTypesArray = (state) => Object.values(state.companyServiceTypes);
export const inCompanyServiceSubTypesArray = (state) => Object.values(state.companyServiceSubTypes);
export const inCountriesArray = (state) => Object.values(state.countries);
export const inStatesArray = (state) => Object.values(state.states);
