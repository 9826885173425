export const eventAdded = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

// export const eventItemAdded = (state, payload) => {
//   state.list = { ...state.list[payload.event_id].items, [payload]: payload };
// };

export const eventItemAdded = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.event_id].event_items.push(payload) };
};

export const reset = (state, payload) => {
  state.list = {};
  state.loading = false;
  state.loaded = false;
};

export const setLoading = (state, payload) => {
  state.loading = payload;
};

export const replaceEvent = (state, payload) => {
  state.list[payload.id] = payload;
};

export const eventRemoved = (state, payload) => {
  const updatedEvent = { ...state.list[payload], deleted_at: true };
  state.list = { ...state.list, [payload]: updatedEvent };
};

export const eventItemRemoved = (state, payload) => {
  // state.list = { ...state.list, [payload.id]: payload };
  state.list[payload.id] = payload;
};

// export const eventItemRemoved = (state, payload) => {
//   const updatedEvent = { ...state.list[payload], deleted_at: true };
//   state.list = { ...state.list, [payload]: updatedEvent };
// };

export const eventRestored = (state, payload) => {
  const updatedEvent = { ...state.list[payload], deleted_at: false };
  state.list = { ...state.list, [payload]: updatedEvent };
};

export const initEvents = (state, payload) => {
  state.list = payload;
  state.loaded = true;
};
