<template>
  <div class="d-inline">
    <i :id="id" class="fas text-info" :class="iconClass" :style="tooltipStyle" />
    <BTooltip :target="id" :placement="placement" triggers="hover" :delay="delay">
      <span v-if="text">{{ text }}</span>
      <slot />
    </BTooltip>
  </div>
</template>
<script>
export default {
  name: "InfoTooltipBase",
  components: {},
  props: {
    id: { type: String, required: true },
    text: { type: String, required: false },
    placement: { type: String, default: "bottom" },
    iconClass: { type: String, default: "fa-question" },
    tooltipStyle: { type: Object },
    delay: { type: Object, default: () => ({ show: 0, hide: 0 }) },
  },

  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
