import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const createBulkDiscount = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/bulk-discounts", payload);
    if (response.data.id) {
      commit("bulkDiscountAdded", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Bulk Discount" }, { root: true });
    return "success";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Bulk Discount" }, { root: true });
    return error;
  }
};

export const fetchBulkDiscounts = async ({ commit }) => {
  try {
    const response = await api.get("/bulk-discounts");
    const bulkDiscounts = arrayToObject(response.data, "id");
    commit("initBulkDiscounts", bulkDiscounts);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Bulk Discount List" }, { root: true });
  }
};

export const destroyBulkDiscount = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/bulk-discounts/${payload}`);
    if (response.data.message == "success") {
      commit("bulkDiscountRemoved", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Bulk Discount" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Bulk Discount" }, { root: true });
  }
};
