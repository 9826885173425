export default {
  list: {},
  profileMessagesCount: 0,
  unreadCount: 0,
  newLeadsCount: 0,
  taskCounts: {
    total: null,
    due: null,
  },
  unreadChatsCount: 0,
};
