<template>
  <span :class="dropdownItemClasses" :role="role" :aria-disabled="disabled ? 'true' : 'false'" :aria-haspopup="hasSubmenu" @click="handleClick">
    <slot />
  </span>
</template>
<script>
export default {
  name: "BDropdownItem",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: "menuitem",
    },
    active: {
      type: Boolean,
      default: false,
    },
    activeClass: {
      type: String,
      default: "active",
    },
    variant: {
      type: String,
      default: null,
    },
    hasSubmenu: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  computed: {
    dropdownItemClasses() {
      return {
        "dropdown-item": true,
        [this.activeClass]: this.active,
        [`text-${this.variant}`]: this.variant,
      };
    },
  },
  methods: {
    handleClick(e) {
      if (this.disabled) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      this.$emit("click", e);
    },
  },
};
</script>
