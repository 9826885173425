<template>
  <BModal
    :id="id"
    :visible="show"
    :max-width="maxWidth"
    :scrollable="scrollable"
    :no-close-on-backdrop="preventCloseOnBackdrop"
    :hide-footer="hideFooter"
    :header-class="headerClass"
    :body-class="bodyClass"
    :footer-class="footerClass"
    :size="size"
    @hide="$emit('hide')"
  >
    <template #modal-header>
      <div class="d-flex w-100">
        <h6 class="modal-title" :class="titleClass">
          {{ title || "Last Chance" }}
        </h6>
        <button type="button" class="modal-close ml-auto" aria-label="Close" @click="$emit('hide')">
          <i class="fa fa-times-thin" style="font-size: 1.5em" />
        </button>
      </div>
    </template>

    <template #default>
      <slot>
        <div class="mx-3">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <!-- eslint-disable vue/no-v-html  -->
          <p v-if="message">
            {{ message }}
          </p>
          <p v-else>Are you completely sure you want to do this?</p>
        </div>
      </slot>
    </template>
    <template #modal-footer>
      <BushelBtn
        v-if="!hideCancelBtn"
        :variant="cancelBtnVariant"
        :ripple-color="cancelBtnRippleVariant"
        :disabled="disableCancelBtn"
        flat
        @click="$emit('hide')"
      >
        {{ cancelText || "Cancel" }}
      </BushelBtn>
      <BushelBtn v-if="alternativeActionBtnText" :variant="alternativeActionButtonVariant" flat @click="$emit('alternativeActionBtnClicked')">
        {{ alternativeActionBtnText }}
      </BushelBtn>
      <BushelBtn v-if="!hideConfirmBtn" :variant="confirmButtonVariant" :disabled="disableConfirmButton" flat @click="$emit('confirm')">
        {{ confirmText || "I'm Sure" }}
      </BushelBtn>
    </template>
  </BModal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validateEmail } from "@/services/helpers";
import BModal from "@/components/base/BModal.vue";

export default {
  name: "DeleteConfirmationModal",
  components: { BModal },
  props: {
    scrollable: { type: Boolean, default: true },
    title: { type: String },
    headerClass: { type: String },
    bodyClass: { type: String },
    footerClass: { type: String },
    titleClass: { type: String },
    message: { type: String },
    preventCloseOnBackdrop: { type: Boolean, default: true },
    show: { type: Boolean, required: true },
    id: { type: String, default: "deleteConfirmationModal" },
    cancelText: { type: String },
    hideCancelBtn: { type: Boolean, default: false },
    hideConfirmBtn: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    disableConfirmButton: { type: Boolean, default: false },
    disableCancelBtn: { type: Boolean, default: false },
    confirmText: { type: String },
    maxWidth: { type: String, default: "500" },
    alternativeActionBtnText: { type: String },
    alternativeActionButtonVariant: { type: String, default: "success" },
    confirmButtonVariant: { type: String, default: "danger" },
    cancelBtnVariant: { type: String, default: "secondary" },
    cancelBtnRippleVariant: { type: String, default: "secondary" },
    size: { type: String, default: "md" },
  },
  emits: ["hide", "confirm", "alternativeActionBtnClicked"],
  data() {
    return {};
  },
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    hasDefaultSlot() {
      return this.$slots.default;
    },
  },
  mounted() {},
  methods: {
    ...mapActions({ setSnackbar: "ui/setSnackbar" }),
    validateEmail,
  },
};
</script>
