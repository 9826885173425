import * as api from "@/services/api";

export const fetchPermissions = async ({ commit }, payload) => {
  try {
    const response = await api.get(`/roles/permissions/${payload}`);
    commit("attachPermissions", { id: payload, permissions: response.data });
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Role List" }, { root: true });
  }
};

export const togglePermission = async ({ commit }, payload) => {
  try {
    const response = await api.patch(`/roles/permissions/toggle-permission/${payload.id}`, { type: payload.type });
    if (response.data && response.data.attached[0]) {
      commit("addPermission", payload);
    } else if (response.data && response.data.detached[0]) {
      commit("removePermission", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Toggling Permission" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Toggling Permission" }, { root: true });
  }
};

export const toggleAllPermissions = async ({ commit }, payload) => {
  try {
    const response = await api.patch(`/roles/permissions/toggle-all-permissions/${payload.id}`, { type: payload.type });
    const data = {
      id: payload.id,
      permissions: response.data,
    };
    commit("toggleAllPermissions", data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Toggling All Permissions" }, { root: true });
  }
};

export const create = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/roles", payload);
    if (response.data.id) {
      commit("created", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Role" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Role" }, { root: true });
  }
};

export const destroy = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/roles/${payload}`);
    if (response.data == 1) {
      commit("destroyed", payload);
      dispatch("users/fetchUsers", "", { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Role" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Role" }, { root: true });
  }
};

export const fetch = async ({ commit }) => {
  try {
    const response = await api.get("/roles");
    commit("init", response.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Role's Permissions" }, { root: true });
  }
};
