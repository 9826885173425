import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const fetchNetTerms = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/netTerms/byCompany", payload);
    if (response.data.code == 200) {
      const data = arrayToObject(response.data.data.netTerms, "id");
      commit("init", data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Net Term List" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Net Term List" }, { root: true });
    return error;
  }
};

export const create = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/netTerms", payload);
    if (response.data.code == 201) {
      commit("added", response.data.data.netTerm);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Net Term" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Net Term" }, { root: true });
    return error;
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/netTerms/${payload.id}`, payload.netTerm);
    if (response.data.code == 200) {
      commit("replace", response.data.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Net Term" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Net Term" }, { root: true });
    return error;
  }
};

export const destroy = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/netTerms/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("removed", response.data.data.netTerm);
      dispatch("buyers/nullifyBuyerTerms", response.data.data.buyersNetTermsRemoved, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Net Term" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Net Term" }, { root: true });
  }
};
