import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const clearRejectionQueue = async ({ commit, dispatch }) => {
  commit("clearRejections");
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/rejects/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceReject", response.data);
      dispatch("getCounts");
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem updating reject" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem updating reject" }, { root: true });
    return error;
  }
};

export const fetchRejects = async ({ commit, dispatch }, payload) => {
  commit("setLoaded", false);
  try {
    const response = await api.post("/rejects/list", payload);
    const rejects = arrayToObject(response.data, "id");
    commit("initRejects", rejects);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error fetching rejects" }, { root: true });
  }
};

export const getCounts = async ({ commit }) => {
  try {
    const response = await api.get("/rejects/counts/all");
    commit("initCounts", response.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Unable to get rejection counts!" }, { root: true });
  }
};

export const fetchReject = async ({ commit, dispatch }, id) => {
  try {
    commit("setLoading", true);
    const response = await api.get(`/rejects/${id}`);
    if (response.data.id) {
      commit("replaceReject", response.data);
      commit("setLoading", false);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error fetching reject" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error fetching reject" }, { root: true });
  }
};

export const toggleArchived = async ({ commit, dispatch }, rejectId) => {
  try {
    const response = await api.post(`/rejects/toggle-archive/${rejectId}`);
    if (response.data.id) {
      commit("replaceReject", response.data);
      dispatch("getCounts");
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error removing reject" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error removing reject" }, { root: true });
  }
};

export const restoreReject = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/rejects/restore/${payload}`);
    if (response.data.message == "success") {
      commit("rejectRestored", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error trying to restore reject" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error trying to restore reject" }, { root: true });
  }
};
