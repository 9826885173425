import * as api from "@/services/api";

export const fetchUsers = async ({ commit }) => {
  try {
    const response = await api.get("/company-users-secure");
    commit("init", response.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const updateUser = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/company-users-secure/${payload.id}`, payload);
    commit("updateUser", response.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const updateUserSettings = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/company-users-secure/${payload.id}/settings`, payload);
    commit("updateUser", response.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const destroyUser = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/company-users-secure/${payload.id}`);
    if (response.data == 1) {
      commit("userRemoved", payload.id);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const fetchOperations = async ({ commit }, payload) => {
  try {
    const response = await api.get(`/company-users-secure/operations/${payload}`);
    commit("attachOperations", { id: payload, operations: response.data });
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const toggleOperation = async ({ commit }, payload) => {
  try {
    const response = await api.patch(`/company-users-secure/operations/toggle-operation/${payload.companyUserId}`, {
      operationId: payload.operationId,
    });
    if (response.data && response.data.attached[0]) {
      commit("addOperation", payload);
    } else if (response.data && response.data.detached[0]) {
      commit("removeOperation", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const toggleAllOperations = async ({ commit }, payload) => {
  try {
    const response = await api.patch(`/company-users-secure/operations/toggle-all-operations/${payload.companyUserId}`, { type: payload.type });
    const data = {
      companyUserId: payload.companyUserId,
      operationsArray: response.data,
    };
    commit("toggleAllOperations", data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const fetchPermissions = async ({ commit }, payload) => {
  try {
    const response = await api.get(`/company-users-secure/permissions/${payload}`);
    commit("attachPermissions", { id: payload, permissions: response.data });
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const togglePermission = async ({ commit }, payload) => {
  try {
    const response = await api.patch(`/company-users-secure/permissions/toggle-permission/${payload.id}`, { type: payload.type });
    if (response.data && response.data.attached[0]) {
      commit("addPermission", payload);
    } else if (response.data && response.data.detached[0]) {
      commit("removePermission", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};

export const toggleAllPermissions = async ({ commit }, payload) => {
  try {
    const response = await api.patch(`/company-users-secure/permissions/toggle-all-permissions/${payload.id}`, { type: payload.type });
    const data = {
      id: payload.id,
      permissions: response.data,
    };
    commit("toggleAllPermissions", data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error" }, { root: true });
  }
};
