export const rejectAdded = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const rejectItemAdded = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.reject_id].reject_items.push(payload) };
};

export const reset = (state, payload) => {
  state.list = {};
  state.loading = false;
  state.loaded = false;
};

export const clearRejections = (state) => {
  state.list = {};
};

export const setLoading = (state, payload) => {
  state.loading = payload;
};

export const setLoaded = (state, payload) => {
  state.loaded = false;
};

export const replaceReject = (state, payload) => {
  state.list[payload.id] = payload;
};

export const rejectRemoved = (state, payload) => {
  const updatedReject = { ...state.list[payload], deleted_at: true };
  state.list = { ...state.list, [payload]: updatedReject };
};

export const rejectRestored = (state, payload) => {
  const updatedReject = { ...state.list[payload], deleted_at: false };
  state.list = { ...state.list, [payload]: updatedReject };
};

export const initRejects = (state, payload) => {
  state.list = payload;
  state.loaded = true;
};

export const initCounts = (state, payload) => {
  state.counts = payload;
};
