import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const createVendor = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/vendors", payload);
    if (response.data.id) {
      commit("vendorAdded", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error creating vendor" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error creating vendor" }, { root: true });
    return error;
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/vendors/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceVendor", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Vendor" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Vendor" }, { root: true });
    return error;
  }
};

export const reset = async ({ commit, dispatch }) => {
  try {
    commit("reset");
  } catch (error) {
    //
  }
};

export const fetchVendors = async ({ commit }) => {
  try {
    commit("loadingVendors");
    const response = await api.get("/vendors");
    const vendors = arrayToObject(response.data, "id");
    commit("initVendors", { vendors, requestMet: true });
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Vendor List" }, { root: true });
  }
};

export const fetchVendorsByQuery = async ({ commit }, payload) => {
  try {
    commit("reset");
    const response = await api.get(`/vendors/search?${payload}`);
    const vendors = arrayToObject(response.data.vendors, "id");
    commit("initVendors", { vendors, requestMet: response.data.requestMet });
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Vendor List" }, { root: true });
  }
};

export const fetchVendor = async ({ commit, dispatch }, uuid) => {
  try {
    const response = await api.get(`/vendors/${uuid}`);
    if (response.data.id) {
      commit("replaceVendor", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Vendor Data" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Vendor Data" }, { root: true });
  }
};

/** ***********************************
 ** Vendor Notes Actions
 ************************************ */
export const createVendorNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/vendors/notes/create", payload);
    if (response.data.code == 201) {
      commit("vendors/replaceVendor", response.data.data.vendor, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Vendor Note" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Vendor Note" }, { root: true });
    return error;
  }
};

export const updateVendorNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/vendors/notes/update/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("vendors/replaceVendor", response.data.data.vendor, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Vendor Note" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Vendor Note" }, { root: true });
    return error;
  }
};

export const deleteVendorNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/vendors/notes/delete/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("vendors/replaceVendor", response.data.data.vendor, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Vendor Note" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Vendor Note" }, { root: true });
    return error;
  }
};
