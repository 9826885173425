<template>
  <div :id="cardId" class="card bushel-card rounded" :class="showingFullScreen ? fullScreenCardClass : ''">
    <div v-if="hasReplaceHeaderSlot">
      <slot name="replaceHeader" />
    </div>
    <div v-else-if="hasHeaderSlot || allowFullscreen" class="bushel-card-header pr-2 py-2" :class="headerClass" :style="headerStyle">
      <div style="display: flex" class="card-title-container">
        <span v-if="title" class="bushel-card-title" :class="titleClass" :style="titleStyle">
          {{ title }}
        </span>

        <slot name="header" />

        <div class="ml-auto my-auto nowrap">
          <div v-if="allowHeaderToolTip" class="mr-3 d-inline-block">
            <InfoTooltipBase
              v-if="tooltipText"
              :id="tooltipId"
              class="mr-2"
              icon-class="fas fa-question text-info"
              :tooltip-style="{ position: 'relative', top: '3px', 'font-size': '21px' }"
              :placement="tooltipPlacement"
              :text="tooltipText"
            />
          </div>

          <slot name="headerButtons" />
          <BushelBtn v-if="selectedArticle" variant="primary" flat class="bushel-card-button" @click="showArticle">
            <ImgIcon icon="pop-icon" />
          </BushelBtn>

          <BushelBtn
            v-if="allowFullscreen && !showingTrueFullScreen"
            :variant="allowFullscreenVariant"
            :flat="allowFullscreenFlat"
            :outline="allowFullscreenOutline"
            class="bushel-card-button"
            :class="allowFullscreenClass"
            :style="allowFullscreenStyle"
            :disabled="disabled"
            @click="showingFullScreen = !showingFullScreen"
          >
            <i class="fa-fw" :class="showingFullScreen ? 'fas fa-compress' : 'fas fa-expand'" />
          </BushelBtn>
          <BushelBtn
            v-if="archivedOptionId"
            :id="archivedOptionId"
            :variant="showingArchivedOptions ? 'secondary' : 'dark'"
            :flat="showingArchivedOptions ? false : true"
            class="bushel-card-button"
            :disabled="archivedDisabled"
            :tooltip-message="showingArchivedOptions ? archivedOptionTooltipArchived : archivedOptionTooltipUnArchived"
            @click="() => $emit('archiveToggled')"
          >
            <i v-if="showingArchivedOptions" :class="archivedOptionIconArchivedClass" :style="archivedOptionIconArchivedStyle" />
            <i v-else :class="archivedOptionIconUnArchivedClass" :style="archivedOptionIconUnArchivedStyle" />
          </BushelBtn>
          <BushelBtn
            v-if="authorized && type != 'blank'"
            :variant="typeVariant"
            :ripple-color="typeRipple"
            :outline="typeOutline"
            :flat="typeFlat"
            class="bushel-card-button ml-2"
            :class="typeClass"
            :style="typeStyle"
            :button-id="typeButtonId"
            :tooltip-message="typeTooltip"
            :disabled="disabled"
            @click="() => $emit('click')"
          >
            <i
              class="fa-fw"
              :class="{
                'fa fa-plus': type === 'add',
                'fa fa-pencil-alt': type === 'edit',
                'fa fa-upload': type === 'upload',
                'fas fa-sync-alt': type === 'refresh',
                'fas fa-paper-plane': type === 'send',
                'fas fa-circle-notch': type === 'loading',
                'fas fa-glasses': type === 'history',
                'fas fa-info': type === 'info',
                'fas fa-eye': type === 'view',
                'fas fa-lock': type === 'lock',
                'fas fa-unlock': type === 'unlock',
                'fa fa-spin': spin,
              }"
            />
          </BushelBtn>

          <BushelBtn
            v-if="allowTrueFullscreen"
            :variant="allowTrueFullscreenVariant"
            :flat="allowTrueFullscreenFlat"
            :outline="allowTrueFullscreenOutline"
            class="bushel-card-button"
            :class="allowTrueFullscreenClass"
            :style="allowTrueFullscreenStyle"
            :disabled="disabled"
            @click="toggleTrueFullScreen"
          >
            <i class="fa-fw" :class="showingTrueFullScreen ? 'fas fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'" />
          </BushelBtn>
        </div>
      </div>
    </div>
    <div class="bushel-card-body" :style="bodyStyle" :class="[bodyClass, { 'rounded-bottom': !hasFooterSlot, 'rounded-top': !hasHeaderSlot }]">
      <slot />
    </div>
    <div v-if="hasFooterSlot" class="card-footer" :class="footerClass">
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import InfoTooltipBase from "@/components/common/InfoTooltipBase.vue";
import ImgIcon from "@/components/common/icons/ImgIcon.vue";

export default {
  components: { InfoTooltipBase, ImgIcon },
  props: {
    cardId: {
      type: String,
      default() {
        return `bushel_card_${Math.floor(Math.random() * 10000 + 1) * Math.floor(Math.random() * 10000 + 1)}`;
      },
    },
    archivedOptionId: { type: String },
    showingArchivedOptions: { type: Boolean },
    archivedOptionTooltipArchived: { type: String, default: "" },
    archivedOptionTooltipUnArchived: { type: String, default: "" },
    archivedOptionIconArchivedClass: { type: String, default: "fas fa-fw fa-archive" },
    archivedOptionIconArchivedStyle: { type: Object },
    archivedOptionIconUnArchivedClass: { type: String, default: "fas fa-fw fa-archive" },
    archivedOptionIconUnArchivedStyle: { type: Object },
    tooltipPlacement: { type: String, default: "left" },
    tooltipId: { type: String, default: "card-tooltip" },
    tooltipText: { type: String },
    typeClass: { type: String },
    typeStyle: { type: String },
    typeButtonId: { type: String },
    typeTooltip: { type: String },
    type: { type: String, default: "blank" },
    title: { type: String, required: false },
    authorized: { type: Boolean, default: false },
    spin: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    archivedDisabled: { type: Boolean, default: false },
    bodyClass: { type: String, required: false },
    bodyStyle: { type: String, default: "" },
    footerClass: { type: String, required: false },
    headerClass: { type: String, required: false },
    headerStyle: { type: String, required: false },
    titleClass: { type: String, required: false },
    titleStyle: { type: String, required: false },
    allowFullscreen: { type: Boolean, default: false },
    startFullscreen: { type: Boolean, default: false },
    allowFullscreenFlat: { type: Boolean, default: false },
    allowFullscreenOutline: { type: Boolean, default: false },
    allowFullscreenFull: { type: Boolean, default: false },
    allowFullscreenVariant: { type: String, default: "light" },
    allowFullscreenClass: { type: String, default: null },
    allowFullscreenStyle: { type: String, default: null },

    allowTrueFullscreen: { type: Boolean, default: false },
    allowTrueFullscreenFlat: { type: Boolean, default: false },
    allowTrueFullscreenOutline: { type: Boolean, default: false },
    allowTrueFullscreenFull: { type: Boolean, default: false },
    allowTrueFullscreenVariant: { type: String, default: "light" },
    allowTrueFullscreenClass: { type: String, default: "text-muted" },
    allowTrueFullscreenStyle: { type: String, default: null },

    typeVariant: { type: String, default: "success" },
    typeRipple: { type: String, default: "success" },
    typeOutline: { type: Boolean, default: false },
    typeFlat: { type: Boolean, default: false },
    apexArticleSlug: { type: String },
    bushelArticleSlug: { type: String },
    allowHeaderToolTip: { type: Boolean, default: true },
  },
  emits: ["click", "archiveToggled"],
  data() {
    return {
      industry: APP_INDUSTRY,
      showingFullScreen: false,
      showingTrueFullScreen: false,
    };
  },
  computed: {
    ...mapState({
      supportArticleDrawerOpen: (state) => state.ui.supportArticleDrawer.open,
      supportArticleDrawerSlug: (state) => state.ui.supportArticleDrawer.articleSlug,
    }),
    selectedArticle() {
      if (this.apexArticleSlug && this.apexArticleSlug.length && this.industry == "cannabis") {
        return this.apexArticleSlug;
      }
      if (this.bushelArticleSlug && this.bushelArticleSlug.length && this.industry == "hemp") {
        return this.bushelArticleSlug;
      }
      return null;
    },
    hasFooterSlot() {
      return this.$slots.footer;
    },
    hasReplaceHeaderSlot() {
      return this.$slots.replaceHeader;
    },
    hasHeaderSlot() {
      return this.$slots.header || this.title;
    },
    fullScreenCardClass() {
      if (this.allowFullscreenFull) {
        return "full-screen-card full-card";
      }
      return "full-screen-card";
    },
  },

  created() {
    if (this.allowFullscreen) {
      document.addEventListener("fullscreenchange", this.exitFullScreenHandler);
      document.addEventListener("webkitfullscreenchange", this.exitFullScreenHandler);
      document.addEventListener("mozfullscreenchange", this.exitFullScreenHandler);
      document.addEventListener("MSFullscreenChange", this.exitFullScreenHandler);
    }
  },
  mounted() {
    if (this.startFullscreen) {
      this.showingFullScreen = true;
    }
  },
  beforeUnmount() {
    if (this.allowFullscreen) {
      document.removeEventListener("fullscreenchange", this.exitFullScreenHandler);
      document.removeEventListener("webkitfullscreenchange", this.exitFullScreenHandler);
      document.removeEventListener("mozfullscreenchange", this.exitFullScreenHandler);
      document.removeEventListener("MSFullscreenChange", this.exitFullScreenHandler);
    }
  },
  methods: {
    ...mapActions("ui", ["handleSupportArticleDrawer"]),
    showArticle() {
      if (!this.supportArticleDrawerOpen || this.supportArticleDrawerSlug != this.selectedArticle) {
        this.handleSupportArticleDrawer(this.selectedArticle);
      } else {
        this.handleSupportArticleDrawer();
      }
    },
    openTrueFullScreen() {
      // var elem = document.documentElement;
      const elem = document.getElementById(this.cardId);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        this.showingTrueFullScreen = true;
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
        this.showingTrueFullScreen = true;
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
        this.showingTrueFullScreen = true;
      }
    },
    closeTrueFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        this.showingTrueFullScreen = false;
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
        this.showingTrueFullScreen = false;
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
        this.showingTrueFullScreen = false;
      }
    },
    toggleTrueFullScreen() {
      if (!this.showingTrueFullScreen) {
        this.openTrueFullScreen();
      } else {
        this.closeTrueFullScreen();
      }
    },
    exitFullScreenHandler() {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        this.showingTrueFullScreen = false;
      }
    },
  },
};
</script>
<style>
.bushel-card-title {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  color: rgba(0, 0, 0, 0.54);
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.5rem;
}
</style>
<style scoped lang="scss">
@import "@sass/_variables.scss";
@import "@sass/_mixins.scss";
.bushel-card {
  border: 0;
  border-radius: 0;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.bushel-card-button {
  margin-left: auto;
  padding: 0.65rem 1.1rem;
  height: 46px;
}
.bushel-card-button:disabled {
  background: linear-gradient(110.6deg, #97be79 100%, #a3c56b 0%, #c5d845 0%);
}
.bushel-card-title {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  color: rgba(0, 0, 0, 0.54);
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.5rem;
}
.full-screen-card {
  position: absolute;
  height: 100%;
  padding: 0px;
  margin: 0px;
  @include border-radius(0px !important);
  width: 100%;
  left: 0;
  top: 0px;
  // overflow: hidden; this doesn't allow scrolling
  // overflow-y: scroll;
  // overflow-x: hidden;
  &.full-card {
    z-index: 100;
    top: -40px;
    height: calc(100% + 40px);
  }
  .bushel-card-header {
    @include border-radius(0px !important);
    &.bushel-card-flat {
      background-color: $white !important;
      border-top: 1px solid $border-color !important;
      border-bottom: 1px solid $border-color !important;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
      z-index: 1;
    }
  }
}
.sidebarSticky .full-screen-card {
  top: 40px;
  // left: -224px !important;
  // width: calc(100% + 224px) !important;
}
@media (min-width: map-get($grid-breakpoints, sm) + 1px) {
  .full-screen-card {
    //
    &.full-card {
      //
    }
  }
}
@media (min-width: map-get($grid-breakpoints, md) + 1px) {
  .full-screen-card {
    // left: -50px;
    // width: calc(100% + 50px);
    &.full-card {
      top: -60px;
      height: calc(100% + 60px);
    }
  }
  .sidebarSticky .full-screen-card {
    top: 0px;
    // left: -224px !important;
    // width: calc(100% + 224px) !important;
  }
}
@media (min-width: map-get($grid-breakpoints, lg) + 1px) {
  .full-screen-card {
    //
    &.full-card {
      //
    }
  }
}
</style>
