<template>
  <div
    style="color: #98c179"
    class="la-ball-beat"
    :class="[size, classes]"
  >
    <img src="img/icons/icon-flower-darker-green.png">
    <img src="img/icons/icon-flower-darker-green.png">
    <img src="img/icons/icon-flower-darker-green.png">
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    size: { type: String, default: 'la-2x' },
    classes: { type: String, default: 'ml-auto mr-auto mt-4 mb-4' },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-beat,
.la-ball-beat > img {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.la-ball-beat {
  display: block;
  font-size: 0;
  color: #fff;
}
.la-ball-beat.la-dark {
  color: #333;
}
.la-ball-beat > img {
  display: inline-block;
  float: none;
  /* background-color: currentColor;
    border: 0 solid currentColor; */
}
.la-ball-beat {
  width: 54px;
  height: 18px;
}
.la-ball-beat > img {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  -webkit-animation: ball-beat 0.7s -0.15s infinite linear;
  -moz-animation: ball-beat 0.7s -0.15s infinite linear;
  -o-animation: ball-beat 0.7s -0.15s infinite linear;
  animation: ball-beat 0.7s -0.15s infinite linear;
}
.la-ball-beat > img:nth-child(2n-1) {
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.la-ball-beat.la-sm {
  width: 26px;
  height: 8px;
}
.la-ball-beat.la-sm > img {
  width: 4px;
  height: 4px;
  margin: 2px;
}

.la-ball-beat.la-2x {
  width: 108px;
  height: 36px;
}

.la-ball-beat.la-1x {
  width: 108px;
  height: 24px;
}

.la-ball-beat.la-2x > img {
  width: 20px;
  height: 20px;
  margin: 8px;
}
.la-ball-beat.la-3x {
  width: 162px;
  height: 54px;
}
.la-ball-beat.la-3x > img {
  width: 30px;
  height: 30px;
  margin: 12px;
}
/*
 * Animation
 */
@-webkit-keyframes ball-beat {
  50% {
    opacity: 0.2;
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes ball-beat {
  50% {
    opacity: 0.2;
    -moz-transform: scale(0.75);
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes ball-beat {
  50% {
    opacity: 0.2;
    -o-transform: scale(0.75);
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ball-beat {
  50% {
    opacity: 0.2;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
</style>
