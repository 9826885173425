import * as api from "@/services/api";

export const fetchDepartments = async ({ commit }) => {
  try {
    const response = await api.get("/departments");
    commit("initDepartments", response.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Departments" }, { root: true });
  }
};

export const createDepartment = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/departments", payload);
    if (response.data.id) {
      commit("createdDepartment", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Department" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Department" }, { root: true });
  }
};

export const fetchPaymentsAccepted = async ({ commit }, payload) => {
  try {
    const response = await api.get("/company-payments/accepted");
    commit("initPaymentsAccepted", response.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Departments" }, { root: true });
  }
};

export const destroyDepartment = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/departments/${payload}`);
    if (response.data == 1) {
      commit("destroyedDepartment", payload);
      dispatch("users/fetchUsers", "", { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Department" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Department" }, { root: true });
  }
};
