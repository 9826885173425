import { deleteProperty } from "@/services/helpers";

// Deal Docs Separate Section
export const initDocs = (state, payload) => {
  state.docs = payload;
};

export const addedDoc = (state, payload) => {
  state.docs = { ...state.docs, [payload.id]: payload };
};

// Deal Flows (will include array of pivot table deal docs)
export const init = (state, payload) => {
  state.list = payload;
};

export const added = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const removed = (state, payload) => {
  state.list = deleteProperty(state.list, payload.toString());
};

export const replaced = (state, payload) => {
  state.list[payload.id] = payload;
};

export const reset = (state, payload) => {
  state.list = {};
};

export const initParentOrderStatuses = (state, payload) => {
  state.parentOrderStatuses = payload;
};
