import * as api from "@/services/api";

export const fetchTagTypes = async ({ commit }) => {
  try {
    const response = await api.get("/tags/types");
    if (response.data.code == 200) {
      commit("initTagTypes", response.data.data.tagTypes);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Tag Types" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Tag Types" }, { root: true });
    return error;
  }
};

export const fetchTags = async ({ commit, dispatch }) => {
  try {
    const response = await api.get("/tags");
    if (response.data.code == 200) {
      commit("initTags", response.data.data.tags);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Tags" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Tags" }, { root: true });
    return error;
  }
};

export const createTag = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/tags/create", payload);
    if (response.data.code == 201) {
      commit("addTag", response.data.data.tag);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Tag" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Tag" }, { root: true });
    return error;
  }
};

export const updateTag = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/tags/update/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("updateTag", response.data.data.tag);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Tag" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Tag" }, { root: true });
    return error;
  }
};

export const deleteTag = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/tags/delete/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("deleteTag", response.data.data.tag);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Tag" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Tag" }, { root: true });
    return error;
  }
};
