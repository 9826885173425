import { arrayToObject, deleteProperty } from "@/services/helpers";

export const reset = (state, payload) => {
  state.list = {};
  state.unreadCount = 0;
  state.profileMessagesCount = 0;
  state.taskCounts.total = null;
  state.taskCounts.due = null;
  state.unreadChatsCount = 0;
};

export const init = (state, payload) => {
  state.list = arrayToObject(payload.companyAlerts, "id");
};

export const initCount = (state, payload) => {
  state.unreadCount = payload.companyAlertsUnreadCount;
};

export const initUnreadChatsCount = (state, payload) => {
  state.unreadChatsCount = payload;
};

export const initProfileMessagesCount = (state, payload) => {
  state.profileMessagesCount = payload;
};

export const initUnreadLeadsCount = (state, payload) => {
  state.newLeadsCount = payload;
};

export const initTaskCount = (state, payload) => {
  state.taskCounts = payload;
};

export const addCompanyAlert = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const updateCompanyAlert = (state, payload) => {
  state.list[payload.id] = { ...state.list[payload.id], ...payload };
};

export const processDeletedCompanyAlert = (state, payload) => {
  const deletedCompanyAlertPayload = payload.deletedCompanyAlert;
  state.list = deleteProperty(state.list, deletedCompanyAlertPayload.id.toString());
};
