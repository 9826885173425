import { arrayToObject, deleteProperty } from "@/services/helpers";

export const reset = (state, payload) => {
  state.list = {};
};

export const init = (state, payload) => {
  state.list = arrayToObject(payload.companyServices, "id");
};

export const updateCompanyServiceItemListing = (state, payload) => {
  state.list[payload.id] = { ...state.list[payload.id], ...payload };
};

export const addedCompanyService = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const deleteCompanyServiceItemListing = (state, payload) => {
  state.list = deleteProperty(state.list, payload.id.toString());
};
