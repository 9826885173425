import * as api from "@/services/api";

export const connectQuickbooks = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/quickbooks/connect");
    if (response.data.code == 200) {
      commit("companies/companyQuickBooksStatusEnabled", response.data.data, { root: true });
    } else if (response.data.code == 202) {
      // QB Token Redirect Uri came back, let the vue handle this.
    } else if (response.data.code == 422) {
      // Quickbooks allowable subscription type or subscription state failed. Vues will handle this.
    } else if (response.data.code == 403) {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Unauthorized to Connect to QuickBooks",
        },
        { root: true },
      );
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Connecting QuickBooks" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Connecting QuickBooks" }, { root: true });
    return error;
  }
};

export const disconnectQuickbooks = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy("/quickbooks/disconnect");
    if (response.data.code != 200) {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Disconnecting QuickBooks" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Disconnecting QuickBooks" }, { root: true });
    return error;
  }
};

export const getQuickBooksCompanyProducts = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/quickbooks/company-products");
    if (response.data.code == 200) {
      // Succesfully Connected
    } else if (response.data.code == 202) {
      // QB Token Redirect Uri came back, let the vue handle this.
    } else if (response.data.code == 422) {
      // Quickbooks allowable subscription type or subscription state failed. Vues will handle this.
    } else if (response.data.code == 403) {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Unauthorized to Connect to QuickBooks",
        },
        { root: true },
      );
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Getting Companies Products From QuickBooks",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Getting Companies Products From QuickBooks",
      },
      { root: true },
    );
    return error;
  }
};

export const getQuickBooksCompanyLocations = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/quickbooks/company-departments");
    if (response.data.code == 200) {
      // Succesfully Connected
    } else if (response.data.code == 202) {
      // QB Token Redirect Uri came back, let the vue handle this.
    } else if (response.data.code == 422) {
      // Quickbooks allowable subscription type or subscription state failed. Vues will handle this.
    } else if (response.data.code == 403) {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Unauthorized to Connect to QuickBooks",
        },
        { root: true },
      );
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching QB Locations" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching QB Locations" }, { root: true });
    return error;
  }
};

export const getQuickBooksCompanyCustomers = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.get("/quickbooks/company-customers");
    if (response.data.code == 200) {
      // Succesfully Connected
    } else if (response.data.code == 202) {
      // QB Token Redirect Uri came back, let the vue handle this.
    } else if (response.data.code == 422) {
      // Quickbooks allowable subscription type or subscription state failed. Vues will handle this.
    } else if (response.data.code == 403) {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Unauthorized to Connect to QuickBooks",
        },
        { root: true },
      );
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Getting Companies Customers From QuickBooks",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Getting Companies Customers From QuickBooks",
      },
      { root: true },
    );
    return error;
  }
};

export const clearQuickbooksData = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/quickbooks/clear-company-data", payload);
    if (response.data.code == 200) {
      commit("ui/setSnackbar", { variant: "success", message: "Success Clearing QuickBooks Data" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Clearing QuickBooks Products Data",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Clearing QuickBooks Products Data",
      },
      { root: true },
    );
    return error;
  }
};

export const bulkMapQuickBooksData = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/quickbooks/bulk-map-data", payload);
    if (response.data.code == 200) {
      commit("shippingOrders/updateOrderQbData", response.data.data, { root: true });
      commit("ui/setSnackbar", { variant: "success", message: "Success Saving QuickBooks Changes" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Saving QuickBooks Changes" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Saving QuickBooks Changes" }, { root: true });
    return error;
  }
};

export const generateQuickBooksInvoice = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/quickbooks/generate-invoice", payload);
    if (response.data.code == 200) {
      // Succesfully Connected
      commit("shippingOrders/addOrderQbInvoice", response.data.data, { root: true });
      commit("ui/setSnackbar", { variant: "success", message: "Success Generating Invoice" }, { root: true });
    } else if (response.data.code == 202) {
      // QB Token Redirect Uri came back, let the vue handle this.
    } else if (response.data.code == 422) {
      // Quickbooks allowable subscription type or subscription state failed. Vues will handle this.
    } else if (response.data.code == 409) {
      // The Quickbooks api returned an error and we are going to send the best parsed error message available.
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: response.data.message }, { root: true });
    } else if (response.data.code == 403) {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Unauthorized to Connect to QuickBooks",
        },
        { root: true },
      );
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Generating Invoice",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Generating Invoice" }, { root: true });
    return error;
  }
};

export const getQuickBooksInvoicePdf = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/quickbooks/invoice-pdf/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
    } else if (response.data.code == 202) {
      // QB Token Redirect Uri came back, let the vue handle this.
    } else if (response.data.code == 204) {
      // QB Invoice Not found
      // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
    } else if (response.data.code == 422) {
      // Quickbooks allowable subscription type or subscription state failed. Vues will handle this.
    } else if (response.data.code == 403) {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Unauthorized to Connect to QuickBooks",
        },
        { root: true },
      );
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Getting Invoice",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Invoice" }, { root: true });
    return error;
  }
};

export const deleteQuickBooksInvoice = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/quickbooks/delete-invoice/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("shippingOrders/removeOrderQbInvoice", response.data.data, { root: true });
      commit("ui/setSnackbar", { variant: "success", message: "Success Deleting Invoice" }, { root: true });
    } else if (response.data.code == 202) {
      // QB Token Redirect Uri came back, let the vue handle this.
    } else if (response.data.code == 204) {
      // QB Invoice Not found
      // commit('ui/setSnackbar', { variant: 'danger', title: 'Error', message: 'Error' }, { root: true });
    } else if (response.data.code == 422) {
      // Quickbooks allowable subscription type or subscription state failed. Vues will handle this.
    } else if (response.data.code == 403) {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Unauthorized to Connect to QuickBooks",
        },
        { root: true },
      );
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Invoice" }, { root: true });
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Invoice" }, { root: true });
    return error;
  }
};

export const cleanUpQuickBooksInvoice = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/quickbooks/cleanup-invoice/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("shippingOrders/removeOrderQbInvoice", response.data.data, { root: true });
      commit("ui/setSnackbar", { variant: "success", message: "Success Cleaning Up Invoice" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Cleaning Up Invoice",
        },
        { root: true },
      );
    }
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Cleaning Up Invoice" }, { root: true });
    return error;
  }
};
