import { arrayToObject, deleteProperty } from "@/services/helpers";

export const reset = (state, payload) => {
  state.products = {};
  state.productsLoaded = false;
};

export const clear = (state, payload) => {
  state.products = {};
};

export const initProducts = (state, payload) => {
  state.products = arrayToObject(payload, "id");
  state.productsLoaded = true;
};

export const replaceItem = (state, payload) => {
  state.products[payload.id] = { ...state.products[payload.id], ...payload };
};

export const replaceBatches = (state, payload) => {
  state.products[payload.product].batches = [...payload.batches];
};

export const itemDeleted = (state, payload) => {
  state.products = deleteProperty(state.products, payload.id.toString());
};

export const toggleArchivedProduct = (state, payload) => {
  state.products = deleteProperty(state.products, payload.id.toString());
};

export const setQuickOpenBatch = (state, payload) => {
  state.quickOpenBatch.id = payload.batchId;
  state.quickOpenBatch.product_id = payload.productId;
};

export const productAdded = (state, payload) => {
  state.products = { ...state.products, [payload.id]: payload };
};
