import { deleteProperty } from "@/services/helpers";

export const initInvitations = (state, payload) => {
  state.list = payload;
  state.loaded = true;
};

export const invitationAdded = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const invitationRemoved = (state, payload) => {
  state.list = deleteProperty(state.list, payload.toString());
};

export const reset = (state, payload) => {
  state.list = {};
  state.loaded = false;
};
