export const toggleSidebarSticky = (state, payload) => {
  state.sidebar.sticky = payload.sticky;
  if (payload.sticky) {
    state.sidebar.close = false;
  }
};

export const toggleSupportLandingDisplay = (state) => {
  state.supportLanding.open = !state.supportLanding.open;
};

// opens or closes the sidebar, on mobile will be invisible, 768 < will be minified
export const switchSidebar = (state, payload) => {
  state.sidebar.close = payload;
  if (payload == true) {
    state.sidebar.sticky = false;
  }
};

// // responsible for highlighting the active route
// export const changeSidebarActive = (state, payload) => {
//   state.sidebar.activeElement = payload;
//   return null;
// };

export const setSnackbar = (state, payload) => {
  if ((!state.snackbar.message && !state.snackbar.title) || (payload.message != state.snackbar.message && payload.title != state.snackbar.title)) {
    state.snackbar.variant = payload.variant;
    state.snackbar.title = payload.title;
    state.snackbar.message = payload.message;
    state.snackbar.position = payload.position;
    state.snackbar.duration = payload.duration;
  }
};

export const clearSnackbar = (state) => {
  state.snackbar.variant = null;
  state.snackbar.title = null;
  state.snackbar.message = null;
  state.snackbar.position = "b-toaster-bottom-right";
};

export const handleSupportArticleDrawer = (state, payload) => {
  if (payload && payload.length) {
    state.supportArticleDrawer.articleSlug = payload;
    state.supportArticleDrawer.open = true;
  } else {
    state.supportArticleDrawer.articleSlug = null;
    state.supportArticleDrawer.open = false;
  }
};

export const IncrementNavUIKey = (state) => {
  state.navUIKey += 1;
};

export const setOnboarding = (state, payload) => {
  state.onboarding = payload;
};

export const handleSettingDashboardPopupShown = (state, payload) => {
  state.dashboardOnboardPopupShown = true;
};
