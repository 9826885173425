import * as api from "@/services/api";

export const fetchCompanyServices = async ({ commit }) => {
  try {
    const response = await api.get("/company-services");
    if (response.data.code == 200) {
      commit("init", response.data.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Company Services" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Getting Company Services" }, { root: true });
    return error;
  }
};

export const createNewService = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/company-services/create", payload);
    if (response.data.code == 201) {
      commit("addedCompanyService", response.data.data.service);
      commit("ui/setSnackbar", { variant: "success", message: "Service Created Successfully" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Creating Service",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Creating Service",
      },
      { root: true },
    );
    return error;
  }
};

export const inlineUpdateCompanyServiceItemListing = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/company-services/inline/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("updateCompanyServiceItemListing", response.data.data);
      commit("ui/setSnackbar", { variant: "success", message: "Service Updated Successfully" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Service" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Service" }, { root: true });
    return error;
  }
};

export const fullUpdateExistingService = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/company-services/full/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("updateCompanyServiceItemListing", response.data.data);
      commit("ui/setSnackbar", { variant: "success", message: "Service Updated Successfully" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Service" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Service" }, { root: true });
    return error;
  }
};

export const toggleCompanyServicePromotion = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/company-services/promo-change/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("updateCompanyServiceItemListing", response.data.data);
      commit("ui/setSnackbar", { variant: "success", message: "Service Promotion Updated Successfully" }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Updating Service Promotion",
        },
        { root: true },
      );
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Service Promotion" }, { root: true });
    return error;
  }
};

export const deleteCompanyServiceItem = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/company-services/${payload.id}`, payload);
    if (response.data.code == 200) {
      commit("deleteCompanyServiceItemListing", response.data.data);
      commit("ui/setSnackbar", { variant: "success", message: "Service Deleted Successfully" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Service" }, { root: true });
    }
    return response.data.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Service" }, { root: true });
    return error;
  }
};
