import { createStore } from "vuex";
import VuexPersist from "vuex-persist";

import auth from "@/store/modules/auth/index";
import baseCompany from "@/store/modules/baseCompany/index";
import ui from "@/store/modules/ui/index";
import users from "@/store/modules/users/index";
import usersSecure from "@/store/modules/usersSecure/index";
import base from "@/store/modules/base/index";
import companies from "@/store/modules/companies/index";
import bulkDiscounts from "@/store/modules/bulkDiscounts/index";
import companyAlerts from "@/store/modules/companyAlerts/index";
import operations from "@/store/modules/operations/index";
import tags from "@/store/modules/tags/index";
import brands from "@/store/modules/brands/index";
import dealFlows from "@/store/modules/dealFlows/index";
import rejects from "@/store/modules/rejects/index";

import netTerms from "@/store/modules/netTerms/index";
import buyers from "@/store/modules/buyers/index";
import vendors from "@/store/modules/vendors/index";
import pricingTiers from "@/store/modules/pricingTiers/index";
import invitations from "@/store/modules/invitations/index";
import roles from "@/store/modules/roles/index";
import shippingOrders from "@/store/modules/shippingOrders/index";
import receivingOrders from "@/store/modules/receivingOrders/index";
import inventory from "@/store/modules/inventory/index";
import companyServices from "@/store/modules/companyServices/index";
import buyerStages from "@/store/modules/buyerStages/index";
import events from "@/store/modules/events/index";
import reporting from "@/store/modules/reporting/index";
import quickbooks from "./modules/quickbooks/index";

const watchedMutations = ["auth/setUser", "auth/logout", "auth/impersonate", "auth/stopImpersonating", "auth/updateUserProfile"];

// eslint-disable-next-line no-undef
const APPTYPE = APP_TYPE;

const persist =
  APPTYPE != "widget"
    ? new VuexPersist({
        key: "vuex",
        storage: window.localStorage,
        reducer: (state) => ({ auth: state.auth }),
        filter: (mutation) => watchedMutations.indexOf(mutation.type) != -1,
      })
    : null;

export default createStore({
  strict: true,
  modules: {
    auth,
    ui,
    users,
    usersSecure,
    base,
    baseCompany,
    companies,
    bulkDiscounts,
    companyAlerts,
    operations,
    tags,
    brands,
    dealFlows,
    rejects,

    netTerms,
    invitations,
    roles,
    buyerStages,
    pricingTiers,
    receivingOrders,
    shippingOrders,
    inventory,
    buyers,
    vendors,
    companyServices,
    events,
    reporting,
    quickbooks,
  },
  plugins: APPTYPE != "widget" ? [persist.plugin] : [],
});
