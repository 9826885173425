import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const createBrand = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/brands", payload);
    if (response.data.id) {
      commit("brandAdded", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Brand" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Brand" }, { root: true });
    return error;
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/brands/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceBrand", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Brand" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Brand" }, { root: true });
    return error;
  }
};

export const fetchBrands = async ({ commit }) => {
  try {
    const response = await api.get("/brands");
    const brands = arrayToObject(response.data, "id");
    commit("initBrands", brands);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Brand List" }, { root: true });
  }
};

export const fetchBrand = async ({ commit, dispatch }, id) => {
  try {
    const response = await api.get(`/brands/${id}`);
    if (response.data.id) {
      commit("replaceBrand", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Brand Data" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Brand Data" }, { root: true });
  }
};

export const destroyBrand = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/brands/${payload}`);
    if (response.data.message == "success") {
      commit("brandRemoved", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Brand" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Brand" }, { root: true });
  }
};

export const restoreBrand = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/brands/restore/${payload}`);
    if (response.data.message == "success") {
      commit("brandRestored", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Brand" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Restoring Brand" }, { root: true });
  }
};
