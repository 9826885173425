<template>
  <b-form-group style="position: relative" class="bushel-form mb-0">
    <div class="row">
      <div
        v-show="!hideLabel"
        class="col-12 text-left"
        :class="{
          'd-sm-none': colWrapSize == 'sm',
          'd-md-none': colWrapSize == 'md',
          'd-lg-none': colWrapSize == 'lg',
        }"
      >
        <div class="d-flex" :class="labelWrapperClass">
          <div>
            <label class="bootstrap-mod-label" :class="[{ focussed: inputFocused }, labelClass]" :for="inputId ? inputId : null">
              {{ labelActive }}
            </label>
            <i
              v-if="tooltipMessage"
              :id="tooltipId"
              class="fas fa-question text-info ml-2"
              :class="tooltipClass"
              style="cursor: default"
              :style="tooltipStyle"
            />
            <BTooltip v-if="tooltipMessage" :target="tooltipId" :placement="tooltipPlacement" triggers="hover">
              {{ tooltipMessage }}
            </BTooltip>
            <slot name="label" />
          </div>
          <div>
            <slot name="label-button"></slot>

            <!-- <BushelBtn
              v-if="labelButtonIconClass && labelButtonId"
              :button-id="labelButtonId"
              :flat="labelButtonFlat"
              :variant="labelButtonVariant"
              class="py-0 px-1"
              @click="$emit('labelButtonClicked')"
            >
              <i :class="labelButtonIconClass" />
            </BushelBtn>
            <BTooltip
              v-if="labelButtonMessage && labelButtonIconClass && labelButtonId"
              :target="labelButtonId"
              :placement="labelButtonTooltipPlacement"
              triggers="hover"
              :delay="{ show: 200, hide: 0 }"
            >
              {{ labelButtonMessage }}
            </BTooltip> -->
          </div>
        </div>
      </div>
      <div
        v-show="!hideLabel"
        class="col-4 text-right mt-2 d-none"
        :class="{
          labelColClass,
          'd-sm-block': colWrapSize == 'sm',
          'd-md-block': colWrapSize == 'md',
          'd-lg-block': colWrapSize == 'lg',
        }"
      >
        <label class="bootstrap-mod-label" :class="[{ focussed: inputFocused }, labelClass]" :for="inputId ? inputId : null">
          <span v-if="required && labelActive" class="text-danger">*</span>{{ labelActive }}
        </label>
        <i
          v-if="tooltipMessage"
          :id="tooltipId"
          class="fas fa-question text-info ml-2"
          :class="tooltipClass"
          style="cursor: pointer"
          :style="tooltipStyle"
        />
        <BTooltip v-if="tooltipMessage" :target="tooltipId" :placement="tooltipPlacement" triggers="hover">
          {{ tooltipMessage }}
        </BTooltip>
      </div>
      <div class="col-12" :class="inputColClass">
        <b-form-input
          :id="inputId"
          :disabled="disabled"
          :readonly="readonly"
          :value="modelValue ? modelValue : value"
          :type="computedType"
          :no-wheel="noWheel"
          :name="name"
          :autofocus="autofocus"
          :autocomplete="autocomplete"
          :placeholder="placeholder"
          :maxlength="maxlength"
          :state="error ? false : null"
          :class="[
            inputClass,
            {
              'text-center': centerInput,
              'prefix-padding': prefix && prefix.length >= 1,
              'pl-prepend-icon': prependIcon,
            },
          ]"
          :min="min"
          :max="max"
          :style="inputStyle"
          @input="(e) => onInput(e)"
          @change="(c) => onInput(e)"
          @focus="setInputFocused"
          @blur="setInputBlurred"
          @keyup="$emit('keyup')"
          @enter="onEnter"
          @keydown="handleInternalChecks"
          @paste="(e) => onPaste(e)"
        />
        <div v-if="prefix && prefix.length >= 1" class="input-prefix" :class="{ focussed: inputFocused }">
          {{ prefix }}
        </div>
        <div v-if="(suffix && suffix.length >= 1) || loading" class="input-suffix" :class="[suffixClass, { focussed: inputFocused }]">
          {{ suffix && suffix.length >= 1 && !loading ? suffix : null }}
          <i v-if="loading" class="fas fa-circle-notch fa-spin text-light fa-fw" />
        </div>
        <div
          v-if="prependIcon"
          :id="prependIconId"
          class="prepend-icon"
          :class="[prependIcon, { 'not-clickable': iconCursorDefault }]"
          :style="'color: ' + iconColor"
          @click="prependClicked"
        />
        <BTooltip
          v-if="prependIconMessage && prependIcon && prependIconId"
          :target="prependIconId"
          :placement="prependIconTooltipPosition"
          triggers="hover"
          :delay="{ show: 200, hide: 0 }"
        >
          {{ prependIconMessage }}
        </BTooltip>
        <div
          v-if="appendIcon || appendIconLarge"
          :class="[
            appendIcon,
            appendIconLarge,
            {
              'append-icon': appendIcon,
              'append-icon-large': appendIconLarge,
              'not-clickable': iconCursorDefault,
            },
          ]"
          :style="{
            color: iconColor,
            textShadow: '0px 0px 1px rgba(0,0,0,1)',
          }"
          @click="appendClicked"
        />
        <div v-if="noBottomPadding" class="" />
        <div v-else-if="thin" class="thin-padding" />
        <div v-else>
          <div v-show="!error" class="feedback-spacer" :class="[{ 'd-none': inputFocused }, messageClass]" />
          <b-form-invalid-feedback v-if="error && errorText" id="inputLiveFeedback" class="message hidden-overflow-vis">
            {{ errorText }}
          </b-form-invalid-feedback>
          <b-form-text v-show="!error" class="message hidden-overflow-vis" :class="[{ 'd-none': !inputFocused }, messageClass]">
            {{ message }}
          </b-form-text>
        </div>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import BFormInput from "@/components/base/BFormInput.vue";
import BFormGroup from "@/components/base/BFormGroup.vue";
import BFormInvalidFeedback from "@/components/base/BFormInvalidFeedback.vue";
import BFormText from "@/components/base/BFormText.vue";
import { onNumberKeyDown, processNumberInput } from "@/services/inventory/inventory";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BFormText,
  },
  props: {
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    tooltipMessage: { type: String },
    tooltipPlacement: { type: String, default: "bottom" },
    tooltipId: { type: String },
    tooltipClass: { type: String },
    tooltipStyle: { type: Object },
    labelButtonTooltipPlacement: { type: String, default: "bottom" },
    labelButtonIconClass: { type: String },
    labelButtonId: { type: [String, Boolean] },
    labelButtonMessage: { type: String },
    labelButtonFlat: { type: Boolean, default: true },
    labelButtonVariant: { type: String, default: "primary" },
    labelWrapperClass: { type: String, default: "justify-content-between" },
    noWheel: { type: Boolean, default: true },
    autofocus: { type: Boolean },
    labelColClass: { type: String },
    labelClass: { type: String, default: "" },
    inputColClass: { type: String },
    colWrapSize: { type: String },
    type: { type: String, default: "text" },
    name: { type: String },
    value: { type: [String, Number], default: null },
    modelValue: { type: [String, Number], default: null },
    message: { type: String, default: null },
    messageInTooltip: { type: Boolean, default: false },
    messageClass: { type: String, default: "" },
    placeholder: { type: String },
    maxlength: { type: Number, default: 191 },
    error: { type: Boolean, default: false },
    errorText: { type: String },
    labelActive: { type: String },
    suffix: { type: String },
    suffixClass: { type: String },
    prefix: { type: String },
    appendIcon: { type: String },
    appendIconLarge: { type: String },
    prependIcon: { type: String },
    prependIconTooltipPosition: { type: String, default: "bottom" },
    prependIconId: { type: String },
    prependIconMessage: { type: String },
    inputClass: { type: String },
    inputStyle: { type: String },
    hideLabel: { type: Boolean, default: false },
    centerInput: { type: Boolean, default: false },
    labelFixed: { type: Boolean, default: false },
    iconCursorDefault: { type: Boolean, default: false },
    iconColor: { type: String },
    thin: { type: Boolean, default: false },
    noBottomPadding: { type: Boolean, default: false },
    autocomplete: { type: String, default: "off" },
    min: { type: Number },
    max: { type: Number },
    inputId: { type: String, default: null },
    textLimit: { type: Number, default: null },
    blurAlt: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    numberTypeAllowCommas: { type: Boolean, default: false },
    numberTypeAllowDecimal: { type: Boolean, default: false },
    numberTypeAllowDashes: { type: Boolean, default: false },
    numberTypeMaxPrecision: { type: Number, default: 2 },
    numberTypeAllowNegative: { type: Boolean, default: false },
  },
  emits: [
    "input",
    "inputFocussed",
    "inputBlurred",
    "iconClicked",
    "appendClicked",
    "prependClicked",
    "change",
    "keypress",
    "keyup",
    "enter",
    "keydown",
    "paste",
    "update:modelValue",
    "labelButtonClicked",
  ],
  data() {
    return {
      // innerValue: this.value ? this.value : '',
      innerValue: "",
      inputFocused: false,
      tooltipVisible: false,
      prependtooltipVisible: false,
    };
  },
  computed: {
    computedType() {
      if (this.type === "number") {
        return "text";
      }
      return this.type;
    },
  },
  methods: {
    onNumberKeyDown,
    processNumberInput,
    onPaste(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData("text");

      if (this.type == "number") {
        const options = {
          allowNegative: this.numberTypeAllowNegative,
          allowDecimal: this.numberTypeAllowDecimal,
          allowCommas: this.numberTypeAllowCommas,
          allowDashes: this.numberTypeAllowDashes,
          maxPrecision: this.numberTypeMaxPrecision,
        };

        const result = processNumberInput(event.target.value, pastedText, event.target.selectionStart, event.target.selectionEnd, options);

        if (result) {
          event.target.value = result.newValue;
          event.target.setSelectionRange(result.newPosition, result.newPosition);
          this.onInput(result.newValue);
          this.$emit("paste", event);
        }
      } else {
        this.onInput(pastedText);
        this.$emit("paste", event);
      }
    },
    onInput(e) {
      this.$emit("update:modelValue", e);
      this.$emit("input", e);
    },
    onEnter(e) {
      this.$emit("enter", e);
    },
    setInputFocused() {
      this.inputFocused = true;
      this.$emit("inputFocussed");
    },
    setInputBlurred() {
      this.inputFocused = false;
      if (this.blurAlt) {
        this.$emit("inputBlurred");
      } else {
        this.$emit("inputBlurred", this.innerValue);
      }
    },
    handleInternalChecks(evt) {
      this.checkInputLimit(evt);
      if (this.type === "number") {
        this.onNumberKeyDown(evt, {
          allowNegative: this.numberTypeAllowNegative,
          allowDecimal: this.numberTypeAllowDecimal,
          allowCommas: this.numberTypeAllowCommas,
          allowDashes: this.numberTypeAllowDashes,
          maxPrecision: this.numberTypeMaxPrecision,
        });
      }
      this.$emit("keydown");
    },
    checkInputLimit(evt) {
      if (this.value && this.textLimit && this.value.length >= this.textLimit) {
        if (evt.keyCode >= 48 && evt.keyCode <= 90) {
          // Allow for other keys not to be pressed.
          evt.preventDefault();
        }
      }
    },
    appendClicked() {
      this.$emit("appendClicked");
      this.$emit("iconClicked");
    },
    prependClicked() {
      this.$emit("prependClicked");
      this.$emit("iconClicked");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@sass/_variables.scss";

.prepend-bti-icon {
  position: absolute;
  left: 26px;
  top: 11px;
  color: #6c757d;
  cursor: pointer;
}

.prefix-padding {
  padding-left: 20px;
}
</style>
