export default {
  snackbar: {
    message: "",
    variant: null,
    title: null,
    position: "b-toaster-bottom-right",
    duration: 3400,
  },
  sidebar: {
    close: false,
    sticky: true,
    // activeElement: "dashboard",
  },
  supportArticleDrawer: {
    open: false,
    articleSlug: null,
  },
  supportLanding: {
    open: false,
  },
  dashboardOnboardPopupShown: false,
  theme: "dark",
  navUIKey: 1,
  onboarding: false,
};
