import * as api from "@/services/api";

export const fetchUsers = async ({ commit }) => {
  try {
    const response = await api.get("/company-users-public");
    commit("init", response.data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Users" }, { root: true });
  }
};
