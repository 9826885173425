import { arrayToObject, deleteProperty } from '../../../services/helpers';

export const addPermission = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.id].permissions.push(payload.type.toLowerCase()) };
};

export const attachPermissions = (state, payload) => {
  state.list[payload.id].permissions = payload.permissions;
};

export const removePermission = (state, payload) => {
  const updatedPermissions = state.list[payload.id].permissions.filter((permission) => (permission !== payload.type.toLowerCase()));
  state.list[payload.id].permissions = updatedPermissions;
};

export const toggleAllPermissions = (state, payload) => {
  state.list[payload.id].permissions = payload.permissions;
};

export const init = (state, payload) => {
  const list = [];
  payload.forEach((item) => {
    const itemVar = { ...item };
    itemVar.permissions = null;
    list.push(itemVar);
  });
  state.list = arrayToObject(list, 'id');
  state.loaded = true;
};

export const created = (state, payload) => {
  state.list = { ...state.list, [payload.id]: { ...payload, permissions: null } };
};

export const destroyed = (state, payload) => {
  state.list = deleteProperty(state.list, payload.toString());
};

export const reset = (state, payload) => {
  state.list = {};
  state.loaded = false;
};
