<template>
  <div class="bushel-form">
    <label v-if="!hideMainLabel" :class="[{ focussed: inputFocused }, labelClass]" :for="inputId ? inputId : null">
      <span v-if="required && labelActive" class="text-danger">*</span> {{ labelActive }}
    </label>
    <slot name="afterLabelSlot" />

    <i v-if="tooltipMessage && tooltipId" :id="tooltipId" class="fas fa-question text-info ml-2" :class="tooltipClass" :style="tooltipStyle" />
    <BTooltip v-if="tooltipMessage" :target="tooltipId" :content="tooltipMessage" :position="tooltipPlacement" />
    <multiselect
      :id="inputId"
      :class="{ error: error, 'no-deselect': noDeselect }"
      :model-value="value"
      :multiple="multiple"
      :tag-placeholder="tagPlaceholder || 'Add'"
      :placeholder="placeholder || (taggable ? 'Select or Create' : searchable ? 'Select / Search' : 'Select')"
      :select-label="selectLabel ? selectLabel : 'Select'"
      :deselect-label="deselectLabel ? deselectLabel : 'Deselect'"
      :options="options"
      :taggable="taggable"
      :loading="loading"
      :allow-empty="allowEmpty && !noDeselect"
      :show-labels="showSelectLabels && !noDeselect"
      :hide-selected="hideSelected"
      :searchable="searchable"
      :track-by="trackBy"
      :label="label"
      :disabled="disabled"
      :internal-search="internalSearch"
      :preselect-first="preselectFirst"
      :open-direction="openDirection"
      :close-on-select="closeOnSelect"
      :custom-label="customLabel"
      :options-limit="optionsLimit"
      :limit="visibleLimit"
      @tag="(value) => $emit('tag', value)"
      @search-change="(value) => $emit('searchUpdated', value)"
      @select="(value) => selected(value)"
      @remove="(value) => removed(value)"
      @open="setInputFocused"
      @close="setInputBlurred"
    >
      <template #placeholder>
        <b-spinner v-if="placeholderAttentionOne" :class="placeholderAttentionClassOne" type="grow" small />
        {{ placeholder }}
        <b-spinner v-if="placeholderAttentionTwo" :class="placeholderAttentionClassTwo" type="grow" small />
      </template>

      <template #noOptions>
        {{ noOptions ? noOptions : "List Is Empty" }}
      </template>
      <template #noResult>
        {{ noResults ? noResults : "No Results." }}
      </template>
      <template v-if="customOptionNames" #option="props">
        {{ props.option.customOptionName }}
      </template>
    </multiselect>
    <div v-if="thin" class="thin-padding" />
    <div v-else-if="noPadding" class="no-padding" />
    <div v-else-if="error">
      <b-form-invalid-feedback id="inputLiveFeedback" class="message hidden-overflow-vis d-block mt-0">
        {{ errorText }}
      </b-form-invalid-feedback>
    </div>
    <div v-else class="regular-padding" />
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import BFormInvalidFeedback from "@/components/base/BFormInvalidFeedback.vue";
import BSpinner from "@/components/base/BSpinner.vue";

export default {
  components: {
    BFormInvalidFeedback,
    Multiselect,
    BSpinner,
  },
  props: {
    inputId: { type: String, default: null },
    tooltipMessage: { type: String },
    tooltipId: { type: String },
    tooltipClass: { type: String },
    tooltipStyle: { type: Object },
    tooltipPlacement: { type: String, default: "bottom" },
    placeholderAttentionClassOne: { type: String, default: "text-success" },
    placeholderAttentionClassTwo: { type: String, default: "text-success" },
    labelClass: { type: String, default: "" },
    deselectLabel: { type: String },
    selectLabel: { type: String },
    taggable: { type: Boolean, default: false },
    noDeselect: { type: Boolean, default: false },
    customOptionNames: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    closeOnSelect: { type: Boolean, default: true },
    showSelectLabels: { type: Boolean, default: false },
    hideSelected: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    allowEmpty: { type: Boolean, default: true },
    preselectFirst: { type: Boolean, default: false },
    trackBy: { type: String, default: "" },
    label: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorText: { type: String },
    labelActive: { type: String },
    hideMainLabel: { type: Boolean, default: false },
    customLabel: { type: Function },
    thin: { type: Boolean },
    noPadding: { type: Boolean, default: false },
    placeholderAttentionOne: { type: Boolean, default: false },
    placeholderAttentionTwo: { type: Boolean, default: false },
    placeholder: { type: String },
    tagPlaceholder: { type: String },
    options: { type: Array, required: true },
    value: { type: [String, Object, Array, Number] },
    noOptions: { type: String },
    required: { type: Boolean, default: false },
    noResults: { type: String },
    openDirection: { type: String, default: "auto" },
    internalSearch: { type: Boolean, default: true },
    optionsLimit: { type: [String, Number], default: 1000 },
    visibleLimit: { type: Number, default: 1000 },

    // ---------------- PERSISTENT STORAGE NOTES ----------------
    // use local storage for FOREVER storage, session storage for only until browser is closed.
    // if you set this, be sure to give it a unique name. Preference is to do pageName.inputName, eg stock-alerts.alerts-for-brands
    localStorageId: { type: String, default: null },
    sessionStorageId: { type: String, default: null },
    // ---------------- Important ----------------
  },
  emits: ["select", "remove", "inputFocussed", "inputBlurred", "searchUpdated", "tag"],
  data() {
    return {
      inputFocused: false,
    };
  },
  computed: {},
  mounted() {
    this.loadFromStorage();
  },
  methods: {
    selected(value) {
      this.$emit("select", value);
      setTimeout(() => {
        // we don't set the value, but the prop value passed back in because multi-select doesn't have access
        // to the full value when it is sent up. This works well.
        this.saveToStorage();
      }, 1000);
    },

    removed(value) {
      this.$emit("remove", value);
      this.removeFromStorage();
    },

    removeFromStorage() {
      if (this.localStorageId) {
        localStorage.removeItem(this.localStorageId);
      } else if (this.sessionStorageId) {
        sessionStorage.removeItem(this.sessionStorageId);
      }
    },

    saveToStorage() {
      if (this.multiple) {
        const stringified = JSON.stringify(this.value);
        if (this.localStorageId) {
          localStorage.setItem(this.localStorageId, stringified);
        } else if (this.sessionStorageId) {
          sessionStorage.setItem(this.sessionStorageId, stringified);
        }
      } else {
        const stringified = JSON.stringify(this.value);

        if (this.localStorageId) {
          localStorage.setItem(this.localStorageId, stringified);
        } else if (this.sessionStorageId) {
          sessionStorage.setItem(this.sessionStorageId, stringified);
        }
      }
    },

    loadFromStorage() {
      try {
        let parsed = null;

        if (this.localStorageId) {
          const storedValue = localStorage.getItem(this.localStorageId);
          if (storedValue !== null) {
            parsed = JSON.parse(storedValue);
          }
        } else if (this.sessionStorageId) {
          const storedValue = sessionStorage.getItem(this.sessionStorageId);
          if (storedValue !== null) {
            parsed = JSON.parse(storedValue);
          }
        }

        if (parsed) {
          if (this.multiple) {
            for (let i = 0; i < parsed.length; i++) {
              this.$emit("select", parsed[i]);
            }
          } else {
            this.$emit("select", parsed);
          }
        }
      } catch (error) {
        this.removeFromStorage();
      }
    },
    setInputFocused() {
      this.inputFocused = true;
      this.$emit("inputFocussed");
    },
    setInputBlurred() {
      this.inputFocused = false;
      this.$emit("inputBlurred");
    },
  },
};
</script>

<style scoped></style>
