export const vendorAdded = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const replaceVendor = (state, payload) => {
  state.list[payload.id] = payload;
};

export const loadingVendors = (state, payload) => {
  state.loaded = false;
};

export const initVendors = (state, payload) => {
  state.list = payload.vendors;
  state.requestMet = payload.requestMet;
  state.loaded = true;
};

export const reset = (state, payload) => {
  state.list = {};
  state.loaded = false;
};
