import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const create = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/deal-flows", payload);
    if (response.data.id) {
      commit("added", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Deal Flow" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "Problem Creating Deal Flow" }, { root: true });
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/deal-flows/${payload.dealFlow.id}`, payload);
    if (response.data.id) {
      commit("replaced", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Deal Flow" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "Problem Creating Deal Flow" }, { root: true });
  }
};

export const createTask = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/deal-flows/${payload.deal_flow_id}/task`, payload);
    if (response.data.id) {
      commit("createdTask", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Task" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "Problem Creating Task" }, { root: true });
  }
};

export const updateTask = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/deal-flows/${payload.deal_flow_id}/task`, payload);
    if (response.data.id) {
      commit("updatedTask", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Task" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "Problem Creating Task" }, { root: true });
  }
};

export const destroyTask = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/deal-flows/${payload.deal_flow_id}/task`, payload);
    if (response.data && response.data.message == "success") {
      commit("removedTask", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Creating Task" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "Problem Creating Task" }, { root: true });
  }
};

export const destroy = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/deal-flows/${payload}`);
    if (response.data && response.data.message == "success") {
      commit("removed", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Deal Flow" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Deal Flow" }, { root: true });
  }
};

export const fetch = async ({ commit }) => {
  try {
    const response = await api.get("/deal-flows");
    const data = arrayToObject(response.data, "id");
    commit("init", data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Deal Flow List" }, { root: true });
  }
};

export const fetchDocs = async ({ commit }) => {
  try {
    const response = await api.get("/deal-docs");
    const data = arrayToObject(response.data, "uuid");
    commit("initDocs", data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Deal Docs" }, { root: true });
  }
};

export const createDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/deal-docs", payload);
    if (response.data.uuid) {
      commit("addedDoc", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Deal Doc" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "warning", title: "Error", message: "Error Creating Deal Doc" }, { root: true });
  }
};

export const deleteDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/deal-docs/${payload}`);
    const data = arrayToObject(response.data, "uuid");
    commit("initDocs", data);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Deal Doc" }, { root: true });
  }
};

export const show = async ({ commit, dispatch }, id) => {
  try {
    const response = await api.get(`/deal-flows/${id}`);
    if (response.data.id) {
      commit("replaced", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Deal Flow Data" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Deal Flow Data" }, { root: true });
  }
};

export const fetchParentOrderStatuses = async ({ commit }) => {
  try {
    const response = await api.get("/deal-flows/parent/statuses");
    commit("initParentOrderStatuses", response.data);
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "error fetching parent order statuses",
      },
      { root: true },
    );
  }
};
