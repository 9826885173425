<template>
  <Teleport to="body" :disabled="!enabled">
    <Transition name="fade">
      <div
        v-show="visible"
        ref="tooltip"
        :class="['tooltip', tooltipClass]"
        :style="{ ...tooltipStyles, ...tooltipStyle }"
        @mouseenter="onTooltipMouseEnter"
        @mouseleave="onTooltipMouseLeave"
      >
        <div ref="arrow" class="arrow"></div>
        <div class="tooltip-body">
          <slot>{{ content }}</slot>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { computePosition, autoUpdate, offset, flip, shift, arrow, inline } from "@floating-ui/dom";

export default {
  props: {
    placement: {
      type: String,
      default: "bottom",
    },
    content: {
      type: String,
      default: "",
    },
    triggers: {
      type: String,
      default: "hover",
    },
    triggerClass: {
      type: String,
      default: "d-inline",
    },
    tooltipClass: {
      type: [String, Array, Object],
      default: "",
    },
    tooltipStyle: {
      type: [String, Object],
      default: "",
    },
    delay: {
      type: Object,
      default: () => ({ show: 0, hide: 0 }),
    },
    gap: {
      type: Number,
      default: 15,
    },
    target: {
      type: String,
    },
  },
  emits: ["click", "mouseenter", "mouseleave", "blur", "focus"],
  data() {
    return {
      tooltipStyles: {},
      arrowStyles: {},
      cleanupAutoUpdate: null,
      showTimeoutId: null,
      hideTimeoutId: null,
      visible: false,
      referenceElement: null,
      isMouseOverTooltip: false,
      isDragging: false,
      enabled: false,
      maxRetries: 5,
      retryInterval: 200,
      currentRetry: 0,
    };
  },

  watch: {
    placement() {
      this.setTooltipPosition();
    },
  },
  mounted() {
    this.enabled = true;
    this.$nextTick(() => {
      if (!this.target) return;
      this.findReferenceElement();
    });
  },
  beforeUnmount() {
    this.enabled = false;
    this.cleanupAutoUpdate && this.cleanupAutoUpdate();
  },
  methods: {
    attachEventListeners() {
      if (!this.referenceElement) return;

      if (this.triggers === "hover") {
        this.referenceElement.addEventListener("mouseenter", this.onMouseenter);
        this.referenceElement.addEventListener("mouseleave", this.onMouseleave);
        this.referenceElement.addEventListener("mousedown", this.onMousedown);
      }

      if (this.triggers === "focus") {
        this.referenceElement.addEventListener("focus", this.onFocus);
        this.referenceElement.addEventListener("blur", this.onBlur);
      }

      if (this.triggers === "click") {
        this.referenceElement.addEventListener("click", this.onClick);
      }

      this.referenceElement.addEventListener("drag", this.onDrag);
      this.referenceElement.addEventListener("dragend", this.onDragEnd);
    },
    findReferenceElement() {
      if (this.target.$el) {
        this.referenceElement = this.target.$el;
      } else {
        this.referenceElement = document.getElementById(this.target);
      }
      if (!this.referenceElement && this.currentRetry < this.maxRetries) {
        this.currentRetry++;
        setTimeout(() => this.findReferenceElement(), this.retryInterval);
        return;
      }

      if (this.referenceElement) {
        this.attachEventListeners();
        this.setTooltipPosition();
      }
    },
    showTooltip() {
      clearTimeout(this.hideTimeoutId);
      this.showTimeoutId = setTimeout(() => {
        this.visible = true;
        this.setTooltipPosition();
      }, this.delay.show);
    },
    hideTooltip() {
      clearTimeout(this.showTimeoutId);
      if (!this.isMouseOverTooltip) {
        this.hideTimeoutId = setTimeout(() => {
          this.visible = false;
          if (this.cleanupAutoUpdate) {
            this.cleanupAutoUpdate();
            this.cleanupAutoUpdate = null;
          }
        }, this.delay.hide);
      }
    },
    onClick() {
      if (this.triggers === "click") {
        if (this.visible) {
          this.hideTooltip();
        } else {
          this.showTooltip();
        }
      }

      this.$emit("click");
    },
    onMouseenter() {
      if (this.triggers === "hover") {
        this.showTooltip();
      }
      this.$emit("mouseenter");
    },
    onMouseleave(e) {
      if (this.triggers === "hover" && e.relatedTarget !== this.$refs.tooltip) {
        this.hideTooltip();
      }
      this.$emit("mouseleave");
    },
    onMousedown() {
      if (this.triggers === "hover") {
        this.hideTooltip();
      }
    },
    onTooltipMouseEnter() {
      this.isMouseOverTooltip = true;
      clearTimeout(this.hideTimeoutId);
    },
    onTooltipMouseLeave(e) {
      this.isMouseOverTooltip = false;
      if (e.relatedTarget !== this.referenceElement) {
        this.hideTooltip();
      }
    },
    onBlur() {
      if (this.triggers === "focus") {
        this.hideTooltip();
      }
      this.$emit("blur");
    },
    onFocus() {
      if (this.triggers === "focus") {
        this.showTooltip();
      }
      this.$emit("focus");
    },
    onDrag() {
      this.isDragging = true;
      this.hideTooltip();
    },
    onDragEnd() {
      this.isDragging = false;
      this.setTooltipPosition();
      this.showTooltip();
    },
    setTooltipPosition() {
       
      const referenceElement = this.referenceElement;
      const floatingElement = this.$refs.tooltip;
      const arrowElement = this.$refs.arrow;

      if (!referenceElement || !floatingElement || !arrowElement) return;

      this.cleanupAutoUpdate && this.cleanupAutoUpdate();

      this.cleanupAutoUpdate = autoUpdate(referenceElement, floatingElement, () => {
        computePosition(referenceElement, floatingElement, {
          placement: this.placement,
          middleware: [inline(), offset(this.gap), flip(), shift({ padding: 10 }), arrow({ element: arrowElement })],
        }).then(({ x, y, placement, middlewareData }) => {
          this.tooltipStyles = {
            left: `${x}px`,
            top: `${y}px`,
            position: "absolute",
          };

          const { x: arrowX, y: arrowY } = middlewareData.arrow;

          const staticSide = {
            top: "bottom",
            right: "left",
            bottom: "top",
            left: "right",
          }[placement.split("-")[0]];

          Object.assign(arrowElement.style, {
            left: arrowX != null ? `${arrowX}px` : "",
            top: arrowY != null ? `${arrowY}px` : "",
            right: "",
            bottom: "",
            [staticSide]: "-5px",
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.05s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
