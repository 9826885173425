import { arrayToObject, deleteProperty } from "@/services/helpers";

export const reset = (state, payload) => {
  state.customInventoryReportslist = {};
  state.defaultInventoryReport = null;
  state.customAllProductSalesReportslist = {};
  state.defaultAllProductSalesReport = null;
  state.customSellerOrderReportslist = {};
  state.defaultSellerOrderReport = null;
};

/** ***************************************
 * Inventory Reporing Mutations
 *************************************** */
export const initDefaultCustomReportslist = (state, payload) => {
  if (payload) {
    state.defaultInventoryReport = payload.report_id;
  }
};

export const initCustomReportslist = (state, payload) => {
  state.customInventoryReportslist = arrayToObject(payload, "id");
};

export const addCustomInventoryReport = (state, payload) => {
  state.customInventoryReportslist = { ...state.customInventoryReportslist, [payload.id]: payload };
};

export const removedCustomInventoryReport = (state, payload) => {
  state.customInventoryReportslist = deleteProperty(state.customInventoryReportslist, payload.id.toString());
};

export const replaceCustomInventoryReport = (state, payload) => {
  state.customInventoryReportslist[payload.id] = payload;
};
/** ***************************************
 * Advanced - All Product Sales Reporing Mutations
 *************************************** */
export const initDefaultCustomAllProductSalesReportslist = (state, payload) => {
  if (payload) {
    state.defaultAllProductSalesReport = payload.report_id;
  }
};

export const initCustomAllProductSalesReportslist = (state, payload) => {
  state.customAllProductSalesReportslist = arrayToObject(payload, "id");
};

export const addCustomAllProductSalesReport = (state, payload) => {
  state.customAllProductSalesReportslist = { ...state.customAllProductSalesReportslist, [payload.id]: payload };
};

export const removedCustomAllProductSalesReport = (state, payload) => {
  state.customAllProductSalesReportslist = deleteProperty(state.customAllProductSalesReportslist, payload.id.toString());
};

export const replaceCustomAllProductSalesReport = (state, payload) => {
  state.customAllProductSalesReportslist[payload.id] = payload;
};

/** ***************************************
 * Advanced - Seller Order Reporing Mutations
 *************************************** */
export const initDefaultCustomSellerOrderReport = (state, payload) => {
  if (payload) {
    state.defaultSellerOrderReport = payload.report_id;
  }
};

export const initCustomSellerOrderReportslist = (state, payload) => {
  state.customSellerOrderReportslist = arrayToObject(payload, "id");
};

export const addCustomSellerOrderReport = (state, payload) => {
  state.customSellerOrderReportslist = { ...state.customSellerOrderReportslist, [payload.id]: payload };
};

export const replaceCustomSellerOrderReport = (state, payload) => {
  state.customSellerOrderReportslist[payload.id] = payload;
};

export const replaceBrand = (state, payload) => {
  state.list[payload.id] = payload;
};

export const removeCustomSellerOrderReport = (state, payload) => {
  state.customSellerOrderReportslist = deleteProperty(state.customSellerOrderReportslist, payload.id.toString());
};

/** ***************************************
 * Advanced - Buyer Order Reporing Mutations
 *************************************** */
export const initDefaultCustomBuyerOrderReport = (state, payload) => {
  if (payload) {
    state.defaultBuyerOrderReport = payload.report_id;
  }
};

export const initCustomBuyerOrderReportslist = (state, payload) => {
  state.customBuyerOrderReportslist = arrayToObject(payload, "id");
};

export const addCustomBuyerOrderReport = (state, payload) => {
  state.customBuyerOrderReportslist = { ...state.customBuyerOrderReportslist, [payload.id]: payload };
};

export const removeCustomBuyerOrderReport = (state, payload) => {
  state.customBuyerOrderReportslist = deleteProperty(state.customBuyerOrderReportslist, payload.id.toString());
};

export const replaceCustomBuyerOrderReport = (state, payload) => {
  state.customBuyerOrderReportslist[payload.id] = payload;
};

/** ***************************************
 * Team Sales Reporing Mutations
 *************************************** */
export const initDefaultCustomTeamSalesReport = (state, payload) => {
  if (payload) {
    state.defaultTeamSalesReport = payload.report_id;
  }
};

export const initCustomTeamSalesReportslist = (state, payload) => {
  state.customTeamSalesReportslist = arrayToObject(payload, "id");
};

export const addCustomTeamSalesReport = (state, payload) => {
  state.customTeamSalesReportslist = { ...state.customTeamSalesReportslist, [payload.id]: payload };
};

export const removeCustomTeamSalesReport = (state, payload) => {
  state.customTeamSalesReportslist = deleteProperty(state.customTeamSalesReportslist, payload.id.toString());
};

export const replaceCustomTeamSalesReport = (state, payload) => {
  state.customTeamSalesReportslist[payload.id] = payload;
};
